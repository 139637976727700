import { OverviewPageDispensary, capitalize } from 'services';
import { useSiteWideContext } from '../../../hooks/siteWideContext';

export type StoreOpeningHoursProps = {
  times: OverviewPageDispensary['openTimes'];
};

const StoreOpeningHours = ({
  times
}: StoreOpeningHoursProps): JSX.Element | null => {
  const { width } = useSiteWideContext();
  if (!times || !Object.keys(times).length) return null;
  return (
    <section
      className="store-hours d-flex flex-column"
      data-test="opening-hours">
      <h6
        className="mh6 text-color-primary margin-bottom-15"
        data-test="store-info-header">
        Opening Hours
      </h6>
      <div
        className="hours-container d-flex flex-column"
        data-test="times-section">
        {Object.keys(times).map((item) => {
          const value =
            times[item as keyof OverviewPageDispensary['openTimes']];
          if (!value.length) return null;
          return (
            <div
              key={item}
              className="hours d-flex flex-row justify-content-between"
              data-test="info-section">
              <p className="margin-0" data-test="opening-hours-range">
                {capitalize(item)}
              </p>
              <p className="margin-0" data-test="opening-hours-times">
                {value
                  .map((v, i) => {
                    const multipleHours = value.length > 1 && i === 0;
                    const lineBreak = width && width <= 915 && multipleHours;
                    return v.startTime && v.endTime ? (
                      <span key={`${item}-${i}`}>
                        {v.startTime} - {v.endTime}
                        {multipleHours ? ', ' : ''}
                        {lineBreak ? <br /> : null}
                      </span>
                    ) : (
                      'CLOSED'
                    );
                  })
                  .filter(Boolean)}
              </p>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default StoreOpeningHours;
