'use client';

import { Tooltip } from 'primereact/tooltip';
import { useState } from 'react';

import { DatalayerAnalytics, optionsMap } from 'services';
import { CartSummaryProps } from './index';
import { EmptyCart } from './EmptyCart';
import { OrderTypeSelection } from './OrderType';
import { ProductListTile } from '../ProductListTile';
import CartButton from '../ProductDescription/CartButton';
import { OrderSummary } from '../OrderSummary';
import { PackageComponentWrapper } from '../PackageComponentWrapper';
import { Alert } from '../Alert';
import { useLoyaltyData } from '../../hooks/graphs';
import { useSiteWideContext } from '../../hooks/siteWideContext';
import { ErrorBoundary } from '../../utils/nullErrorBoundary';
import { useDispensaryPathContext } from '../../hooks/dispensaryPathContextProvider';
import { useStorage } from '../../hooks/useStorage';
import InformationIcon from '../../assets/svgs/shared/icon-info.svg';

export const CartSummary = ({
  enabledLogin,
  shopLink,
  userParam
}: CartSummaryProps): JSX.Element | null => {
  const {
    isLoggedIn,
    setAccountDrawerMode,
    setAccountDrawerVisible,
    userOrderType,
    setUserOrderType,
    cart,
    clearCart,
    quantity,
    updateCartOrderType
  } = useSiteWideContext();
  const { currentPathDispensary } = useDispensaryPathContext();
  const { slug, uid } = currentPathDispensary;
  const { loyaltyMemberInfo } = useLoyaltyData(isLoggedIn);

  const [platformStorage] = useStorage('ga_platform_storage', '', true);

  const [loading, setLoading] = useState(cart.loading);
  const [showClearCartAlert, setClearCartAlert] = useState(false);

  const orderTypeCheck = () => {
    // Adding a check here because order types are shared on order summary & store drawer
    // and we want to still be able to click delivery in the store drawer without setting orderType
    setLoading(true);

    // If user selected 'PICKUP' but the dispensary only allows 'DELIVERY',
    // switch the order type to 'DELIVERY'
    if (userOrderType === 'PICKUP' && currentPathDispensary?.deliveryOnly) {
      setUserOrderType('DELIVERY');
    }
    // If user selected 'DELIVERY' but the dispensary doesn't allow it and allows 'PICKUP',
    // switch the order type to 'PICKUP'
    else if (
      userOrderType === 'DELIVERY' &&
      !currentPathDispensary?.orderTypes?.includes('DELIVERY') &&
      currentPathDispensary?.orderTypes?.includes('PICKUP')
    ) {
      setUserOrderType('PICKUP');
    }

    // If the cart's order type is different from the user's order type
    // update it to user's order type
    if (
      cart.data?.orderType &&
      updateCartOrderType &&
      userOrderType !== cart.data.orderType
    ) {
      updateCartOrderType
        .mutateAsync({ orderType: userOrderType })
        .finally(() => {
          setLoading(false);
          onCheckout();
        });
    } else {
      setLoading(false);
      onCheckout();
    }
  };

  const onCheckout = () => {
    if (cart.data) {
      if (cart.data.redirectUrl.length === 0) {
        alert('this store isnt configured!');
      } else {
        if (cart.data) {
          DatalayerAnalytics.pushBeginCheckout({
            value: cart.data.priceSummary.subtotal / 100,
            coupon: undefined,
            items: DatalayerAnalytics.mapOrderItems(cart.data.items, {
              dispensaryFriendlyName: currentPathDispensary.friendlyName
            }),
            consolidateDispensary: currentPathDispensary
          });
          let redirectBack = `${window.location.origin}${shopLink}/order-confirmation`;
          if (platformStorage && platformStorage === 'android') {
            const firstName = userParam
              ?.split('&')
              .find((param) => param.includes('firstName'))
              ?.split('=')[1];
            redirectBack += `/android?dispensaryId=${uid}&dispensarySlug=${slug}&firstName=${firstName}`;
          }

          const redirect =
            cart.data.redirectUrl.substring(0, 4) === 'http'
              ? cart.data.redirectUrl
              : `https://${cart.data.redirectUrl}`;

          // If curaleaf accounts are enabled, hides dutchie login
          window.open(
            redirect +
              `${userParam?.length ? userParam + '&' : '?'}r=${redirectBack}${
                enabledLogin ? `&hideLogin=true` : ''
              }`,
            '_self'
          );
        }
      }
    }
  };

  const disclaimer =
    (cart?.data?.items.length && currentPathDispensary?.deliveryDisclaimer) ||
    undefined;

  return (
    <PackageComponentWrapper additionalClass="bg-ecom">
      {showClearCartAlert ? (
        <Alert
          button={{
            callBackFunc: clearCart
              ? () => clearCart(() => setClearCartAlert(false))
              : undefined,
            text: 'Yes, I am sure'
          }}
          close={() => setClearCartAlert(false)}
          title="Are you sure you want to clear your cart?"
        />
      ) : null}
      <div className="cart-summary padding-top-15 container">
        <h2 className="header mh2 mh4-mobile margin-bottom-45 text-primary">{`Cart (${quantity})`}</h2>
        {cart.data?.items.length ? (
          <div className="clear-cart-container text-right margin-bottom-5">
            <a
              tabIndex={0}
              className="clear-cart tbody16 text-grey pointer underline"
              onClick={() => setClearCartAlert(true)}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  setClearCartAlert(true);
                }
              }}>
              Clear Cart
            </a>
          </div>
        ) : null}
        <div className="summary-container d-flex">
          <div className="tiles d-flex flex-column">
            {cart?.data?.items.length ? (
              cart.data.items.map((item) => {
                const variant = item.product.variants.find(
                  (v) => optionsMap(v.option) === optionsMap(item.option)
                );
                return (
                  <ProductListTile
                    key={item.id}
                    props={{
                      isLoading: false,
                      item,
                      setButtonLoading: setLoading,
                      shopLink,
                      type: 'cart',
                      variant
                    }}
                  />
                );
              })
            ) : (
              <EmptyCart shopLink={shopLink} />
            )}
          </div>
          <ErrorBoundary component="order-summary">
            <div className="order-summary bg-white padding-30">
              {!(process.env.IS_KIOSK === 'true') ? (
                <OrderTypeSelection
                  options={{
                    cart,
                    updateCartOrderType
                  }}
                  mode="cart"
                  setLoading={setLoading}
                />
              ) : null}
              {isLoggedIn && loyaltyMemberInfo ? (
                <>
                  <div className="points d-flex justify-content-between">
                    <div className="d-flex">
                      <span className="text-dark-grey">Loyalty Points</span>
                      <span data-test="tooltip">
                        <Tooltip target=".tooltip-info" position="bottom" />
                        <span
                          className="tooltip-info margin-left-5 margin-right-5"
                          data-pr-tooltip={
                            'Loyalty points are redeemable in store at time of purchase.'
                          }>
                          <InformationIcon data-test="information-icon" />
                        </span>
                      </span>
                      :
                    </div>
                    <span className="points-tag text-primary bg-success-light subtitle padding-bottom-15">
                      {loyaltyMemberInfo.totalPointsAvailable}pts
                    </span>
                  </div>
                  <div className="hairline" />
                </>
              ) : null}
              <div className={disclaimer ? '' : 'margin-bottom-30'}>
                {cart.data ? (
                  <OrderSummary
                    props={{
                      isLoading: false,
                      pricing: {
                        discounts: cart.data.priceSummary.discounts,
                        subtotal: cart.data.priceSummary.subtotal,
                        taxes: cart.data.priceSummary.taxes,
                        total: cart.data.priceSummary.total,
                        fees: cart.data.priceSummary.fees
                      },
                      type: 'cart'
                    }}
                  />
                ) : null}
              </div>
              {disclaimer ? (
                <div className="tbody14-bold text-center text-color-error margin-top-15 margin-bottom-15">
                  {disclaimer}
                </div>
              ) : null}
              {enabledLogin &&
              !isLoggedIn &&
              !(process.env.IS_KIOSK === 'true') ? (
                <div className="signin-btn" data-test="checkout-signin">
                  <CartButton
                    callBackFunc={() => {
                      setAccountDrawerMode('login');
                      setAccountDrawerVisible(true);
                    }}
                    text="Sign In"
                    variant="secondary"
                  />
                </div>
              ) : null}
              <div
                className="checkout-btn padding-top-10"
                data-test="checkout-guest">
                <CartButton
                  loading={loading}
                  disabled={
                    !!(
                      !cart.data?.items.length ||
                      currentPathDispensary?.pauseEcommerce
                    )
                  }
                  callBackFunc={orderTypeCheck}
                  text={
                    enabledLogin && isLoggedIn ? 'Checkout' : 'Guest Checkout'
                  }
                  variant="primary"
                />
              </div>
            </div>
          </ErrorBoundary>
        </div>
      </div>
    </PackageComponentWrapper>
  );
};
