import { CarouselType, StorefrontCarouselBanner } from './index';
import { CarouselTile } from './components/tile';
import { PackageComponentWrapper } from '../PackageComponentWrapper';
import { LoadingSkeleton } from '../Loader/Skeleton';

type LoaderProps = {
  backgroundColor?: string;
  title?: string;
  type: CarouselType;
};

export const Loader = ({ backgroundColor, title, type }: LoaderProps) => {
  const items = type === 'categories' ? 7 : 5;
  const emptyTileArray = new Array(items).fill(undefined);

  return (
    <PackageComponentWrapper additionalClass={`bg-${backgroundColor}`}>
      <div className="storefront-carousel d-flex flex-column">
        <div className="header d-flex justify-content-between align-items-end margin-bottom-5">
          <h2 className="title text-primary margin-0">
            {title || <LoadingSkeleton width="200px" />}
          </h2>
        </div>
        <div className="slider d-flex padding-bottom-10">
          {emptyTileArray.map((item, id) => (
            <div key={id} data-type={type} className="item">
              {CarouselTile(
                type,
                item as unknown as StorefrontCarouselBanner,
                true,
                0
              )}
            </div>
          ))}
        </div>
      </div>
    </PackageComponentWrapper>
  );
};
