import classNames from 'classnames';
import { usePathname } from 'next/navigation';

import { WebFooterProps } from '../index';
import { PackageComponentWrapper } from '../../PackageComponentWrapper';
import { SocialIcons } from '../../SocialIcons';

export const FooterLower = ({
  copyrightInfo,
  legalLinks,
  socialMediaLinks
}: {
  copyrightInfo: WebFooterProps['copyrightInfo'];
  legalLinks: WebFooterProps['legalLinks'];
  socialMediaLinks: WebFooterProps['socialMediaLinks'];
}): JSX.Element | null => {
  const pathname = usePathname();

  return (
    <PackageComponentWrapper additionalClass="bg-white">
      <div
        // had to add 15 here because 80 was getting overrided in scss
        className={classNames('footer-lower padding-bottom-15', {
          'padding-bottom-80': pathname.includes('/shop')
        })}>
        {socialMediaLinks && socialMediaLinks.length ? (
          <div className="corp-social-media" data-test="corp-social-icons">
            <SocialIcons socialMediaLinks={socialMediaLinks} />
          </div>
        ) : null}
        <div className="sp-links" data-test="sp-links-url">
          {legalLinks && legalLinks.length
            ? legalLinks
                .filter((llinks, i) => i < 3)
                .map((link) => {
                  return (
                    <a
                      className="text-dark-teal text-decoration-none"
                      key={link.id}
                      href={link.url ? link.url : undefined}
                      target={
                        link.url
                          ? link.url.startsWith('/')
                            ? ''
                            : '_blank'
                          : undefined
                      }
                      data-test="legal-link">
                      <small>{link.linkText}</small>
                    </a>
                  );
                })
            : null}
        </div>
        {copyrightInfo ? (
          <small
            className="footer-copyright text-primary"
            data-test="footer-copyright">
            {copyrightInfo}
          </small>
        ) : null}
      </div>
    </PackageComponentWrapper>
  );
};
