import { useSiteWideContext } from '../../../hooks/siteWideContext';
import CloseIcon from '../../../assets/svgs/close/close-teal.svg';

export type PopoutHeaderProps = {
  closeNav: () => void;
  navOpen: boolean;
  shopHome: () => void;
};

export const PopoutHeader = ({
  closeNav,
  navOpen,
  shopHome
}: PopoutHeaderProps): JSX.Element | null => {
  const { selectedDispensary } = useSiteWideContext();
  return (
    <div className="popup-header d-flex justify-content-between align-items-end">
      <button tabIndex={0} className="close-icon d-flex" onClick={closeNav}>
        <CloseIcon aria-controls="primary-navigation" aria-expanded={navOpen} />
      </button>
      <button
        tabIndex={0}
        className="selected-store margin-bottom-5"
        onClick={shopHome}
        onKeyDown={(event) => {
          if (event.key === 'Enter') shopHome();
        }}>
        <span className="pointer tbody14 text-color-primary">
          {selectedDispensary?.friendlyName || 'Find a Dispensary'}
        </span>
      </button>
    </div>
  );
};
