import { InView } from 'react-intersection-observer';
import { usePathname } from 'next/navigation';

import { DatalayerAnalytics, snakeCase } from 'services';
import { getMonetatePageType } from '../../utils/personalized';
import { PExperienceType, PersonalizationCarouselProps } from './index';
import { productsAnalyticsMap } from '../../utils/storefrontCarouselsMap';
import { StorefrontCarousel } from '../StorefrontCarousel';

export const Personalization = ({
  backgroundColor,
  isLoading,
  recommendations
}: PersonalizationCarouselProps) => {
  const pathname = usePathname();

  const handleAnalyticsInView = (rec: PExperienceType) => {
    DatalayerAnalytics.pushBasicEvent('in_view', {
      component: 'personalized_carousel',
      link_url: getMonetatePageType(pathname),
      title: snakeCase(rec.title)
    });
  };

  const handleAnalyticsClick = (productName: string, rec: PExperienceType) => {
    DatalayerAnalytics.pushBasicEvent('click', {
      link_text: productName || '',
      click_title: snakeCase(rec.title),
      click_page: getMonetatePageType(pathname),
      click_location: 'personalized_carousel'
    });
  };

  return (
    <>
      {recommendations.map((pp, id) => (
        <InView
          key={`${pp.title}-${id}`}
          threshold={1}
          triggerOnce={true}
          onChange={(inView) => inView && handleAnalyticsInView(pp)}>
          {({ ref }) => (
            <div ref={ref}>
              <StorefrontCarousel
                backgroundColor={backgroundColor}
                banners={productsAnalyticsMap(
                  pp.recItems,
                  (productName: string) => handleAnalyticsClick(productName, pp)
                )}
                isLoading={isLoading}
                helpText={pp.description}
                title={pp.title}
                type="products"
              />
            </div>
          )}
        </InView>
      ))}
    </>
  );
};
