'use client';

import { StorefrontCarousel as Default } from './default';
import { Loader } from './loader';
import { CategoryTileProps } from '../CategoryTile';
import { ProductTileProps } from '../ProductTile';
import { SpecialTileProps } from '../SpecialTile';

import './storefront-carousel.scss';

export type StorefrontCarouselProps = {
  backgroundColor?: string;
  banners: StorefrontCarouselBanner[];
  helpText?: string;
  isLoading: boolean;
  link?: string;
  title?: string;
  type: CarouselType;
};

export type StorefrontCarouselBanner = (
  | Pick<CategoryTileProps, 'title'>
  | Omit<ProductTileProps, 'analyticsContext'>
  | SpecialTileProps
) & {
  containingCarouselName?: string;
};

export type CarouselDirection = 'next' | 'previous';

export type CarouselType =
  | 'categories'
  | 'products'
  | 'specials'
  | 'specials-drawer';

export const StorefrontCarousel = (
  props: StorefrontCarouselProps
): JSX.Element | null => {
  if (!props.banners?.length && !props.isLoading) return null;
  if (props.isLoading) {
    return (
      <Loader
        backgroundColor={props.backgroundColor}
        title={props.title}
        type={props.type}
      />
    );
  }
  return <Default {...props} banners={props.banners} />;
};
