import { StorefrontBannersProps } from './index';
import { StorefrontImageBanner } from './banners/ImageBanner';
import Carousel from '../Carousel';
import { PackageComponentWrapper } from '../PackageComponentWrapper';
import { CarouselDots } from '../Carousel/components';
import { useSiteWideContext } from '../../hooks/siteWideContext';
import { useCarousel } from '../../hooks/useCarousel';

export const StorefrontBanners = ({
  banners
}: StorefrontBannersProps): JSX.Element | null => {
  const { width } = useSiteWideContext();
  const {
    setSelectedItem,
    setClassName,
    selectedItem,
    activeImageIndex,
    setActiveImageIndex,
    autoSlide,
    setAutoSlide,
    autoSlideSeconds
  } = useCarousel(banners, true, 7);

  const states = {
    setSelectedItem,
    setClassName,
    selectedItem,
    activeImageIndex,
    setActiveImageIndex,
    autoSlide,
    setAutoSlide,
    autoSlideSeconds
  };

  const isTablet = !!(width && width < 1024);

  return (
    <PackageComponentWrapper additionalClass="bg-ecom">
      <div className="storefront-banners-carousel">
        <Carousel data={banners} states={states}>
          {banners.map((banner, i) => (
            <div
              key={i} // should be alternative text, but they are all "1" in contentstack.
              className="carousel-el"
              style={{
                transform: `translate3d(${-activeImageIndex * 100}%, 0, 0)`
              }}>
              <StorefrontImageBanner {...banner} isMobile={isTablet} id={i} />
            </div>
          ))}
        </Carousel>
        <div className="storefront-banner-dots thumbs">
          {banners && banners.length > 1 && (
            <CarouselDots data={banners} states={states} dotName="sm-dot" />
          )}
        </div>
      </div>
    </PackageComponentWrapper>
  );
};
