'use client';

import { useEffect, useState } from 'react';
import classNames from 'classnames';
import Link from 'next/link';

import { CS_CuraleafPage_CTAButton, getSlug } from 'services';
import { ecommerceSanitizeQuery, isExternalUrl } from '../../utils/url';
import { PackageComponentWrapper } from '../PackageComponentWrapper';

import './cta-button.scss';

export const changeButtonText = (text: string) => {
  // Limits button text to 6 words
  let newWord = '';
  const wordLimit = 6;
  const words = text.split(' ');
  words.length > wordLimit
    ? (newWord = words.slice(0, 6).join(' '))
    : (newWord = text);

  return newWord;
};

export type CTAButtonVariants =
  | CS_CuraleafPage_CTAButton['variant']
  | 'quintenary'
  | 'account';

export type CTAButtonProps = {
  background_hex_color?: string;
  border_hex_color?: string;
  callBackFunc?: () => void | Promise<void> | undefined;
  'data-testid'?: string;
  disabled?: boolean;
  text_hex_color?: string;
  text: string;
  type?: 'submit' | 'button';
  url?: string | null;
  variant?: CTAButtonVariants;
  allowEmptyUrl?: boolean;
};

export const CTAButton = ({
  'data-testid': dataTestId = 'cta-button',
  background_hex_color,
  border_hex_color,
  callBackFunc,
  disabled = false,
  text_hex_color,
  text,
  type,
  url,
  variant = 'primary',
  allowEmptyUrl = false
}: CTAButtonProps): JSX.Element | null => {
  const styles: {
    backgroundColor?: string;
    color?: string;
    borderColor?: string;
  } = {};
  if (background_hex_color) styles.backgroundColor = background_hex_color;
  if (border_hex_color) styles.borderColor = border_hex_color;
  if (text_hex_color) styles.color = text_hex_color;
  const [isDisabled, setDisabled] = useState(disabled);

  useEffect(() => {
    if (disabled !== isDisabled) {
      setDisabled(disabled);
    }
  }, [disabled]);

  const anchor = (anchorUrl: string) => {
    return (
      <Link
        tabIndex={0}
        className={classNames('btn', {
          'cta-button-disabled': isDisabled,
          'cta-button-primary': variant === 'shop' && !isDisabled,
          [`cta-button-${variant}`]: variant !== 'shop' && !isDisabled
        })}
        onClick={() => (callBackFunc ? callBackFunc() : null)}
        aria-disabled={isDisabled}
        data-testid={dataTestId}
        target={anchorUrl && isExternalUrl(anchorUrl) ? '_blank' : ''}
        rel="noreferrer"
        title={text}
        href={`${ecommerceSanitizeQuery(getSlug(anchorUrl))}`}
        style={styles}>
        {text && changeButtonText(text)}
      </Link>
    );
  };

  const cbButton = (cb: () => void) => (
    <button
      type={type ? type : 'button'}
      onClick={() => {
        cb();
      }}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          cb();
        }
      }}
      className={classNames({
        'cta-button-disabled': isDisabled,
        [`cta-button-${variant}`]: !isDisabled,
        btn: variant !== 'account'
      })}
      data-testid={dataTestId}
      style={styles}>
      {text && changeButtonText(text)}
    </button>
  );

  const link = variant === 'shop' ? '/locations?shop' : url;
  return (
    <PackageComponentWrapper minWidth={false}>
      <div className={`cta-button cta-button-curaleaf`}>
        {link ? anchor(link) : !link && callBackFunc && cbButton(callBackFunc)}
        {!link && !callBackFunc && allowEmptyUrl && (
          <Link
            href=""
            className={classNames('btn', {
              'cta-button-disabled': isDisabled,
              'cta-button-primary': variant === 'shop' && !isDisabled,
              [`cta-button-${variant}`]: variant !== 'shop' && !isDisabled
            })}
            aria-disabled={isDisabled}
            data-testid={dataTestId}
            title={text}
            style={styles}>
            {text && changeButtonText(text)}
          </Link>
        )}
      </div>
    </PackageComponentWrapper>
  );
};
