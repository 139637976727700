import Cookies from 'js-cookie';
import { Consolidate_Product } from 'services';

export type PreferenceType =
  | 'userProductViewPreferences'
  | 'userAddToCartPreferences'
  | 'userPurchasePreferences';

interface Preference {
  [key: string]: number;
}

export interface UserPreferences {
  userProductViewPreferences: Preference;
  userAddToCartPreferences: Preference;
  userPurchasePreferences: Preference;
}

export const trackMonetateImpressions = (
  category: Consolidate_Product['category'],
  preferences: PreferenceType,
  quantity?: number
) => {
  const rawCookie = Cookies.get('category_preferences');
  const parsedCookie = rawCookie ? JSON.parse(rawCookie) : null;

  if (parsedCookie) {
    if (parsedCookie[preferences][category]) {
      parsedCookie[preferences][category] += quantity ?? 1;
      Cookies.set('category_preferences', JSON.stringify(parsedCookie));
    } else {
      parsedCookie[preferences][category] = quantity ?? 1;
      Cookies.set('category_preferences', JSON.stringify(parsedCookie));
    }
  } else {
    const newPreferencessCookie: {
      userProductViewPreferences: Preference;
      userAddToCartPreferences: Preference;
      userPurchasePreferences: Preference;
    } = {
      userProductViewPreferences: {},
      userAddToCartPreferences: {},
      userPurchasePreferences: {}
    };
    newPreferencessCookie[preferences][category] = quantity ?? 1;
    Cookies.set('category_preferences', JSON.stringify(newPreferencessCookie));
  }
};
