import { LoadingSkeleton } from '../Loader/Skeleton';
import { OrderSummary } from '../OrderSummary';
import { PackageComponentWrapper } from '../PackageComponentWrapper';
import { ProductListTile } from '../ProductListTile';

export const Loader = (): JSX.Element | null => {
  const cartTiles = new Array(3).fill(undefined);

  return (
    <PackageComponentWrapper additionalClass="bg-ecom">
      <div className="cart-summary padding-top-15 container">
        <h2 className="header mh2 mh4-mobile margin-bottom-45 text-primary">
          Cart
        </h2>
        <div className="summary-container d-flex">
          <div className="tiles d-flex flex-column">
            {cartTiles.map((_, i) => (
              <ProductListTile
                key={i}
                props={{
                  isLoading: true,
                  type: 'cart'
                }}
              />
            ))}
          </div>
          <div className="order-summary bg-white padding-30">
            <div className="margin-bottom-30">
              <OrderSummary props={{ isLoading: true }} />
            </div>
            <div className="checkout-btn margin-top-10">
              <LoadingSkeleton width="100%" height="56px" />
            </div>
          </div>
        </div>
      </div>
    </PackageComponentWrapper>
  );
};
