'use client';

import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState
} from 'react';

import {
  Consolidate_Dispensary,
  CurrentPathDispensary,
  DutchiePlus_PricingType,
  getMinHeightClass,
  InformationBannerType
} from 'services';
import { AlertProps } from '../components/Alert';
import { FeedbackTab } from '../components/FeedbackTab';
import { useParams } from 'next/navigation';
import { useSiteWideContext } from './siteWideContext';
import { SpecialTileProps } from '../components/SpecialTile';
import { OverviewCard } from '../components/DispensaryOverview/OverviewCard';
import { MailingOptInBanner } from '../components/MailingOptIn/Banner';
import { SpecialsTray } from '../components/SpecialsTray';

export interface DispensaryPathWrapperProps {
  alerts: (AlertProps & { id: number })[];
  currentPathDispensary: CurrentPathDispensary;
  defaultMenuType: DutchiePlus_PricingType;
  infoBanners: {
    banners: InformationBannerType[];
    sticky: boolean;
    set: Dispatch<SetStateAction<InformationBannerType[]>>;
  } | null;
  offerList: SpecialTileProps[];
  todaysOffers: InformationBannerType | null;
}

// Create Context Object
export const DispensaryPathContext = createContext<DispensaryPathWrapperProps>(
  {} as DispensaryPathWrapperProps
);

export type DispensaryPathContextProviderProps = {
  children: ReactNode;
  // Props that need to be passed in
  contextProps: Omit<
    DispensaryPathWrapperProps,
    'defaultMenuType' | 'infoBanners' | 'personalized'
  >;
};

// Context for current shop path dispensary
export const DispensaryPathContextProvider = ({
  children,
  contextProps
}: DispensaryPathContextProviderProps) => {
  const {
    cart,
    selectedDispensary,
    selectedDispensaryLoading,
    setSelectedDispensary,
    userMenuType
  } = useSiteWideContext();

  // WARN and INFO can be closed out and removed from arr
  // save removed banners to local storage?
  const [updatedInfoBanners, setInfoBanners] = useState<
    InformationBannerType[]
  >(
    contextProps.currentPathDispensary.storefrontMap
      ? contextProps.currentPathDispensary.storefrontMap.infoBanners.banners
      : []
  );

  useEffect(() => {
    // update selected dispensary to current path dispensary
    if (
      (!selectedDispensaryLoading && !selectedDispensary) ||
      (selectedDispensary &&
        selectedDispensary.slug !== contextProps.currentPathDispensary.slug)
    ) {
      setSelectedDispensary(contextProps.currentPathDispensary.uid);
      cart.refetch();
    }
  }, [
    contextProps.currentPathDispensary.slug,
    selectedDispensary,
    selectedDispensaryLoading
  ]);

  /* ------ FEEDBACK ------ */
  const feedbackUrl =
    contextProps.currentPathDispensary.storefrontMap?.feedbackUrl || '';

  /* ------------------------------------------------------ */

  const context = {
    ...contextProps,
    // DEFAULTS MENUTYPE FROM USER MENUTYPE > RECREATIONAL > FIRST TYPE IN ARRAY. This way a user can still see products even if the dispensary doesn't have their menutype.
    defaultMenuType: contextProps.currentPathDispensary.menuTypes.includes(
      userMenuType
    )
      ? userMenuType
      : (contextProps.currentPathDispensary
          .menuTypes[0] as Consolidate_Dispensary['menuTypes'][number]),
    setInfoBanners,
    infoBanners: contextProps.currentPathDispensary.storefrontMap
      ? {
          ...contextProps.currentPathDispensary.storefrontMap.infoBanners,
          banners: updatedInfoBanners,
          sticky:
            contextProps.currentPathDispensary.storefrontMap.infoBanners.sticky,
          set: setInfoBanners
        }
      : null
  };

  const params = useParams();
  const isKiosk = process.env.IS_KIOSK === 'true';

  const minHeightClass = getMinHeightClass(
    'ecom',
    isKiosk,
    process.env.CONTENTSTACK_ENVIRONMENT
  );

  return (
    <DispensaryPathContext.Provider value={context}>
      <div className={`bg-ecom ${minHeightClass}`}>
        <FeedbackTab url={feedbackUrl} />
        {children}
        {isKiosk ? null : (
          <OverviewCard dispensary={contextProps.currentPathDispensary} />
        )}
      </div>
      <MailingOptInBanner />
      {params.productId || isKiosk ? null : (
        <SpecialsTray
          selectedDispensary={contextProps.currentPathDispensary}
          offerList={contextProps.offerList}
          todaysOffers={contextProps.todaysOffers}
        />
      )}
    </DispensaryPathContext.Provider>
  );
};

// Use this in child components
export const useDispensaryPathContext = () => useContext(DispensaryPathContext);
