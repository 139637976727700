import { SocialMediaLinksData } from '../../models/types';

import FacebookIcon from '../../assets/svgs/social-icons/facebook.svg';
import FacebookSmIcon from '../../assets/svgs/social-icons/facebook-sm.svg';
import InstagramIcon from '../../assets/svgs/social-icons/instagram.svg';
import InstagramSmIcon from '../../assets/svgs/social-icons/instagram-sm.svg';
import LinkedInIcon from '../../assets/svgs/social-icons/linkedin.svg';
import LinkedInSmIcon from '../../assets/svgs/social-icons/linkedin-sm.svg';
import TwitterIcon from '../../assets/svgs/social-icons/twitter.svg';
import TwitterSmIcon from '../../assets/svgs/social-icons/twitter-sm.svg';
import YoutubeIcon from '../../assets/svgs/social-icons/youtube.svg';
import YoutubeSmIcon from '../../assets/svgs/social-icons/youtube-sm.svg';

import './social-icons.scss';

export type SocialIconsProps = {
  socialMediaLinks: SocialMediaLinksData[];
  size?: 'normal' | 'small';
};

export const SocialIcons = ({
  socialMediaLinks,
  size = 'normal'
}: SocialIconsProps): JSX.Element | null => {
  if (!socialMediaLinks || !socialMediaLinks.length) return null;
  const getIcon = (corporateType: string) => {
    switch (corporateType) {
      case 'Facebook':
        return size === 'normal' ? <FacebookIcon /> : <FacebookSmIcon />;
      case 'Instagram':
        return size === 'normal' ? <InstagramIcon /> : <InstagramSmIcon />;
      case 'LinkedIn':
        return size === 'normal' ? <LinkedInIcon /> : <LinkedInSmIcon />;
      case 'Twitter/X':
      case 'Twitter':
        return size === 'normal' ? <TwitterIcon /> : <TwitterSmIcon />;
      case 'YouTube':
        return size === 'normal' ? <YoutubeIcon /> : <YoutubeSmIcon />;
      default:
        console.warn('Social icon not found!');
        return null;
    }
  };

  return (
    <div className="social-icons-curaleaf">
      <div data-test="social-data" className="s-comp">
        {socialMediaLinks.map((socialMediaLinks: SocialMediaLinksData) => (
          <div
            className="s-icon"
            key={socialMediaLinks.id}
            data-test="social-icon">
            <a
              aria-label="social icon"
              href={socialMediaLinks.url}
              target="_blank">
              {getIcon(socialMediaLinks.corporate_type)}
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};
