import { Dispatch, SetStateAction, useEffect } from 'react';
import Link from 'next/link';

import { useSiteWideContext } from '../../hooks/siteWideContext';
import { SublinkState } from './web';
import { HeaderNavigationProps, HeaderLink } from './index';
import { DatalayerAnalytics } from 'services';

import RightArrow from '../../assets/svgs/arrows/chevron-right.svg';
import LeftIcon from '../../assets/svgs/arrows/chevron-left.svg';

export type SublinkProps = Pick<HeaderNavigationProps, 'navLinks'> & {
  isPopup?: boolean;
  openNav?: () => void;
  setShowMore?: Dispatch<SetStateAction<boolean>>;
  setSublinks: Dispatch<SetStateAction<SublinkState>>;
  show?: boolean;
};

type Links = HeaderLink[number] & {
  pixelLength?: number;
};

export const Sublink = ({
  isPopup = false,
  navLinks,
  openNav,
  setShowMore,
  setSublinks,
  show = false
}: SublinkProps): JSX.Element | null => {
  const { isMobile, selectedDispensary } = useSiteWideContext();

  useEffect(() => {
    window.addEventListener('resize', () => {
      updateMenuVisibility();
    });
    return (): void =>
      window.removeEventListener('resize', updateMenuVisibility);
  }, []);

  useEffect(() => {
    updateMenuVisibility();
  }, [navLinks, isMobile]);

  const updateMenuVisibility = () => {
    const currentItems: HTMLElement[] = Array.from(
      document?.querySelectorAll('.header-item')
    );
    const container: HTMLElement | null =
      document?.querySelector('.header-links');
    const moreContainer: HTMLElement | null =
      document?.querySelector('.more-link');
    const availableWidth = container && container.clientWidth;
    const buffer = 70;
    const moreWidth = 42;
    const computedMenu = [];
    let consumedWidth = 0;

    if (moreContainer && availableWidth) {
      moreContainer.style.display = 'none';

      for (const item of currentItems) {
        if (!isMobile) item.style.display = 'none';
        const dataWidthAttribute = item.getAttribute('data-width');
        if (typeof dataWidthAttribute === 'string') {
          const itemWidth = parseInt(dataWidthAttribute, 10);
          let isShowing = false;
          if (consumedWidth + itemWidth <= availableWidth - buffer) {
            item.style.display = 'block';
            consumedWidth += itemWidth + 32;
            isShowing = true;
          }
          computedMenu.push({
            item,
            isShowing: isShowing,
            pixelLength: itemWidth
          });
        }
      }

      const itemCutoff = computedMenu.some((item) => !item.isShowing);
      const availableSpace = availableWidth - buffer - consumedWidth;

      if (itemCutoff && availableSpace > moreWidth) {
        moreContainer.style.display = 'block';
      } else if (itemCutoff) {
        let lastIndex = -1;
        computedMenu.map((item, i) => {
          if (!item.isShowing && lastIndex == -1) {
            lastIndex = i;
          }
        });
        moreContainer.style.display = 'block';
      }
    }
  };

  const handleItemClick = (item: Links, id: number) => {
    if (item.sublinks && item.sublinks.length) {
      if (openNav) openNav();
      setSublinks({
        id,
        show: true
      });
    }
  };

  return (
    <ul
      className={`header-links ${
        isPopup && !isMobile ? 'scrollbar-navigation' : 'primary-navigation'
      } d-flex align-items-center padding-0 margin-0'`}>
      {show ? (
        <li
          className="d-flex align-items-center padding-bottom-10"
          tabIndex={0}
          onClick={() => setSublinks({ id: undefined, show: false })}
          onKeyDown={(event) =>
            event.key === 'Enter' && setSublinks({ id: undefined, show: false })
          }>
          <LeftIcon />
          <p className="tbody16 text-color-primary margin-left-5 margin-bottom-0 pointer">
            Back to Main Menu
          </p>
        </li>
      ) : null}
      {navLinks.map((item, id) => (
        <li
          key={id}
          className="header-item"
          data-width={!isMobile && item.pixelLength}
          tabIndex={0}
          onClick={() => item.sublinks && handleItemClick(item, id)}
          onKeyDown={(event) =>
            event.key === 'Enter' && item.sublinks && handleItemClick(item, id)
          }>
          {item.link ? (
            <Link
              href={item.link}
              onClick={() => {
                if (item.link?.includes('categories') && selectedDispensary) {
                  try {
                    DatalayerAnalytics.pushBasicEvent(
                      'category_select',
                      {
                        click_location: 'category sidebar',
                        link_category: 'category',
                        link_text: item.title,
                        link_url: item.link
                      },
                      selectedDispensary
                    );
                  } catch (err) {
                    console.error(err);
                  }
                }
              }}
              target={item.link.startsWith('http') ? '__blank' : ''}
              key={item.title}
              className="d-flex align-items-center justify-content-between pointer tbody16-bold text-color-primary">
              {item.title}
            </Link>
          ) : item.sublinks && item.sublinks.length ? (
            <span
              data-popup-link={true}
              key={item.title}
              className="d-flex align-items-center justify-content-between pointer tbody16-bold text-color-primary">
              {item.title}
              <RightArrow />
            </span>
          ) : null}
        </li>
      ))}
      <li
        tabIndex={0}
        className="more-link d-flex align-items-center justify-content-between pointer tbody16-bold text-color-primary"
        onClick={() => setShowMore && setShowMore(true)}
        onKeyDown={(event) =>
          event.key === 'Enter' && setShowMore && setShowMore(true)
        }>
        More
      </li>
    </ul>
  );
};
