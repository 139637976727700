import { Markdown } from '../../Markdown';
import { DatalayerAnalytics, InformationBannerType } from 'services';

import CloseIcon from '../../../assets/svgs/close/icon-close.svg';

type InformationBannerProps = {
  banner: InformationBannerType;
  removeBanner: (id: number) => void;
  setBulletinModal: (open: boolean) => void;
};

export const InformationBanner = ({
  banner,
  removeBanner,
  setBulletinModal
}: InformationBannerProps): JSX.Element | null => {
  if (!banner || !banner.title || !banner.severity) return null;

  return (
    <div className="information-banner">
      <div
        data-banner-type={banner.severity}
        className="ib-container d-flex justify-content-between align-items-center padding-15">
        <div className="ib-contents d-flex flex-column">
          <div className="ib-header d-flex align-items-center">
            <p className="margin-0 tbody14 font-bold text-color-dark-grey">
              {banner.title}
            </p>
            {banner.cta?.text ? (
              <a
                tabIndex={0}
                onClick={() => {
                  if (banner.dialog) {
                    setBulletinModal(true);
                    DatalayerAnalytics.pushBasicEvent('click', {
                      click_location: 'todays_offers'
                    });
                  }
                }}
                href={banner.cta.url || undefined}
                className="ib-button tbody14 font-bold sm-border-radius pointer text-primary">
                {banner.cta.text}
              </a>
            ) : null}
          </div>
          <Markdown additionalClass="ib-content" content={banner.description} />
        </div>
        {['INFO', 'WARN'].includes(banner.severity) ? (
          <div
            role="button"
            tabIndex={0}
            className="close pointer"
            aria-label="close"
            onClick={() => removeBanner(banner.id)}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                removeBanner(banner.id);
              }
            }}>
            <CloseIcon aria-hidden="true" />
          </div>
        ) : null}
      </div>
    </div>
  );
};
