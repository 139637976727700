import { PriceSection as Default } from './default';
import { PriceRangeSection } from './range';
import { ErrorBoundary } from '../../utils/nullErrorBoundary';
import { Consolidate_ProductVariant } from 'services';

export type PriceSectionProps = {
  options?: {
    biggerFont?: boolean;
    showOldPrice?: boolean;
    showOptions?: boolean;
  };
  variant: Pick<
    Consolidate_ProductVariant,
    'isSpecial' | 'specialPrice' | 'price' | 'option'
  >;
  showRange: false;
};

export type PriceRangeSectionProps = {
  variants: Pick<
    Consolidate_ProductVariant,
    'isSpecial' | 'specialPrice' | 'price' | 'option'
  >[];
  showRange: true;
};

export const fixedPrice = (price: number) => {
  if (JSON.stringify(price).includes('.')) {
    return price.toFixed(2);
  }
  return price;
};

export const PriceSection = ({
  props
}: {
  props: PriceSectionProps | PriceRangeSectionProps;
}): JSX.Element | null => {
  return props.showRange ? (
    <ErrorBoundary component="price-range-section">
      <PriceRangeSection {...props} />
    </ErrorBoundary>
  ) : (
    <ErrorBoundary component="price-range">
      <Default {...props} />
    </ErrorBoundary>
  );
};
