import { Dispatch } from 'react';

import {
  Consolidate_Product,
  DatalayerAnalytics,
  DutchiePlus_PricingType,
  Personalization,
  ProductTileType
} from 'services';
import {
  PExperienceProductsType,
  PExperienceType
} from '../components/Personalization';
import { ProductsData } from '@cura/next-server/GetProducts';
import { MonetateProductTileType } from '../components/ProductTile';
import { Experiences } from '../hooks/useMonetateExperience';
import { SiteWideWrapperProps } from '../hooks/siteWideContext';

export const getMonetatePageType = (pathname: string): string => {
  const pathSegments = pathname.toLowerCase().split('/');
  if (pathSegments.includes('products') && pathSegments.length === 6) {
    return 'shop/pdp';
  } else if (pathSegments.includes('cart')) {
    return 'shop/cart';
  } else if (pathSegments.length === 4) {
    return 'shop/home';
  }
  return '';
};

export const personalizedProductsMap = (
  personalized: Personalization.ProductRecExperience[],
  products: Consolidate_Product[]
): PExperienceType[] => {
  if (!products || !personalized) return [];
  const recommendations: PExperienceType[] = [];
  for (const p of personalized) {
    const experience = { ...p };
    let recItems: PExperienceProductsType[] = [];
    if (experience.recItems.length) {
      recItems = [...p.recItems].reduce(
        (arr: PExperienceProductsType[], ri) => {
          if (ri && products.length) {
            const matchingProduct = products.find(
              (prod) => prod?.id === ri.id || prod?.name === ri.name
            );
            if (matchingProduct) {
              arr.push({ ...matchingProduct, recToken: ri.recToken });
            }
          }
          return arr;
        },
        []
      );
    }
    recommendations.push({ ...experience, recItems });
  }
  return recommendations;
};

const getPersonalizedProductIds = (experiences: Experiences): string => {
  let ids = '';
  experiences.forEach((exp) => {
    if (exp.id === 'product-rec' && exp.recItems?.length) {
      exp.recItems.forEach((item) => (ids += `${item.id},`));
    }
  });
  return ids.substring(0, ids.length - 1); //delete last comma
};

export type PersonalizedCarousels = {
  top: PExperienceType[];
  bottom: PExperienceType[];
};

export type RecommendationCarousel = {
  all: ProductTileType[];
  category: ProductTileType[];
};

type PageType = 'HomePage' | 'CartPage' | 'ProductPage';

export const fetchProductsForExperiences = async (
  websiteUrl: string,
  experiences: Experiences,
  retailerId: string,
  defaultMenuType: DutchiePlus_PricingType,
  setPersonalized: Dispatch<PersonalizedCarousels>,
  pageType: PageType,
  monetateSort: SiteWideWrapperProps['sort']['value'],
  recommendationIds?: string[],
  setRecommendations?: Dispatch<RecommendationCarousel>,
  setLoading?: (bool: boolean) => void
) => {
  if (experiences.length) {
    await fetch(
      `${websiteUrl}/api/products/${retailerId}?menuType=${defaultMenuType}&pageType=${pageType}&personalizedProductIds=${getPersonalizedProductIds(experiences)}`,
      { next: { revalidate: process.env.DISABLE_REDIS ? 1 : 300 } }
    )
      .then((res) => res.json())
      .then(async ({ data }: { data: ProductsData }) => {
        if (data.products?.length) {
          const personalizedCarousels: PersonalizedCarousels = {
            top: [],
            bottom: []
          };
          experiences.forEach((exp) => {
            if (exp.id === 'product-rec') {
              const matchingRecItems = exp.recItems.reduce(
                (arr: MonetateProductTileType[], rec) => {
                  const matchingProduct = data.products.find(
                    (prod) => prod.id === rec.id
                  );
                  if (matchingProduct) {
                    arr.push({
                      ...matchingProduct,
                      recToken: rec.recToken
                    });
                  }
                  return arr;
                },
                []
              );
              if (matchingRecItems.length) {
                personalizedCarousels[exp.position].push({
                  ...exp,
                  recItems: matchingRecItems
                });
              }
            }
          });
          setPersonalized({
            top: personalizedCarousels.top.sort((a, b) => a.rank - b.rank),
            bottom: personalizedCarousels.bottom.sort((a, b) => a.rank - b.rank)
          });
          return;
        } else if (recommendationIds?.length && setRecommendations) {
          fetchProductsForRecommendations(
            websiteUrl,
            recommendationIds,
            retailerId,
            defaultMenuType,
            setRecommendations,
            pageType,
            monetateSort
          );
        }
      })
      .catch((error) => {
        DatalayerAnalytics.pushErrorEvent({
          category: 'api',
          location: 'fetchProductsForExperiences',
          description:
            (error as string) || 'Error fetching products for experiences'
        });
      })
      .finally(() => {
        setLoading && setLoading(false);
      });
  } else if (recommendationIds?.length && setRecommendations) {
    await fetchProductsForRecommendations(
      websiteUrl,
      recommendationIds,
      retailerId,
      defaultMenuType,
      setRecommendations,
      pageType,
      monetateSort
    );
  }
};

export const fetchProductsForRecommendations = async (
  websiteUrl: string,
  recommendationIds: string[],
  retailerId: string,
  defaultMenuType: DutchiePlus_PricingType,
  setRecommendations: Dispatch<RecommendationCarousel>,
  pageType: PageType,
  monetateSort: SiteWideWrapperProps['sort']['value']
) => {
  await fetch(
    `${websiteUrl}/api/products/${retailerId}?menuType=${defaultMenuType}&pageType=${pageType}&sort=${monetateSort}&recommendationIds=${recommendationIds.join(',')}&sort=${monetateSort}`,
    { next: { revalidate: process.env.DISABLE_REDIS ? 1 : 300 } }
  )
    .then((res) => res.json())
    .then(({ data }: { data: ProductsData }) => {
      if (data.products?.length) {
        const all = data.products.filter(
          (p) => p.recommendationCarouselType === 'all'
        );
        const category = data.products.filter(
          (p) => p.recommendationCarouselType === 'category'
        );
        setRecommendations({
          all,
          category
        });
      }
    })
    .catch((error) => {
      DatalayerAnalytics.pushErrorEvent({
        category: 'api',
        location: 'fetchProductsForRecommendations',
        description:
          (error as string) || 'Error fetching products for recommendations.'
      });
    });
};
