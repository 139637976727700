'use client';

import { PackageComponentWrapper } from '../../PackageComponentWrapper';
import { Disclaimer } from '../disclaimer';
import { ErrorBoundary } from '../../../utils/nullErrorBoundary';
import { KioskFooterLower } from './FooterLower';
import { KioskFooterProps } from '../index';

export const KioskFooterNavigation = ({
  copyrightInfo,
  disclaimer,
  legalLinks
}: KioskFooterProps): JSX.Element | null => {
  if (!legalLinks) return null;

  return (
    <ErrorBoundary component="kiosk-footer">
      <PackageComponentWrapper maxWidth={false}>
        <div className="footer-container-curaleaf" data-test="footer-container">
          {disclaimer && disclaimer.length ? (
            <Disclaimer disclaimer={disclaimer} />
          ) : null}
          <KioskFooterLower
            copyrightInfo={copyrightInfo}
            legalLinks={legalLinks}
          />
        </div>
      </PackageComponentWrapper>
    </ErrorBoundary>
  );
};
