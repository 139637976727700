import { useParams, usePathname } from 'next/navigation';
import { useEffect, useState } from 'react';
import { Personalization } from 'services';
import Cookies from 'js-cookie';

import {
  PreferenceType,
  UserPreferences
} from '../utils/trackMonetateImpressions';

function findMaxPreferences(preferenceObj: UserPreferences) {
  if (!preferenceObj) return {};
  const maxPreferences = {
    userProductViewPreferences: '',
    userAddToCartPreferences: '',
    userPurchasePreferences: ''
  };

  for (const preference in preferenceObj) {
    if (preferenceObj && preferenceObj[preference as keyof UserPreferences]) {
      const keys = Object.keys(
        preferenceObj[preference as keyof UserPreferences]
      );
      const maxKey = keys.reduce((a, b) => {
        const prefA =
          preferenceObj[preference as keyof UserPreferences][a] ?? 0;
        const prefB =
          preferenceObj[preference as keyof UserPreferences][b] ?? 0;
        return prefA >= 3 && prefA > prefB ? a : prefB >= 3 ? b : a;
      }, '');
      maxPreferences[preference as PreferenceType] = maxKey;
    }
  }

  if (
    maxPreferences.userProductViewPreferences.length ||
    maxPreferences.userAddToCartPreferences.length ||
    maxPreferences.userPurchasePreferences.length
  ) {
    return maxPreferences;
  }
  return {};
}

export type Experiences = Array<
  | Personalization.ProductRecExperience
  | Personalization.ABExperience
  | Personalization.SortExperience
>;

export const useMonetateExperience = ({
  pageTemplate
}: {
  pageTemplate: string;
}) => {
  const [experiences, setExperiences] = useState<Experiences>([]);
  const [isLoading, setLoading] = useState<boolean>(true);
  const pathname = usePathname();
  // note: params do not exist in the header navigation
  const params = useParams();
  const searchParams =
    typeof window !== 'undefined'
      ? new URLSearchParams(window.location.search)
      : null;
  const paramsCategoryKey = searchParams?.get('categoryKey');
  let categoryPreferences: {
    userProductViewPreferences?: string;
    userAddToCartPreferences?: string;
    userPurchasePreferences?: string;
  } = {};

  useEffect(() => {
    if (Object.keys(categoryPreferences).length) {
      for (const pref in categoryPreferences) {
        if (categoryPreferences[pref as keyof UserPreferences]) {
          window[pref as keyof UserPreferences] = categoryPreferences[
            pref as keyof UserPreferences
          ] as string;
        }
      }
    }
  }, [categoryPreferences]);

  useEffect(() => {
    setLoading(true);
    const slug =
      (typeof params.dispensarySlug === 'string' && params.dispensarySlug) ||
      pathname.split('/')[3] ||
      '';
    const productId = (typeof params.id === 'string' && params.id) || '';
    const categoryKey =
      paramsCategoryKey && typeof paramsCategoryKey === 'string'
        ? paramsCategoryKey
        : undefined;

    const rawCookie = Cookies.get('category_preferences');
    const parsedCookie = rawCookie ? JSON.parse(rawCookie) : null;
    categoryPreferences = findMaxPreferences(parsedCookie);

    Personalization.getExperience(
      Date.now() + '',
      window.location.href,
      pageTemplate,
      slug,
      productId,
      categoryKey,
      categoryPreferences
    )
      .then((experience) => {
        const newParse = Personalization.parseExperiences(experience);
        setExperiences([...newParse]);
      })
      .finally(() => setLoading(false));
  }, [pathname, pageTemplate]);

  return { experiences, isLoading };
};
