'use client';

import { useEffect, useState } from 'react';

import { UnauthorizedPage } from './unauthorized';
import { AccountDrawer } from '../../components/Account/Drawer';
import { AccountNavigation } from '../../components/Account/Navigation';
import { BreadCrumbs } from '../../components/Breadcrumbs';
import { CTAButton } from '../../components/CTAButton';
import { Loader } from '../../components/Account/Loader/loader';
import { PerksView } from '../../components/Account/Drawer/View/perks';
import { PointHistoryView } from '../../components/Account/Drawer/View/pointHistory';
import { StatusBar } from '../../components/Account/Loyalty/StatusBar';
import { PackageComponentWrapper } from '../../components/PackageComponentWrapper';
import { useLoyaltyData } from '../../hooks/graphs';
import { useSiteWideContext } from '../../hooks/siteWideContext';
import { DatalayerAnalytics, Monetate } from 'services';

import LoyaltyLeaf from '../../assets/svgs/loyalty/icon-loyalty-leaf-logo.svg';

import './account-preferences.scss';

export const LoyaltyPage = () => {
  const { isLoggedIn } = useSiteWideContext();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [mode, setMode] = useState<'history' | 'perks'>('history');
  const [initialPageLoad, setLoading] = useState(true);

  useEffect(() => {
    DatalayerAnalytics.pushPageView({
      page_type: 'account/loyalty'
    });
    Monetate.setPageType('account/loyalty');
  }, []);

  const { loyaltyMemberInfo, loading, error, isNewMember } =
    useLoyaltyData(isLoggedIn);

  useEffect(() => {
    if (initialPageLoad !== loading) {
      setLoading(loading);
    }
  }, [loading]);

  if (
    !initialPageLoad &&
    ((!loyaltyMemberInfo && !loading) ||
      !isLoggedIn ||
      error?.message === 'Loyalty can only be seen by authenticated users.')
  ) {
    return <UnauthorizedPage />;
  }

  const pointsDisclaimer =
    'Points cannot be earned or redeemed in OH, ND, and MA-adult use dispensaries.';

  return (
    <>
      <BreadCrumbs
        props={{
          breadcrumb: [
            {
              breadcrumbName: 'Home',
              urlFragment: '/',
              id: 0
            },
            {
              breadcrumbName: 'Account',
              urlFragment: '/account',
              id: 1
            }
          ],
          currentPage: 'Loyalty',
          isLoading: false
        }}
      />
      <PackageComponentWrapper additionalClass="account-preferences-wrapper bg-ecom">
        <AccountNavigation currentPage="loyalty" />
        <div className="loyalty">
          <h1 className="text-center margin-bottom-15 text-primary mh4">
            Curaleaf Rewards
          </h1>
          <div className="loyalty-status-wrapper bg-white">
            {initialPageLoad ? (
              <Loader />
            ) : loyaltyMemberInfo && !isNewMember ? (
              <>
                <AccountDrawer
                  visible={drawerOpen}
                  setVisibility={setDrawerOpen}>
                  {mode === 'history' ? (
                    <PointHistoryView />
                  ) : (
                    <PerksView
                      currentTier={loyaltyMemberInfo.currentTier}
                      nextTier={loyaltyMemberInfo.nextTier || null}
                      tierProgress={loyaltyMemberInfo.tierProgress}
                    />
                  )}
                </AccountDrawer>
                <div className="bb-light-grey margin-bottom-30 padding-bottom-15 d-flex justify-content-between">
                  <h5 className="text-dark-grey tbody18-bold">
                    Status: {loyaltyMemberInfo.currentTier?.prestige}
                  </h5>
                  <a
                    tabIndex={0}
                    className="details-button text-primary underline"
                    role="button"
                    onClick={() => {
                      setMode('perks');
                      setDrawerOpen(true);
                    }}>
                    <h5 className="h5-regular">View Details</h5>
                  </a>
                </div>
                <div className="d-flex justify-content-center">
                  <div className="points-container margin-bottom-30 d-flex flex-column align-items-center">
                    <div className="margin-bottom-30">
                      <LoyaltyLeaf />
                    </div>
                    <h3 className="available-header text-dark-grey text-center margin-bottom-15 th1-bold">
                      <b>
                        {loyaltyMemberInfo.totalPointsAvailable} Points
                        Available
                      </b>
                    </h3>
                    <span className="text-grey margin-bottom-30 subtitle">
                      Redeem on your next purchase
                    </span>
                    <StatusBar
                      nextTierPrestige={
                        loyaltyMemberInfo.nextTier
                          ? loyaltyMemberInfo.nextTier.prestige
                          : loyaltyMemberInfo.currentTier.prestige
                      }
                      tierProgress={loyaltyMemberInfo.tierProgress}
                    />
                    <small className="text-grey margin-bottom-15">
                      {pointsDisclaimer}
                    </small>
                    <CTAButton
                      callBackFunc={() => {
                        setMode('history');
                        setDrawerOpen(true);
                      }}
                      text="View Point History"
                      variant="account"
                    />
                  </div>
                </div>
              </>
            ) : (
              <div className="l-content d-flex justify-content-center">
                <div className="points-container margin-bottom-30  d-flex flex-column align-items-center">
                  <div className="margin-bottom-30">
                    <LoyaltyLeaf />
                  </div>
                  <h3 className="text-dark-grey text-center margin-bottom-15 th1">
                    Make a purchase to begin earning rewards.
                  </h3>
                  <span className="text-grey margin-bottom-30">
                    Earn 3 points for every $100 spent.
                  </span>
                  <small className="margin-bottom-15 text-grey">
                    {pointsDisclaimer}
                  </small>
                  <CTAButton
                    url="/locations?shop"
                    text="Start Shopping"
                    variant="account"
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </PackageComponentWrapper>
    </>
  );
};
