import { PackageComponentWrapper } from '../PackageComponentWrapper';
import { Accordion } from './Accordion';
import { Markdown } from '../Markdown';

import './faq-block.scss';

export type FAQBlockProps = {
  anchor?: string;
  content: string;
  faqs: { answer: string; question: string }[];
};

export const FAQBlock = ({
  anchor,
  content,
  faqs
}: FAQBlockProps): JSX.Element | null => {
  if (!faqs || !faqs.length) return null;

  return (
    <PackageComponentWrapper additionalClass="m-container">
      <div id={anchor} className="faq-block-curaleaf" data-test="faq-block">
        <div className="heading-content">
          {content && content.length ? (
            <Markdown
              additionalClass="heading justify-content-space-center"
              data-test="text-block-content"
              content={content}
            />
          ) : null}
        </div>
        {faqs.map((element, i) => (
          <Accordion faq={element} key={i} />
        ))}
      </div>
    </PackageComponentWrapper>
  );
};
