import Cash from '../../../assets/svgs/store-offers/cash.svg';
import Debit from '../../../assets/svgs/store-offers/debit.svg';
import Delivery from '../../../assets/svgs/store-offers/delivery.svg';
import HC from '../../../assets/svgs/store-offers/hc.svg';
import Instore from '../../../assets/svgs/store-offers/in-store.svg';
import Parking from '../../../assets/svgs/store-offers/parking.svg';
import Pickup from '../../../assets/svgs/store-offers/pickup.svg';

export type StoreOffersProps = {
  offers: {
    id: string;
    type: string;
    text: string;
  }[];
};

const Icons = {
  HC: <HC />,
  PARKING: <Parking />,
  CASH: <Cash />,
  DEBIT: <Debit />,
  PICKUP: <Pickup />,
  INSTORE: <Instore />,
  DELIVERY: <Delivery />
};

const getIcon = (iconName: string) => {
  switch (iconName) {
    case 'HC':
      return Icons.HC;
    case 'PARKING':
      return Icons.PARKING;
    case 'CASH':
      return Icons.CASH;
    case 'DEBIT':
      return Icons.DEBIT;
    case 'PICKUP':
      return Icons.PICKUP;
    case 'INSTORE':
      return Icons.INSTORE;
    case 'DELIVERY':
      return Icons.DELIVERY;
    default:
      return Icons.PICKUP;
  }
};

const StoreOffers = ({ offers }: StoreOffersProps): JSX.Element | null => {
  if (!offers.length) return null;

  return (
    <section className="store-offers" data-test="store-offers">
      {offers.map((item, index) => (
        <div
          key={item.id || index}
          className="d-flex flex-row align-items-center"
          data-test="store-offer-item">
          <div
            className="store-offer-icon margin-right-25 d-flex align-items-center "
            data-test="store-offer-icon">
            {getIcon(item.type)}
          </div>
          <p
            className="store-offer-text margin-0 tbody18-bold tbody14-bold-mobile"
            data-test="store-offer-text">
            {item.text}
          </p>
        </div>
      ))}
    </section>
  );
};

export default StoreOffers;
