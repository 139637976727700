'use client';

import { useState } from 'react';

import { useSiteWideContext } from '../../../hooks/siteWideContext';
import { Markdown } from '../../Markdown';

import FaqClose from '../../../assets/svgs/faq/faq-close.svg';
import FaqOpen from '../../../assets/svgs/faq/faq-open.svg';
import FaqCloseMobile from '../../../assets/svgs/faq/faq-close-mobile.svg';
import FaqOpenMobile from '../../../assets/svgs/faq/faq-open-mobile.svg';

type ComponentProps = {
  faq: {
    answer: string;
    question: string;
  };
};

const renderIcon = (width: number | undefined, isOpen: boolean) => {
  if (width && width < 768) {
    return isOpen ? (
      <FaqCloseMobile data-test="curaleaf-close-mobile" />
    ) : (
      <FaqOpenMobile data-test="curaleaf-open-mobile" />
    );
  } else {
    return isOpen ? (
      <FaqClose data-test="curaleaf-close-dt" />
    ) : (
      <FaqOpen data-test="curaleaf-open-dt" />
    );
  }
};

export const renderExpandButton = (
  open: boolean,
  setOpen: (bool: boolean) => void,
  width?: number
) => {
  return (
    <button
      data-test="expand-btn"
      type="button"
      aria-expanded={open}
      className="faq-btn"
      onClick={() => setOpen(!open)}>
      {renderIcon(width, open)}
    </button>
  );
};

export const Accordion = ({ faq }: ComponentProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const { width } = useSiteWideContext();
  return (
    <div className="faq-container" data-test="faq-accordion">
      <div className="question" data-test="question">
        <>
          <h6
            className="heading mh6 text-primary margin-bottom-0"
            data-test="heading">
            {faq.question}
          </h6>
          {renderExpandButton(open, setOpen, width)}
        </>
      </div>
      <div className={open ? 'answer active' : 'answer'}>
        <Markdown data-test="text-block-content" content={faq.answer} />
      </div>
    </div>
  );
};
