import { LoadingProps } from '.';
import { useSiteWideContext } from '../../hooks/siteWideContext';
import { LoadingSkeleton } from '../Loader/Skeleton';
import { PackageComponentWrapper } from '../PackageComponentWrapper';

export const Loader = ({ type }: LoadingProps) => {
  const { width } = useSiteWideContext();

  const renderPriceSide = () => {
    switch (type) {
      case 'cart': {
        return (
          <div className="qpd-info d-flex align-items-center">
            <div className="qd d-flex flex-column align-items-center">
              <LoadingSkeleton width="120px" height="56px" />
              <div className="delete tbody18 pointer underline margin-top-10">
                <LoadingSkeleton width="70px" />
              </div>
            </div>
            {width && width > 991 ? (
              <div className="price-info text-right">
                <LoadingSkeleton width="60px" />
              </div>
            ) : null}
          </div>
        );
      }
      case 'order': {
        return (
          <div className="pv-info d-flex justify-content-end align-items-center">
            <div className="quantity-tag" />
            <div
              data-has-variant="true"
              className="variant-button bg-white d-flex flex-column align-items-center justify-content-center">
              <span>
                <LoadingSkeleton width="30px" />
              </span>
              <LoadingSkeleton width="88px" height="80px" />
            </div>
          </div>
        );
      }
      case 'product': {
        return (
          <div className="variants-container variants-container-loader d-flex justify-content-end inline-btn">
            <div data-has-variant="true">
              <LoadingSkeleton width="88px" height="80px" />
            </div>
          </div>
        );
      }
      default: {
        return null;
      }
    }
  };
  return (
    <PackageComponentWrapper>
      <div className="product-list-tile  d-flex bg-white padding-15">
        <div className="info-container d-flex align-items-center justify-content-between">
          <div className="inbv-info d-flex">
            <div className="image-container margin-right-10">
              <LoadingSkeleton width="130px" height="130px" />
            </div>
            <div className="nbv d-flex flex-column justify-content-center">
              <LoadingSkeleton width="150px" height="28px" />
              <LoadingSkeleton width="100px" height="28px" />
              <LoadingSkeleton width="30px" />
              {type === 'cart' && width && width <= 991 ? (
                <div className="price-info text-right">
                  <LoadingSkeleton width="60px" />
                </div>
              ) : null}
            </div>
          </div>
          {renderPriceSide()}
        </div>
      </div>
    </PackageComponentWrapper>
  );
};
