import { LoadingSkeleton } from '../Loader/Skeleton';
import { PackageComponentWrapper } from '../PackageComponentWrapper';

export const Loader = (): JSX.Element => {
  return (
    <PackageComponentWrapper additionalClass="bg-ecom">
      <div className="information-banner-carousel">
        <div className="information-banner">
          <div
            data-banner-type="LOAD"
            className="ib-container d-flex justify-content-between align-items-center padding-15">
            <div className="ib-contents d-flex flex-column">
              <div className="ib-header d-flex align-items-center">
                <p className="margin-0 tbody14 font-bold text-color-dark-grey">
                  <LoadingSkeleton width="100px" height="20px" />
                </p>
                <p className="margin-0 tbody14 font-bold text-color-dark-grey">
                  <LoadingSkeleton width="60px" height="20px" />
                </p>
              </div>
              <LoadingSkeleton width="200px" height="20px" />
            </div>
          </div>
        </div>
      </div>
    </PackageComponentWrapper>
  );
};
