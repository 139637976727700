import { Dispatch, SetStateAction } from 'react';

import { InformationBannerType } from 'services';
import { InformationBanners as Default } from './default';
import { Loader } from './loader';
import { ErrorBoundary } from '../../utils/nullErrorBoundary';

import './information-banners.scss';

export type InformationBannersProps = {
  banners: InformationBannerType[];
  isLoading: boolean;
  setBanners: Dispatch<SetStateAction<InformationBannerType[]>>;
  sticky?: boolean;
};

export const InformationBanners = ({
  props
}: {
  props: InformationBannersProps;
}): JSX.Element | null => {
  if (!props.banners.length && !props.isLoading) return null;
  return props.isLoading ? (
    <Loader />
  ) : (
    <ErrorBoundary component="information-banners">
      <div data-infomation-stick-banner={props.sticky ?? false}>
        <Default {...props} />
      </div>
    </ErrorBoundary>
  );
};
