'use client';

import { ErrorInfo, ReactNode, useEffect, useState } from 'react';

interface Props {
  children: ReactNode;
  component: string;
}

interface State {
  hasError: boolean;
  error: Error | null;
  errorInfo: ErrorInfo | null;
}

export const ErrorBoundary = (props: Props) => {
  const [errors, setErrors] = useState<State>({
    hasError: false,
    error: null,
    errorInfo: null
  });

  useEffect(() => {
    setErrors((prevState) => ({
      ...prevState,
      hasError: false,
      error: null,
      errorInfo: null
    }));
  }, [props]);

  const handleComponentError = (error: Error, errorInfo: ErrorInfo) => {
    // Log error to the console
    console.warn(`Error caught in ${props.component}: ${error}`);

    // Set error state
    setErrors({
      hasError: true,
      error,
      errorInfo
    });
  };

  useEffect(() => {
    // Set up global error handler
    window.addEventListener('error', (event: ErrorEvent) => {
      handleComponentError(event.error, {
        componentStack: event?.error?.stack
      });
    });
  }, [handleComponentError]);

  // TODO: Rather than passing null, we need to think about what to do if an error occurs
  // const renderFallbackUI = () => {
  //   return (
  //     <div>
  //       <h1 className="mh4">Something went wrong.</h1>
  //       <p>{errors.error && errors.error.toString()}</p>
  //       <pre>{errors.errorInfo && errors.errorInfo.componentStack}</pre>
  //     </div>
  //   );
  // };

  if (errors.hasError) {
    return null;
  }

  return <>{props.children}</>;
};
