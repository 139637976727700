'use client';

import { usePathname } from 'next/navigation';

import { PackageComponentWrapper } from '../PackageComponentWrapper';
import { LoadingSkeleton } from '../Loader/Skeleton';

import CuraleafArrow from '../../assets/svgs/arrows/breadcrumb-arrow.svg';

export const Loader = (): JSX.Element | null => {
  const asPath = usePathname();
  const backgroundColor =
    asPath.includes('shop') || asPath.includes('account')
      ? 'bg-ecom'
      : 'bg-white';

  return (
    <PackageComponentWrapper additionalClass={backgroundColor}>
      <nav
        aria-label="breadcrumb"
        role="navigation"
        data-test="breadcrumb-container"
        className="Breadcrumbs">
        <ul className="d-flex align-items-center margin-0">
          <li className="link-container d-flex align-items-center">
            <p className="breadcrumb-name margin-0">
              <LoadingSkeleton height="20px" width="50px" />
            </p>
            <CuraleafArrow data-test="curaleaf-arrow-icon" />
          </li>
          <li className="link-container d-flex align-items-center">
            <p className="breadcrumb-name margin-0">
              <LoadingSkeleton height="20px" width="50px" />
            </p>
            <CuraleafArrow data-test="curaleaf-arrow-icon" />
          </li>
          <li className="link-container d-flex align-items-center">
            <p className="breadcrumb-name margin-0">
              <LoadingSkeleton height="20px" width="50px" />
            </p>
            <CuraleafArrow data-test="curaleaf-arrow-icon" />
          </li>
        </ul>
      </nav>
    </PackageComponentWrapper>
  );
};
