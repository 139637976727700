'use client';

import { useState, useEffect, useCallback } from 'react';
import { Autocomplete, GoogleMap, useLoadScript } from '@react-google-maps/api';
import { Subject } from 'rxjs';
import classNames from 'classnames';

import NoSSR from './NoSSR';
import { StorePreference } from './StorePreference';
import { CTAButton } from '../CTAButton';
import { Markdown } from '../Markdown';
import { PackageComponentWrapper } from '../PackageComponentWrapper';
import { ErrorBoundary } from '../../utils/nullErrorBoundary';
import { useSiteWideContext } from '../../hooks/siteWideContext';
import { MOBILE_WIDTH } from '../../utils/constants';
import { LocationType } from '../../models/types';

import LocationIcon from '../../assets/svgs/locations/icon-location.svg';
import SearchIcon from '../../assets/svgs/header/search.svg';

import './location-finder.scss';

let locationSelectedState = {};
const locationSelected = new Subject();

export type CoordinatesInput = {
  latitude: number;
  longitude: number;
};

const containerStyle = {
  height: '400px',
  borderRadius: '4px'
};

const geocodeByLatLng = (latLng: {
  lat: number;
  lng: number;
}): Promise<google.maps.GeocoderResult[]> => {
  const geocoder = new window.google.maps.Geocoder();
  const { OK } = window.google.maps.GeocoderStatus;

  return new Promise((resolve, reject) => {
    geocoder.geocode(
      { location: latLng },
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      (
        results: google.maps.GeocoderResult[],
        status: google.maps.GeocoderStatus
      ) => {
        if (status !== OK) return reject(status);

        return resolve(results);
      }
    );
  });
};

const center = {
  lat: 39.8097343,
  lng: -98.5556199
};

type LocationSelectedChannelType = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  subscribe: any;
  publish: (location: LocationType) => void;
};

export const locationSelectedChannel: LocationSelectedChannelType = {
  subscribe: (setState: () => void) => locationSelected.subscribe(setState),
  publish: (location: LocationType) => {
    locationSelectedState = { ...location };
    locationSelected.next(locationSelectedState);
  }
};

type ToggleType = {
  id: number;
  toggleName: string;
  preference: string;
  isActive: boolean;
};

export type PreferencesType = {
  toggles: ToggleType[];
  heading?: string;
};

type LocationFinderProps = {
  heading?: string;
  current_location?: string;
  input_placeholder?: string;
  search_button: string;
  enable_map?: boolean;
  storepref: PreferencesType | null;
  className?: string;
  mode?: 'locations' | 'storedrawer';
  storeDrawerCallback?: (coords?: CoordinatesInput) => void;
  setStoreDrawerLoading?: (bool: boolean) => void;
};

const googleMapsApiLibraries: ('places' | 'drawing')[] = ['drawing', 'places'];

export const LocationFinder = (
  props: LocationFinderProps
): JSX.Element | null => {
  const {
    current_location,
    enable_map = false,
    heading,
    input_placeholder,
    mode = 'locations',
    search_button,
    setStoreDrawerLoading,
    storeDrawerCallback,
    storepref
  } = props;

  const { userOrderType, width } = useSiteWideContext();

  const [marker, setMarker] = useState<google.maps.Marker | null>(null);
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [location, setLocation] =
    useState<google.maps.places.PlaceResult | null>(null);
  const [autoComplete, setAutoComplete] =
    useState<google.maps.places.Autocomplete | null>(null);
  const [addressInput, setAddressInput] = useState<string>('');

  const isMapEnabled = () => {
    return enable_map && width && width > MOBILE_WIDTH;
  };

  const { isLoaded, loadError } = useLoadScript({
    id: 'google-map-script',
    googleMapsApiKey: `${process.env.NEXT_PUBLIC_GCP_API_KEY}`,
    libraries: googleMapsApiLibraries
  });

  const onGoogleMapLoad = useCallback(function callback(map: google.maps.Map) {
    setMap(map);
    map.setCenter(center);

    setMarker(
      new google.maps.Marker({
        map,
        anchorPoint: new google.maps.Point(0, -29)
      })
    );
  }, []);

  const onAutoCompleteLoad = useCallback(
    (autoComplete: google.maps.places.Autocomplete) =>
      setAutoComplete(autoComplete),
    []
  );

  useEffect(() => {
    if (isMapEnabled() && map && marker) {
      marker.setVisible(false);

      if (location?.geometry) {
        if (location.geometry.viewport) {
          map.fitBounds(location.geometry.viewport);
        } else if (location.geometry.location) {
          map.setCenter(location.geometry.location);
          map.setZoom(20);
        }

        marker.setPosition(location.geometry.location);
        marker.setVisible(true);
      }
    }
  }, [location]);

  useEffect(() => {
    const autoCompleteDropdown = document.createElement('style');
    // Each google autocomplete dropdown needs a different z-index
    autoCompleteDropdown.innerHTML = `
      .pac-container {
        z-index: ${mode === 'locations' ? 101 : 103}
      }
    `;

    document.head.appendChild(autoCompleteDropdown);

    // Cleanup function
    return () => {
      document.head.removeChild(autoCompleteDropdown);
    };
  }, [mode]);

  const getCurrentLocation = () => {
    if (navigator.geolocation) {
      if (setStoreDrawerLoading) setStoreDrawerLoading(true);
      navigator.geolocation.getCurrentPosition((position) => {
        if (mode === 'storedrawer' && storeDrawerCallback) {
          storeDrawerCallback({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
          });
        } else {
          geocodeByLatLng({
            lat: position.coords.latitude,
            lng: position.coords.longitude
          })
            .then((data) => {
              const results = data[0] as google.maps.GeocoderResult;
              setLocation(results);
              setAddressInput(results.formatted_address);
            })
            .catch((error: string) => console.error(error));
        }
      });
      if (setStoreDrawerLoading) setStoreDrawerLoading(false);
    }
  };

  // This function is used to get the first prediction in the dropdown as a string
  const getFirstPrediction = () => {
    const itemQueryElement = document.querySelector('.pac-item-query');
    const itemQueryText = itemQueryElement ? itemQueryElement.textContent : '';
    const pacMatchedElement = document.querySelector('.pac-matched');
    const pacMatchedText = pacMatchedElement
      ? pacMatchedElement.textContent
      : '';

    // Remove the 'pac-matched' text to get the remainder of prediction text
    const comparisonText =
      itemQueryText && pacMatchedText
        ? itemQueryText.replace(pacMatchedText, '').trim()
        : '';

    return pacMatchedText + comparisonText;
  };

  const getLatLngFromPlacePrediction = (): Promise<
    CoordinatesInput | undefined
  > => {
    return new Promise((resolve) => {
      const input = getFirstPrediction();
      const service = new google.maps.places.AutocompleteService();
      const placesStatus = google.maps.places.PlacesServiceStatus.OK;
      service.getPlacePredictions({ input }, (predictions, status) => {
        if (status === placesStatus && predictions?.length) {
          const placeId = predictions[0]?.place_id || '';
          const placeService = new google.maps.places.PlacesService(
            document.createElement('div')
          );
          placeService.getDetails({ placeId }, (placeResult, placeStatus) => {
            if (
              placeStatus === placesStatus &&
              placeResult?.geometry?.location
            ) {
              if (placeResult.formatted_address)
                setAddressInput(placeResult.formatted_address);
              const { lat: latitude, lng: longitude } =
                placeResult.geometry.location.toJSON();
              resolve({ latitude, longitude });
            } else {
              resolve(undefined);
            }
          });
        } else {
          resolve(undefined);
        }
      });
    });
  };

  const triggerLocationSelectedEvent = async (
    input?: google.maps.places.PlaceResult
  ) => {
    let coords;
    if (input) {
      coords = {
        latitude: input.geometry?.location?.lat(),
        longitude: input.geometry?.location?.lng()
      };
    } else {
      const latlng = await getLatLngFromPlacePrediction();
      if (latlng) coords = latlng;
    }
    if (coords?.latitude && coords.longitude) {
      if (storeDrawerCallback && mode === 'storedrawer') {
        storeDrawerCallback({
          latitude: coords.latitude,
          longitude: coords.longitude
        });
      } else {
        locationSelectedChannel.publish({
          lat: coords.latitude,
          lng: coords.longitude
        });
      }
    }
  };

  const onPlaceChanged = async () => {
    const place = autoComplete && autoComplete.getPlace();
    if (place) {
      await triggerLocationSelectedEvent(place).then(() => {
        setLocation(place);
        setAddressInput(place.formatted_address ?? addressInput);
      });
    }
  };

  if (loadError) {
    return (
      <div>
        Location Finder cannot be loaded right now, please try again shortly.
      </div>
    );
  }

  return (
    <PackageComponentWrapper minWidth={false}>
      <ErrorBoundary component="location-finder">
        {isLoaded && (
          <div
            data-test="location-finder-container"
            className={
              mode !== 'storedrawer'
                ? `text-center search-panel container ${props.className || ''}`
                : 'store-drawer-search-panel'
            }>
            {mode === 'locations' && heading && heading.length ? (
              <Markdown
                additionalClass="search-header text-primary"
                data-test="search-header"
                content={heading}
              />
            ) : null}
            <div
              className={
                isMapEnabled() ? 'd-flex' : 'd-flex justify-content-center'
              }>
              <div
                className={classNames({
                  'col-12 col-md-6': isMapEnabled(),
                  'col-12 col-md-8': mode !== 'storedrawer'
                })}>
                <div className="search-container">
                  <div
                    className={classNames('search-input-wrapper d-flex', {
                      'align-items-start': mode !== 'storedrawer',
                      'justify-content-between': mode === 'storedrawer'
                    })}>
                    <div
                      className={classNames({
                        'search-input col-12 col-md-8 ': mode !== 'storedrawer',
                        'search-input-col d-flex align-items-center':
                          mode === 'storedrawer'
                      })}
                      data-test="search-input">
                      {(process.env.NEXT_PUBLIC_GCP_API_KEY as string) ? (
                        <>
                          {mode === 'storedrawer' ? <SearchIcon /> : null}
                          <NoSSR>
                            <Autocomplete
                              onLoad={onAutoCompleteLoad}
                              onPlaceChanged={onPlaceChanged}
                              options={{
                                types:
                                  mode === 'storedrawer' &&
                                  userOrderType === 'DELIVERY'
                                    ? [
                                        'street_number',
                                        'street_address',
                                        'postal_code',
                                        'postal_code_suffix',
                                        'route'
                                      ]
                                    : []
                              }}
                              data-test="Google-auto-complete">
                              <input
                                type="text"
                                placeholder={input_placeholder}
                                value={addressInput}
                                onChange={(event) =>
                                  setAddressInput(event.target.value)
                                }
                                className="search-input tbody16"
                                onKeyDown={async (event) => {
                                  if (event.key === 'Enter') {
                                    await triggerLocationSelectedEvent();
                                  }
                                }}
                              />
                            </Autocomplete>
                          </NoSSR>
                        </>
                      ) : (
                        'No Google API key provided '
                      )}
                    </div>
                    <div
                      className={classNames('cta-wrapper', {
                        'col-12 col-md-4': mode !== 'storedrawer'
                      })}>
                      <CTAButton
                        text={search_button}
                        callBackFunc={triggerLocationSelectedEvent}
                        variant="secondary"
                        data-test="search-cta"
                      />
                    </div>
                  </div>
                  {mode !== 'storedrawer' ? (
                    <div
                      tabIndex={0}
                      data-test="current-location"
                      className="use-current-location"
                      onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                          getCurrentLocation();
                        }
                      }}
                      onClick={() => getCurrentLocation()}>
                      <LocationIcon />
                      <span className="current-location-text">
                        {current_location || ''}
                      </span>
                    </div>
                  ) : null}
                </div>
                {storepref && mode !== 'storedrawer' ? (
                  <div>
                    <StorePreference data={storepref} />
                  </div>
                ) : storepref && mode === 'storedrawer' ? (
                  <div className="d-flex margin-top-20 margin-bottom-25 justify-content-between">
                    <div className="use-current-location">
                      <span
                        tabIndex={0}
                        data-test="current-location"
                        className="current-location-text tbody12 underline pointer"
                        onKeyDown={(event) => {
                          if (event.key === 'Enter') {
                            getCurrentLocation();
                          }
                        }}
                        onClick={() => getCurrentLocation()}>
                        {current_location || ''}
                      </span>
                    </div>
                  </div>
                ) : null}
              </div>
              {mode !== 'storedrawer' && isMapEnabled() && (
                <div className="col-md-6" data-test="google-map">
                  <GoogleMap
                    mapContainerStyle={containerStyle}
                    onLoad={onGoogleMapLoad}
                    zoom={4}
                    center={center}
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </ErrorBoundary>
    </PackageComponentWrapper>
  );
};
