import GeoPin from '../../../assets/svgs/overview/geo-pin-teal.svg';
import Mail from '../../../assets/svgs/overview/mail-teal.svg';
import Phone from '../../../assets/svgs/overview/phone-teal.svg';

export type StoreInformationProps = {
  address: string;
  googleMapsUri: string;
  number: string;
  url: string;
};

const StoreInformation = ({
  address,
  googleMapsUri,
  number,
  url
}: StoreInformationProps): JSX.Element | null => {
  return (
    <section
      className="store-info d-flex flex-column"
      data-test="store-info-component">
      <h6 className="mh6 text-color-primary" data-test="store-info-header">
        Store Information
      </h6>
      {address ? (
        <a
          data-test="store-address"
          className="d-flex align-items-center text-color-black underline pointer"
          onClick={() => {
            if (googleMapsUri) window.open(googleMapsUri, '_blank');
          }}>
          <GeoPin />
          <span>{address}</span>
        </a>
      ) : null}
      {number ? (
        <a
          data-test="store-info-number"
          className="d-flex align-items-center text-color-black"
          href={`tel:${number}`}>
          <Phone />
          <span>{number}</span>
        </a>
      ) : null}
      <a
        data-test="store-info-contact-us"
        className="d-flex align-items-center text-color-black underline"
        href={url}>
        <Mail />
        <span>Contact Us</span>
      </a>
    </section>
  );
};

export default StoreInformation;
