import { CTAButtonVariants, changeButtonText } from '../../../CTAButton';
import { PackageComponentWrapper } from '../../../PackageComponentWrapper';

import './account-cta-button.scss';

export type EcomButtonProps = {
  disabled?: boolean;
  loading: boolean;
  text: string;
  variant?: CTAButtonVariants;
};

export const EcomCTAButton = ({
  disabled,
  loading,
  text,
  variant = 'primary'
}: EcomButtonProps): JSX.Element | null => {
  return (
    <PackageComponentWrapper minWidth={false}>
      <div className="ecom-button-curaleaf">
        <button
          tabIndex={0}
          type="submit"
          disabled={disabled || loading}
          className={`ecom-button-${variant}`}>
          {loading ? <i className="pi pi-spin pi-spinner" /> : null}
          {text && changeButtonText(text)}
        </button>
      </div>
    </PackageComponentWrapper>
  );
};
