import classNames from 'classnames';
import { useRouter } from 'next/navigation';

import { PackageComponentWrapper } from '../../PackageComponentWrapper';
import { ErrorBoundary } from '../../../utils/nullErrorBoundary';

import './account-navigation.scss';

type AccountNavigationProps = {
  currentPage: 'settings' | 'loyalty' | 'best-buds' | 'orders';
};

export const AccountNavigation = ({
  currentPage
}: AccountNavigationProps): JSX.Element | null => {
  const { push } = useRouter();

  return (
    <PackageComponentWrapper additionalClass="bg-ecom">
      <ErrorBoundary component="account-navigation">
        <div className="account-navigation bb-light-grey">
          <button
            disabled={currentPage === 'settings'}
            onClick={() => push('/account')}
            className={classNames('bg-white sm-border-radius', {
              active: currentPage === 'settings'
            })}>
            Settings
          </button>
          <button
            disabled={currentPage === 'loyalty'}
            onClick={() => push('/account/loyalty')}
            className={classNames('bg-white sm-border-radius', {
              active: currentPage === 'loyalty'
            })}>
            Curaleaf Rewards
          </button>
          {process.env.NEXT_PUBLIC_ENABLE_ORDER_HISTORY === 'true' ? (
            <button
              disabled={currentPage === 'orders'}
              onClick={() => push('/account/orders')}
              className={classNames('bg-white sm-border-radius', {
                active: currentPage === 'orders'
              })}>
              Orders
            </button>
          ) : null}
          {process.env.NEXT_PUBLIC_HIDE_REFERRAL !== 'true' ? (
            <button
              disabled={currentPage === 'best-buds'}
              onClick={() => push('/account/best-buds')}
              className={classNames('bg-white sm-border-radius', {
                active: currentPage === 'best-buds'
              })}>
              Best Buds
            </button>
          ) : null}
        </div>
      </ErrorBoundary>
    </PackageComponentWrapper>
  );
};
