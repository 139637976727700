import { Personalization as Default } from './default';
import { ErrorBoundary } from '../../utils/nullErrorBoundary';
import { Personalization } from 'services';
import { MonetateProductTileType } from '../ProductTile';

export type PExperienceType = Omit<
  Personalization.ProductRecExperience,
  'recItems'
> & { recItems: PExperienceProductsType[] };

export type PExperienceProductsType = MonetateProductTileType;

export type PersonalizationCarouselProps = {
  backgroundColor?: string;
  isLoading: boolean;
  recommendations: PExperienceType[];
};

export const PersonalizationCarousel = ({
  props
}: {
  props: PersonalizationCarouselProps;
}): JSX.Element | null => {
  if (!props.recommendations.length && !props.isLoading) return null;
  return (
    <ErrorBoundary component="personalization-carousel">
      <Default {...props} />
    </ErrorBoundary>
  );
};
