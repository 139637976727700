import { LoadingSkeleton } from '../Loader/Skeleton';
import { PackageComponentWrapper } from '../PackageComponentWrapper';

export const Loader = () => {
  const dotsArray = new Array(3).fill(undefined);
  return (
    <PackageComponentWrapper additionalClass="bg-ecom">
      <div className="storefront-banners-carousel">
        <LoadingSkeleton width="100%" height="265px" />
        <div className="storefront-banner-dots thumbs">
          {dotsArray.map((_, i) => (
            <button
              key={i}
              className="sm-dot pointer"
              type="button"
              aria-label="sm-dot">
              <LoadingSkeleton width="8px" height="8px" />
            </button>
          ))}
        </div>
      </div>
    </PackageComponentWrapper>
  );
};
