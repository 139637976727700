import { FormikProps, useFormik } from 'formik';

export const isFormFieldValid = (
  name: string,
  formik: FormikProps<{ [key: string]: string[] }>
) => !!formik.errors[name];

export const getFormErrorMessage = (
  name: string,
  formik: FormikProps<{ [key: string]: string[] }>,
  errorMessage?: string
) => {
  return isFormFieldValid(name, formik) || errorMessage ? (
    <small className="p-error">{errorMessage || formik.errors[name]}</small>
  ) : null;
};

export const validationErrorCheck = (
  errors: { [key: string]: string } | Record<string, never>
) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return Object.fromEntries(Object.entries(errors).filter(([_, v]) => v != ''));
};

export const DefaultFormik = () => {
  return useFormik({
    initialValues: {
      test: []
    },
    validate: (data) => {
      const errors = {} as {
        test: 'Required';
      };

      if (!data.test) errors.test = 'Required';
      return errors;
    },
    onSubmit: () => {
      /* DO NOTHING */
    }
  });
};
