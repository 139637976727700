import { LoadingSkeleton } from '../Loader/Skeleton';

export const Loader = () => {
  return (
    <div
      className="category-tile-container bg-white padding-bottom-10 sm-border-radius"
      data-test="category-tile">
      <div className="tile flex-column align-items-center justify-content-center">
        <LoadingSkeleton width="55px" height="55px" variant="round-img" />
        <h5
          className="tbody14-bold text-primary margin-0 margin-top-5"
          data-test="header">
          <LoadingSkeleton width="50px" />
        </h5>
      </div>
    </div>
  );
};
