import Link from 'next/link';
import { ReactNode } from 'react';
import classNames from 'classnames';

import { PopoutHeader } from './header';
import { UserLink, UserMenu } from './userMenu';
import { SocialMediaLinksData } from '../../../models/types';
import { useSiteWideContext } from '../../../hooks/siteWideContext';
import { SocialIcons } from '../../SocialIcons';

type HeaderDrawerProps = {
  children?: ReactNode;
  closeNav: () => void;
  navOpen: boolean;
  shopHome: () => void;
  socialLinks: SocialMediaLinksData[];
  type: 'navigation' | 'popup';
  userLinks: UserLink[];
};

export const HeaderDrawer = ({
  children,
  closeNav,
  navOpen,
  shopHome,
  socialLinks,
  type,
  userLinks
}: HeaderDrawerProps): JSX.Element | null => {
  const { isMobile } = useSiteWideContext();
  const isStateSite =
    process.env.CONTENTSTACK_ENVIRONMENT.includes('ct') ||
    process.env.CONTENTSTACK_ENVIRONMENT.includes('nd');
  const links = [
    {
      title: 'Find a Dispensary',
      url: isStateSite ? '/#locations' : '/locations'
    },
    {
      title: 'Contact Us',
      url: '/contact-us'
    }
  ];
  const extraLinks = (
    <div className="extra-links d-flex flex-column">
      {links.map((link) => (
        <Link
          href={link.url}
          key={link.title}
          tabIndex={0}
          className="tbody16 text-color-dark-grey">
          {link.title}
        </Link>
      ))}
    </div>
  );

  switch (type) {
    case 'navigation': {
      return (
        <nav id="navigation" className="navigation" data-visible={navOpen}>
          {isMobile ? (
            <PopoutHeader
              closeNav={closeNav}
              navOpen={navOpen}
              shopHome={shopHome}
            />
          ) : null}
          {children}
          {isMobile ? (
            <div className="social-user flex-column justify-content-end">
              {extraLinks}
              <UserMenu userLinks={userLinks} />
              <SocialIcons socialMediaLinks={socialLinks} size="small" />
            </div>
          ) : null}
        </nav>
      );
    }
    case 'popup': {
      return (
        <div
          data-visible={navOpen}
          className={classNames('nav-sublink-popup', {
            ['disable-scroll']: navOpen
          })}>
          <PopoutHeader
            closeNav={closeNav}
            navOpen={navOpen}
            shopHome={shopHome}
          />
          {children}
          <div className="social-user flex-column justify-content-end">
            {extraLinks}
            <UserMenu userLinks={userLinks} />
            <SocialIcons socialMediaLinks={socialLinks} size="small" />
          </div>
        </div>
      );
    }
  }
};
