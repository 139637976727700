'use client';

import { usePathname, useRouter } from 'next/navigation';
import { useEffect, useState } from 'react';
import { Params } from 'next/dist/shared/lib/router/utils/route-matcher';

import {
  Consolidate_Product,
  DatalayerAnalytics,
  dutchieFriendlyName,
  DutchiePlus_ProductFragment,
  Monetate,
  slugify
} from 'services';
import { PageState } from '../components/PageState';
import { BreadCrumbs } from '../components/Breadcrumbs';
import { InformationBanners } from '../components/InformationBanners';
import { ProductDescription } from '../components/ProductDescription';
import { useSiteWideContext } from '../hooks/siteWideContext';
import { useDispensaryPathContext } from '../hooks/dispensaryPathContextProvider';
import { breadcrumbsJsonLD, pdpJsonLD } from '../utils/jsonLD';
import { trackMonetateImpressions } from '../utils/trackMonetateImpressions';
import { productMap } from '../utils/storefrontMap';

export interface ProductPageProps {
  searchParams: Params;
  product?: DutchiePlus_ProductFragment | null;
  coaUrl?: string;
}

const PAGE_TYPE = 'shop/pdp';

export const ProductPage = ({
  product,
  searchParams,
  coaUrl
}: ProductPageProps) => {
  const { push } = useRouter();
  const { currentPathDispensary, defaultMenuType, infoBanners, offerList } =
    useDispensaryPathContext();
  const { userMenuType } = useSiteWideContext();
  const pathname = usePathname();
  const [productData, setProductData] = useState<Consolidate_Product | null>(
    null
  );

  useEffect(() => {
    // For useMonetateExperience
    if (productData?.category) {
      const query = {
        categoryKey: dutchieFriendlyName(productData.category)
      };
      const queryObj = { ...searchParams, ...query };
      const newQuery = new URLSearchParams(queryObj).toString();
      newQuery ? push(pathname + '?' + newQuery) : null;
    }
  }, []);

  useEffect(() => {
    if (currentPathDispensary) {
      DatalayerAnalytics.pushPageView({
        page_type: PAGE_TYPE,
        consolidateDispensary: currentPathDispensary
      });
    }
  }, [pathname, currentPathDispensary]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!productData) {
      setLoading(true);

      if (product) {
        setProductData(productMap(product, offerList, defaultMenuType));
      }
    }
    setLoading(false);
  }, [userMenuType]);

  useEffect(() => {
    if (productData) {
      DatalayerAnalytics.pushProductView({
        product: productData,
        consolidateDispensary: currentPathDispensary
      });
      Monetate.addPDP({
        productId: productData.id,
        sku:
          productData.variants.length == 1 && productData.variants[0]
            ? productData.variants[0].id
            : undefined
        // sku is variant id, but there isnt one here.
      });
      trackMonetateImpressions(
        productData.category,
        'userProductViewPreferences'
      );
    }
  }, [productData]);

  if (!loading && !productData) {
    if (currentPathDispensary && currentPathDispensary.shopLink) {
      DatalayerAnalytics.pushErrorEvent({
        category: 'expected',
        location: 'productPage',
        description: 'Product Not Found',
        consolidateDispensary: currentPathDispensary
      });
      return (
        <PageState
          header="Product not available."
          showPopularProducts={true}
          link={{
            url: currentPathDispensary.shopLink,
            text: 'Check out our other products!'
          }}
        />
      );
    } else {
      DatalayerAnalytics.pushErrorEvent({
        category: 'expected',
        location: 'productPage',
        description: 'Dispensary Not Found',
        consolidateDispensary: currentPathDispensary
      });
    }
  }

  const breadcrumbs = () => {
    const bc: {
      id: number;
      urlFragment: string;
      breadcrumbName: string;
      params?: [string, string][];
    }[] = [];
    if (productData?.category) {
      bc.push(
        {
          id: 0,
          urlFragment: currentPathDispensary.shopLink,
          breadcrumbName: 'Home'
        },
        {
          id: 1,
          urlFragment: `${currentPathDispensary.shopLink}#categories`,
          breadcrumbName: 'Categories'
        },
        {
          id: 2,
          urlFragment: `${currentPathDispensary.shopLink}/categories/${slugify(
            productData.category
          )}`,
          breadcrumbName: dutchieFriendlyName(productData.category)
        }
      );

      if (productData.subcategory && productData.subcategory !== 'DEFAULT') {
        bc.push({
          id: 3,
          urlFragment: `${currentPathDispensary.shopLink}/categories/${slugify(
            productData.category
          )}?sub=${encodeURI(productData.subcategory)}`,
          breadcrumbName: dutchieFriendlyName(
            productData.subcategory.toLowerCase()
          )
        });
      }
    }
    return bc;
  };

  return (
    <>
      {process.env.IS_KIOSK !== 'true' && productData ? (
        <section>
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
              __html: JSON.stringify(pdpJsonLD(productData))
            }}
          />
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
              __html: JSON.stringify(
                breadcrumbsJsonLD(productData.name, breadcrumbs(), pathname)
              )
            }}
          />
        </section>
      ) : null}
      <BreadCrumbs
        props={
          productData
            ? {
                breadcrumb: breadcrumbs(),
                isLoading: false
              }
            : {
                isLoading: true
              }
        }
      />
      <InformationBanners
        props={{
          banners: (infoBanners?.banners || []).filter(
            (b) => b.severity === 'ERROR'
          ),
          isLoading: !infoBanners,
          setBanners: infoBanners ? infoBanners.set : () => undefined,
          sticky: infoBanners?.sticky
        }}
      />
      {productData ? (
        <ProductDescription
          props={{
            isLoading: false,
            product: productData,
            productSpecials: productData.offers
              ? productData.offers.map((offer, index) => {
                  return {
                    ...offer,
                    containingPageType: PAGE_TYPE,
                    containingCarouselName: 'Special Offers',
                    index
                  };
                })
              : [],
            coaUrl
          }}
        />
      ) : loading ? (
        <ProductDescription props={{ isLoading: true }} />
      ) : null}
    </>
  );
};
