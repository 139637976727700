import { LoadingSkeleton } from '../../Loader/Skeleton';
import { PackageComponentWrapper } from '../../PackageComponentWrapper';

export const Loader = (): JSX.Element | null => {
  const specifications = new Array(2).fill(undefined);
  return (
    <PackageComponentWrapper additionalClass="bg-ecom" minWidth={false}>
      <div className="product-details specifications-loader container d-flex flex-column padding-0">
        {specifications.map((_, i) => (
          <div
            key={i}
            className="description-section d-flex flex-column padding-top-15">
            <h4 className="section-header tbody18-bold padding-bottom-15 margin-bottom-0">
              <LoadingSkeleton width="100px" />
            </h4>
            <div className="d-flex justify-content-between align-items-center padding-bottom-15 item margin-bottom-0">
              <div className="d-flex align-items-center tbody12-bold item-name-container">
                <span className="margin-right-25 item-name">
                  <LoadingSkeleton width="100%" />
                </span>
                <span className="tbody12 text-left item-bars"></span>
                <p className="tbody12 text-right item-value">
                  <LoadingSkeleton width={i === 1 ? '20%' : '50%'} />
                </p>
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center padding-bottom-15 item">
              <div className="d-flex align-items-center tbody12-bold item-name-container margin-bottom-0">
                <span className="margin-right-25 item-name">
                  <LoadingSkeleton width="100%" />
                </span>
                <span className="tbody12 text-left item-bars"></span>
                <p className="tbody12 text-right item-value">
                  <LoadingSkeleton width={i === 1 ? '20%' : '50%'} />
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </PackageComponentWrapper>
  );
};
