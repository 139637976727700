import { useEffect, useState } from 'react';
import { FormikProps } from 'formik';
import { InputText } from 'primereact/inputtext';
import { InputMask } from 'primereact/inputmask';
import { classNames } from 'primereact/utils';
import { FloatLabel } from 'primereact/floatlabel';

import {
  DefaultFormik,
  getFormErrorMessage,
  isFormFieldValid
} from '../../../../utils/forms';

import '../form.scss';

export type TextInputProps = {
  autoComplete?: string; //https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete#values
  title: string;
  type: 'name' | 'tel' | 'email' | 'zip';
  field: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formik: FormikProps<any>;
  required?: boolean;
  disabled?: boolean;
  customId?: string;
  disclaimer?: string;
};

export const TextInput = ({
  autoComplete,
  customId = undefined,
  disabled = false,
  disclaimer,
  field,
  formik,
  required = false,
  title,
  type
}: TextInputProps) => {
  const [showPhoneMask, setPhoneMask] = useState(false);
  if (!formik) {
    formik = DefaultFormik();
  }

  useEffect(() => {
    if (type === 'tel' && formik.values[field].length) setPhoneMask(true);
  }, [formik.values[field]]);

  const getInput = (type: string) => {
    if (type === 'name' || type === 'email' || type === 'zip') {
      const keyfilters = () => {
        if (type === 'zip') return 'num';
        if (type === 'name') return /^[a-z\d\-_\s]+$/i;
      };
      return (
        <div
          data-test="text-input-container"
          className={classNames('text-input-curaleaf margin-bottom-15', {
            'form-error':
              isFormFieldValid(field, formik) && formik.submitCount > 0
          })}>
          <FloatLabel>
            <InputText
              disabled={disabled}
              name={field}
              id={customId ?? type}
              data-test="text-input"
              keyfilter={keyfilters()}
              value={formik.values[field]}
              onChange={formik.handleChange}
              autoComplete={autoComplete}
            />
            <label
              data-test="text-input-label"
              data-has-value={!!formik.values[field]}
              htmlFor={customId ?? type}>
              {title}
              {required ? <sup>*</sup> : null}
            </label>
          </FloatLabel>
          {formik.submitCount > 0 ? getFormErrorMessage(field, formik) : null}
          {disclaimer ? (
            <div className="text-dark-grey margin-bottom-10 margin-left-5">
              <small>Enter your full name as it appears on your ID</small>
            </div>
          ) : null}
        </div>
      );
    } else if (type === 'tel') {
      return (
        <div
          data-test="text-input-container"
          className={classNames('text-input-curaleaf margin-bottom-15', {
            'form-error':
              isFormFieldValid(field, formik) && formik.submitCount > 0
          })}>
          <FloatLabel>
            <InputMask
              name={field}
              id={customId ?? type}
              type={type}
              data-test="text-input"
              mask={showPhoneMask ? '(999)999-9999' : ''}
              slotChar={showPhoneMask ? '(xxx)xxx-xxxx' : ''}
              value={formik.values[field]}
              onClick={() => setPhoneMask(true)}
              onFocus={() => setPhoneMask(true)}
              onChange={formik.handleChange}
              autoComplete={autoComplete}
            />
            <label
              data-test="text-input-label"
              data-has-value={!!formik.values[field]}
              htmlFor={customId ?? type}>
              {title}
              {required ? <sup>*</sup> : null}
            </label>
          </FloatLabel>
          {formik.submitCount > 0 ? getFormErrorMessage(field, formik) : null}
        </div>
      );
    } else {
      return <div>Missing component-{type}</div>;
    }
  };

  return getInput(type);
};
