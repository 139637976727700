import { capitalize } from 'services';
import { OrderSummaryPricing, OrderSummaryProps } from '.';
import { formatPrice } from '../../utils/ecommerce';

export const OrderSummary = ({
  pricing,
  type
}: OrderSummaryProps): JSX.Element | null => {
  if (!pricing) return null;
  const summary: OrderSummaryPricing[] = ['subtotal', 'taxes', 'fees', 'total'];
  if (pricing.discounts && pricing.discounts > 0) {
    summary.splice(1, 0, 'discounts');
  }

  return (
    <>
      {summary.map((ps) => {
        const match = pricing[ps] || 0;
        const price = type === 'order' ? match.toFixed(2) : formatPrice(match);
        const isDiscount = ps === 'discounts';
        const bold = ps === 'total' ? 'font-bold' : '';

        if (ps === 'fees' && pricing.fees === 0) return null;
        return (
          <div key={ps} className="margin-bottom-10">
            <div className={bold ? 'hairline' : ''} />
            <div className="d-flex justify-content-between text-color-dark-grey margin-bottom-10">
              <span className={bold}>{capitalize(ps)}</span>
              {isDiscount ? (
                <span className={`${bold}text-color-success`}>-${price}</span>
              ) : (
                <span className={bold}>${price}</span>
              )}
            </div>
          </div>
        );
      })}
    </>
  );
};
