import { PackageComponentWrapper } from '../../PackageComponentWrapper';
import { Disclaimer } from '../disclaimer';
import { FooterLower } from './footerLower';
import { FooterUpper } from './footerUpper';
import { ErrorBoundary } from '../../../utils/nullErrorBoundary';
import { WebFooterProps } from '../index';

import './footer-navigation.scss';

export const WebFooterNavigation = ({
  copyrightInfo,
  disclaimer,
  footerLinks,
  legalLinks,
  socialMediaLinks,
  states
}: WebFooterProps): JSX.Element | null => {
  if (!footerLinks) return null;

  return (
    <ErrorBoundary component="footer">
      <PackageComponentWrapper maxWidth={false}>
        <div className="footer-container-curaleaf" data-test="footer-container">
          {footerLinks ? (
            <FooterUpper footerLinks={footerLinks} states={states} />
          ) : null}
          {disclaimer && disclaimer.length ? (
            <Disclaimer disclaimer={disclaimer} />
          ) : null}
          <FooterLower
            copyrightInfo={copyrightInfo}
            legalLinks={legalLinks}
            socialMediaLinks={socialMediaLinks}
          />
        </div>
      </PackageComponentWrapper>
    </ErrorBoundary>
  );
};
