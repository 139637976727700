import { PromoCarousel as Default } from './default';
import { ErrorBoundary } from '../../utils/nullErrorBoundary';
import { PromoBannerProps } from './PromoBanner';

export type PromoCarouselProps = {
  anchor?: string;
  auto_slide_in_seconds?: number;
  banners: PromoBannerProps[];
  variant: 'large' | 'small';
};

export const PromoCarousel = (
  props: PromoCarouselProps
): JSX.Element | null => {
  if (!props.banners || props.banners.length === 0) return null;
  return (
    <ErrorBoundary component="promo-carousel">
      <Default {...props} />
    </ErrorBoundary>
  );
};
