'use client';

import { usePathname } from 'next/navigation';
import Link from 'next/link';

import { ecommerceSanitizeQuery } from '../../utils/url';
import { useSiteWideContext } from '../../hooks/siteWideContext';
import { BreadcrumbProps } from './index';
import { PackageComponentWrapper } from '../PackageComponentWrapper';

import './breadcrumbs.scss';

import CuraleafArrow from '../../assets/svgs/arrows/breadcrumb-arrow.svg';

export const BreadCrumbs = ({
  breadcrumb,
  currentPage
}: BreadcrumbProps): JSX.Element | null => {
  const asPath = usePathname();
  const { width } = useSiteWideContext();
  const backgroundColor =
    asPath.includes('shop') || asPath.includes('account')
      ? 'bg-ecom'
      : 'bg-white';

  const link = {
    container: 'link-container d-flex align-items-center',
    text: `breadcrumb-name margin-0 ${
      width && width <= 767 ? 'tbody12' : 'tbody14'
    }`
  };

  return (
    <PackageComponentWrapper additionalClass={backgroundColor}>
      <nav
        aria-label="breadcrumb"
        role="navigation"
        data-test="breadcrumb-container"
        className="Breadcrumbs">
        <ul className="d-flex align-items-center margin-0">
          {breadcrumb.map((element, i) => {
            const textColor =
              !currentPage && i === breadcrumb.length - 1 ? 'primary' : 'grey';
            return element.urlFragment && element.urlFragment.length ? (
              <li
                className={link.container}
                data-test="link-container"
                key={element.id}>
                <Link
                  href={ecommerceSanitizeQuery(element.urlFragment)}
                  className={`${link.text} text-${textColor} pointer text-decoration-none`}
                  data-test="breadcrumb-name">
                  {element.breadcrumbName}
                </Link>
                <CuraleafArrow data-test="curaleaf-arrow-icon" />
              </li>
            ) : (
              <li
                key={element.id}
                className={link.container}
                data-test="link-container">
                <p
                  className={`${link.text} text-${textColor} margin-0`}
                  data-test="breadcrumb-name">
                  {element.breadcrumbName}
                </p>
                <CuraleafArrow data-test="curaleaf-arrow-icon" />
              </li>
            );
          })}
          {currentPage ? (
            <li className={link.container}>
              <p className={`${link.text} text-primary margin-0`}>
                {currentPage}
              </p>
            </li>
          ) : null}
        </ul>
      </nav>
    </PackageComponentWrapper>
  );
};
