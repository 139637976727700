import { useState } from 'react';
import { Dialog } from 'primereact/dialog';

import { PackageComponentWrapper } from '../PackageComponentWrapper';

import './feedback-tab.scss';

export type FeedbackTabProps = {
  url?: string;
};

export const FeedbackTab = ({ url }: FeedbackTabProps): JSX.Element | null => {
  const [show, setShow] = useState(false);

  if (!url) return null;

  return (
    <PackageComponentWrapper>
      <div
        tabIndex={0}
        aria-expanded={show}
        role="button"
        className="feedback-tab bg-tertiary tbody14 text-center padding-5 pointer"
        onClick={() => setShow(true)}
        onKeyDown={(event) => event.key === 'Enter' && setShow(true)}>
        Feedback
      </div>
      <Dialog
        blockScroll={true}
        className="feedback-dialog all-brands"
        closeOnEscape
        dismissableMask={true}
        draggable={false}
        onHide={() => setShow(false)}
        resizable={false}
        visible={show}>
        <iframe className="feedback-iframe" src={url} />
      </Dialog>
    </PackageComponentWrapper>
  );
};
