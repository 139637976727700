import { CarouselType, StorefrontCarouselBanner } from '../index';
import { CategoryTile, CategoryTileProps } from '../../CategoryTile';
import { ProductTile, ProductTileProps } from '../../ProductTile';
import { SpecialTile, SpecialTileProps } from '../../SpecialTile';
import { useSiteWideContext } from '../../../hooks/siteWideContext';
import { DatalayerAnalytics } from 'services';

export const CarouselTile = (
  type: CarouselType,
  item: StorefrontCarouselBanner,
  isLoading = false,
  index: number
) => {
  const { selectedDispensary } = useSiteWideContext();

  switch (type) {
    case 'categories': {
      return (
        <CategoryTile
          props={
            isLoading
              ? {
                  isLoading: true
                }
              : {
                  isLoading: false,
                  link: (item as CategoryTileProps).link,
                  title: (item as CategoryTileProps).title
                }
          }
        />
      );
    }
    case 'products': {
      return (
        <ProductTile
          props={{
            ...(item as ProductTileProps),
            isLoading,
            handleAnalytics: () => {
              DatalayerAnalytics.pushCarouselClick({
                page_type: 'shop/home',
                dispensary: selectedDispensary,
                carousel_name: item.containingCarouselName || '',
                product: (item as ProductTileProps).product,
                index
              });
            }
          }}
        />
      );
    }
    case 'specials-drawer':
    case 'specials': {
      return (
        <SpecialTile
          props={{
            ...(item as SpecialTileProps),
            isLoading,
            index
          }}
        />
      );
    }
    default: {
      return null;
    }
  }
};
