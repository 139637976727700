import { MouseEvent, useState } from 'react';
import Image from 'next/image';
import { useParams, usePathname } from 'next/navigation';

import { useSiteWideContext } from '../../hooks/siteWideContext';
import {
  DatalayerAnalytics,
  Personalization,
  dutchieFriendlyName
} from 'services';
import { ProductTileProps } from './index';
import { PriceSection } from '../PriceSection';
import { PExperienceProductsType } from '../Personalization';

import { ecommerceSanitizeQuery } from '../../utils/url';
import { imgPath } from '../../utils/imgPath';
import { trackMonetateImpressions } from '../../utils/trackMonetateImpressions';
import { STRAIN_TYPES_SIGNALS } from '../../utils/constants';
import { getDealSignal } from '../../utils/ecommerce';

import DefaultProductImage from '../../assets/images/product-placeholder.png';
import PlusIcon from '../../assets/svgs/faq/faq-open-mobile.svg';
import { useDispensaryPathContext } from '../../hooks/dispensaryPathContextProvider';

export const ProductTile = ({
  forCarousel = false,
  handleAnalytics,
  hideButton = false,
  product
}: ProductTileProps) => {
  // using sitewide context instead of currentpath because it's on home page
  const { currentPathDispensary } = useDispensaryPathContext();
  const { addItemToCart, selectedDispensary } = useSiteWideContext();
  const [loading, setLoading] = useState(false);
  const pathname = usePathname();
  const params = useParams();

  const shopLink =
    currentPathDispensary &&
    typeof currentPathDispensary.shopLink === 'string' &&
    typeof currentPathDispensary.shopLink.length
      ? currentPathDispensary.shopLink
      : selectedDispensary?.shopLink;

  const handleAddToCart = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setLoading(true);

    const extendedProduct = product as PExperienceProductsType;

    if (extendedProduct.recToken) {
      Personalization.sendRecEvent(
        extendedProduct.recToken,
        'monetate:record:RecClicks'
      );
    }

    if (
      product.variants[0] &&
      (currentPathDispensary || selectedDispensary) &&
      addItemToCart
    ) {
      addItemToCart(
        {
          product,
          quantity: 1,
          variant: product.variants[0],
          analyticsContext: DatalayerAnalytics.generateAnalyticsContext(
            pathname,
            shopLink || '',
            '',
            '',
            { key: `${params.categoryKey}` }
          ),
          baseCartUrl: window.location.origin + shopLink
        },
        () => {
          trackMonetateImpressions(
            product.category,
            'userAddToCartPreferences'
          );
          setLoading(false);
        }
      );
    }
  };

  const image = product.images[0]?.url || '';
  const variantsLength = product.variants.length;
  const hasStrainTypeSignal = product.strainType
    ? STRAIN_TYPES_SIGNALS.includes(product.strainType)
    : false;
  const dealSignal = getDealSignal(product.offers);

  return (
    <div
      className="product-tile-container margin-bottom-10 pointer bg-white"
      data-test="product-tile">
      {process.env.NEXT_PUBLIC_HIDE_DEAL_SIGNAL !== 'true' &&
      dealSignal.length ? (
        <div className="strain text-color-white bg-hybrid" data-test="strain">
          <span className="tbody12">{dealSignal}</span>
        </div>
      ) : null}
      {loading ? (
        <div className="spinner-icon">
          <div className="loading-spinner" />
        </div>
      ) : (
        ((!hideButton && variantsLength > 1) ||
        selectedDispensary?.pauseEcommerce
          ? null
          : !hideButton) && (
          <button
            id="cart-icon"
            data-test="cart"
            aria-label="add to cart"
            className="cart-icon bg-white pointer"
            onClick={handleAddToCart}>
            <PlusIcon />
          </button>
        )
      )}
      <a
        tabIndex={0}
        onClick={() => {
          const extendedProduct = product as PExperienceProductsType;
          if (extendedProduct.recToken) {
            Personalization.sendRecEvent(
              extendedProduct.recToken,
              'monetate:record:RecClicks'
            );
          }
          if (handleAnalytics) {
            handleAnalytics(product.name || '');
          }
        }}
        href={ecommerceSanitizeQuery(`${shopLink}/products/${product.id}`)}>
        <div className="tile">
          <div className="image">
            <Image
              data-test="default-product-img"
              className="product-img"
              src={image ? imgPath(image) : DefaultProductImage}
              alt={product.name}
              fill={true}
              sizes="100%"
              style={{
                objectFit: 'contain'
              }}
            />
          </div>
          <div className="product-details">
            <div className="d-flex justify-content-between align-items-center">
              {product.variants[0] ? (
                <PriceSection
                  props={
                    variantsLength > 1
                      ? {
                          variants: product.variants,
                          showRange: true
                        }
                      : {
                          variant: product.variants[0],
                          showRange: false,
                          options: {
                            biggerFont: true,
                            showOptions: true
                          }
                        }
                  }
                />
              ) : null}
              {variantsLength > 1 ? (
                <span className="tbody12 text-color-grey margin-left-5 margin-top-5">{`+${
                  variantsLength - 1
                } Options`}</span>
              ) : null}
            </div>
            <h2 className="product-name text-color-primary hover-text-tertiary margin-0 tbody16-bold">
              {product.name}
            </h2>
            <div
              className="moodi-day-rating-overlay"
              data-productid={product.id}></div>
            {!forCarousel ? (
              <div className="product-additional-info">
                {product.brand ? (
                  <div data-test="brand">
                    <span className="text-color-grey tbody12">
                      by {product.brand?.name}
                    </span>
                  </div>
                ) : null}
                <div data-test="thc">
                  <span className="text-color-grey tbody12">
                    {product.cardDescription}
                    {product.cardDescription && hasStrainTypeSignal
                      ? ' - '
                      : null}
                    {product.strainType && hasStrainTypeSignal
                      ? `${dutchieFriendlyName(product.strainType)}`
                      : ''}
                  </span>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </a>
    </div>
  );
};
