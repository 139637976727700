import { LoadingProps } from '.';
import { useSiteWideContext } from '../../../hooks/siteWideContext';
import { LoadingSkeleton } from '../../Loader/Skeleton';

export const Loader = ({ pageTitle }: LoadingProps): JSX.Element | null => {
  const { width } = useSiteWideContext();
  if (width && width < 960) {
    return (
      <>
        <h1 className="page-title text-primary bg-ecom container-lr margin-0 mh4 text-center">
          {pageTitle}
        </h1>
        <div className="product-sort-filter psf-loader container-lr">
          <div className="button-group d-flex">
            <a
              role="button"
              data-test="filter-button"
              className="d-flex justify-content-center align-items-center bg-white tbody14-body sm-border-radius">
              <LoadingSkeleton width="25px" />
              <span className="margin-left-10 text-color-dark-grey tbody14-bold">
                <LoadingSkeleton width="88px" />
              </span>
            </a>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <div className="d-flex justify-content-between align-items-end">
        <h1 className="page-title text-primary bg-ecom container-lr margin-0 mh3">
          {pageTitle}
        </h1>
        <div className="d-flex product-sort-filter-desktop psfd-loader">
          <div className="product-view">
            <LoadingSkeleton width="88px" height="40px" />
          </div>
          <div className="sort-by">
            <LoadingSkeleton width="115px" height="40px" />
          </div>
        </div>
      </div>
    );
  }
};
