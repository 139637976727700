import Image from 'next/image';
import Link from 'next/link';

import { ImageType } from '../../models/types';
import { PackageComponentWrapper } from '../PackageComponentWrapper';

import InstagramIcon from '../../assets/svgs/social-icons/instagram-sm.svg';

import './instagram-grid.scss';

export type InstagramGridProps = {
  images: {
    image: ImageType;
    link: string;
  }[];
};

export const InstagramGrid = ({ images }: InstagramGridProps) => {
  return (
    <PackageComponentWrapper additionalClass="bg-gray">
      <div className="instagram-grid">
        {images.map((ig) => {
          if (ig.link.length) {
            return (
              <Link
                href={ig.link}
                className="insta-image"
                target="_blank"
                key={ig.link}>
                <Image
                  src={ig.image.url}
                  height={ig.image.height}
                  width={ig.image.width}
                  alt={'instagram image'}
                  style={{
                    height: '100%',
                    objectFit: 'cover',
                    objectPosition: 'center',
                    width: '100%'
                  }}
                />
                <div className="link">
                  <InstagramIcon />
                </div>
              </Link>
            );
          } else {
            return (
              <div key={ig.image.url}>
                <Image
                  src={ig.image.url}
                  height={ig.image.height}
                  width={ig.image.width}
                  alt={'instagram image'}
                  style={{
                    height: '100%',
                    objectFit: 'cover',
                    objectPosition: 'center',
                    width: '100%'
                  }}
                />
              </div>
            );
          }
        })}
      </div>
    </PackageComponentWrapper>
  );
};
