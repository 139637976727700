import Image from 'next/image';

import { useSiteWideContext } from '../../../hooks/siteWideContext';
import { ImageType } from '../../../models/types';
import { imgPath } from '../../../utils/imgPath';

import { CTAButton, CTAButtonProps } from '../../CTAButton';
import { Markdown } from '../../Markdown';

import './promo-banner.scss';

export type PromoBannerProps = {
  backgroundHexColor?: string;
  content: string;
  ctaButton?: CTAButtonProps | null;
  id: number;
  image: ImageType;
  layout: 'left' | 'right';
  mobileImage?: ImageType | null;
};

export const PromoBanner = ({
  backgroundHexColor = '#f5f1eb',
  content,
  ctaButton,
  image,
  layout,
  mobileImage
}: PromoBannerProps): JSX.Element | null => {
  const { isMobile } = useSiteWideContext();
  const responsiveImage = mobileImage && isMobile ? mobileImage : image;

  return (
    <section
      className="promo-banner-curaleaf"
      data-test="promo-banners-container">
      <div
        className={`column ${
          layout === 'right' ? 'lg-row' : 'reverse'
        } d-flex align-items-center justify-content-center`}>
        {content && (
          <div
            className="col-md-5 col-lg-4 text-block-section"
            style={{ backgroundColor: backgroundHexColor }}>
            <Markdown content={content} />
            {ctaButton && <CTAButton {...ctaButton} />}
          </div>
        )}
        <section className="col-12 col-md-7 col-lg-8 promo-image-banner-wrapper">
          <div className="promo-image-banner">
            {responsiveImage ? (
              <Image
                src={imgPath(responsiveImage.url)}
                style={{
                  height: 'auto',
                  width: '100%',
                  objectFit: 'contain'
                }}
                loading="eager"
                data-test="banner-image"
                height={responsiveImage.height}
                width={responsiveImage.width}
                alt={responsiveImage.alternativeText || ''}
              />
            ) : null}
          </div>
        </section>
      </div>
    </section>
  );
};
