import { DatalayerAnalytics, capitalize } from 'services';
import { useSiteWideContext } from '../../../hooks/siteWideContext';
import { logout } from '../../../utils/account';

export type UserLink = { title: string; link: string };

type UserMenuProps = {
  userLinks: UserLink[];
};

export const UserMenu = ({ userLinks }: UserMenuProps): JSX.Element | null => {
  const { clearAnonCart, clearUser, isLoggedIn, user } = useSiteWideContext();
  if (!isLoggedIn || !user?.firstName) return null;

  const handleClick = () => {
    logout(clearUser, clearAnonCart);
    DatalayerAnalytics.pushAccountEvent({
      action: 'logout'
    });
  };

  return (
    <div className="user-navigation">
      <div className="user-container">
        <p className="tbody16-bold text-color-primary">
          {capitalize(`welcome back, ${user.firstName}`)}
        </p>
        <ul className="user-links d-flex flex-column">
          {userLinks.map((item) => (
            <li key={item.title}>
              <a
                tabIndex={0}
                aria-label={item.title}
                className="tbody16 text-color-primary"
                href={item.link}>
                {item.title}
              </a>
            </li>
          ))}
        </ul>
        <a
          tabIndex={0}
          className="tbody16 text-color-grey underline pointer"
          onClick={handleClick}
          onKeyDown={(event) => {
            if (event.key === 'Enter') handleClick();
          }}>
          Sign Out
        </a>
      </div>
    </div>
  );
};
