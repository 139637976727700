import { useEffect, useState } from 'react';
import { Divider } from 'primereact/divider';

import '../form.scss';

export type ChecklistProps = {
  value: string;
};

export const PasswordChecklist = ({ value }: ChecklistProps) => {
  const [passwordCheck, setPasswordCheck] = useState({
    length: false,
    lower: false,
    upper: false,
    number: false,
    symbol: false
  });

  const passwordChecks = (pw: string) => {
    const lower = new RegExp('(?=.*[a-z])');
    const upper = new RegExp('(?=.*[A-Z])');
    const number = new RegExp('(?=.*\\d)');
    const symbol = new RegExp('(?=.*[-+_!@#$%^&*., ?])');
    setPasswordCheck({
      length: pw.length >= 8,
      lower: lower.test(pw),
      upper: upper.test(pw),
      number: number.test(pw),
      symbol: symbol.test(pw)
    });
  };

  useEffect(() => {
    passwordChecks(value);
  }, [value]);

  const passwordCriteria = [
    { display: 'Minimum 8 characters', key: 'length' },
    { display: 'At least one uppercase', key: 'upper' },
    { display: 'At least one lowercase', key: 'lower' },
    { display: 'At least one number', key: 'number' },
    { display: 'At least one symbol', key: 'symbol' }
  ];

  return (
    <div className="password-checks">
      <p className="margin-bottom-5">You password must have:</p>
      {passwordCriteria.map((pw) => (
        <div key={pw.key} className="d-flex align-items-center margin-bottom-5">
          <span className="check margin-right-10 tbody12">
            {(
              passwordCheck as unknown as {
                [key: string]: boolean;
              }
            )[pw.key] ? (
              <i className="pi pi-check text-success" />
            ) : (
              <i className="pi pi-times text-error" />
            )}
          </span>
          <small>{pw.display}</small>
        </div>
      ))}
      <Divider />
    </div>
  );
};
