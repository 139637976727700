import { Curaql_LoyaltyTierPrestige } from 'services';
import { PackageComponentWrapper } from '../../../PackageComponentWrapper';
import { ErrorBoundary } from '../../../../utils/nullErrorBoundary';

import './status-bar.scss';

type StatusBarProps = {
  nextTierPrestige: Curaql_LoyaltyTierPrestige;
  tierProgress: number;
};

export const StatusBar = ({
  nextTierPrestige,
  tierProgress
}: StatusBarProps) => {
  return (
    <PackageComponentWrapper>
      <ErrorBoundary component="loyalty-status-bar">
        <div className="loyalty-status-bar margin-bottom-30">
          <div className="status-bar-background bg-light-grey">
            <div
              className="status-bar bg-primary"
              style={{ width: `${tierProgress}%` }}></div>
          </div>
          <div className="d-flex justify-content-between sub-header">
            <span className="text-dark-grey subtitle">Status</span>
            <span className="text-grey subtitle">{nextTierPrestige}</span>
          </div>
        </div>
      </ErrorBoundary>
    </PackageComponentWrapper>
  );
};
