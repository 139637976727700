'use client';

import { useEffect } from 'react';

import { DatalayerAnalytics, Monetate } from 'services';
import { PackageComponentWrapper } from '../../components/PackageComponentWrapper';
import { CTAButton } from '../../components/CTAButton';
import { useSiteWideContext } from '../../hooks/siteWideContext';

import './account-preferences.scss';

export const UnauthorizedPage = () => {
  const { setAccountDrawerMode, setAccountDrawerVisible } =
    useSiteWideContext();

  useEffect(() => {
    DatalayerAnalytics.pushPageView({
      page_type: 'account'
    });
    Monetate.setPageType('account');
  }, []);

  return (
    <PackageComponentWrapper additionalClass="account-preferences-wrapper bg-ecom">
      <div className="account-preferences text-center">
        <h2 className="text-primary margin-bottom-15 mh5">
          Whoops, you must be logged in!
        </h2>
        <p className="text-grey">You must be logged in to view this page.</p>
        <CTAButton
          text="Click here to login"
          variant="secondary"
          callBackFunc={() => {
            setAccountDrawerMode('login');
            setAccountDrawerVisible(true);
          }}
        />
      </div>
    </PackageComponentWrapper>
  );
};
