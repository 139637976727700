import { PackageComponentWrapper } from '../PackageComponentWrapper';
import { TextBlock } from '../TextBlock';

export const Disclaimer = ({
  disclaimer
}: {
  disclaimer: string;
}): JSX.Element | null => {
  return (
    <PackageComponentWrapper additionalClass="bg-dark-teal" maxWidth={true}>
      <div className="footer-disclaimer" data-test="disclaimer">
        <TextBlock content={[disclaimer]} align="center" />
      </div>
    </PackageComponentWrapper>
  );
};
