import { Dialog } from 'primereact/dialog';

import { CTAButton } from '../../CTAButton';
import { PackageComponentWrapper } from '../../PackageComponentWrapper';
import { Markdown } from '../../Markdown';
import { InformationBannerType } from 'services';
import { ErrorBoundary } from '../../../utils/nullErrorBoundary';

import './todays-offers.scss';

export type TodaysOffersProps = {
  content: InformationBannerType;
  setBulletinModal: (open: boolean) => void;
  show: boolean;
};

export const TodaysOffers = ({
  content,
  setBulletinModal,
  show
}: TodaysOffersProps): JSX.Element | null => {
  if (!content || !content.dialog || !show) return null;

  return (
    <ErrorBoundary component="todays-offer">
      <Dialog
        blockScroll={true}
        className="bulletin-board-modal"
        closeOnEscape
        dismissableMask={true}
        draggable={false}
        footer={
          <CTAButton
            text="Close"
            variant="primary"
            callBackFunc={() => setBulletinModal(false)}
          />
        }
        header={
          <h3 className="text-color-dark-grey h4-mobile">{content.title}</h3>
        }
        onHide={() => setBulletinModal(false)}
        resizable={false}
        visible={show}>
        <PackageComponentWrapper>
          <div className="bulletin-board-content">
            <Markdown
              additionalClass="todays-offers-markdown"
              content={content.dialog}
            />
          </div>
        </PackageComponentWrapper>
      </Dialog>
    </ErrorBoundary>
  );
};
