import { PackageComponentWrapper } from '../../../PackageComponentWrapper';
import { Markdown } from '../../../Markdown';

export type ConfirmationProps = {
  content: string;
  title: string;
};

export const AccountConfirmationForm = ({
  content,
  title
}: ConfirmationProps) => {
  return (
    <PackageComponentWrapper>
      <div>
        <div className="sidebar-header">
          <h2 className="margin-bottom-30 text-primary mh5">{title}</h2>
        </div>
        <div className="sidebar-form-padded">
          <Markdown content={content} />
        </div>
      </div>
    </PackageComponentWrapper>
  );
};
