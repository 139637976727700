import { PriceSectionProps } from '.';

export const PriceSection = ({
  variant,
  options = { biggerFont: false, showOldPrice: true, showOptions: false }
}: PriceSectionProps) => {
  const {
    biggerFont = false,
    showOldPrice = true,
    showOptions = false
  } = options;
  const fontSize = biggerFont ? 'tbody16-bold' : 'tbody12';

  const fixedPrice = (price: number) => {
    if (JSON.stringify(price).includes('.')) {
      return price.toFixed(2);
    }
    return price;
  };

  if (variant.price) {
    if (variant.isSpecial && variant.specialPrice) {
      return (
        <span className="price">
          <span className={`new-price ${fontSize} text-color-error`}>
            ${fixedPrice(variant.specialPrice)}
          </span>
          {showOldPrice ? (
            <span
              className={`old-price strikethrough margin-left-5 ${fontSize} text-color-grey`}>
              ${fixedPrice(variant.price)}
            </span>
          ) : null}
          {showOptions && variant.option !== 'N/A' ? (
            <span className="tbody12 text-color-grey margin-left-5">
              ({variant.option})
            </span>
          ) : null}
        </span>
      );
    }

    return (
      <span className="price">
        <span className={`current-price ${fontSize} text-color-primary`}>
          ${fixedPrice(variant.price)}
        </span>
        {options.showOptions && variant.option !== 'N/A' ? (
          <span className="tbody12 text-color-grey margin-left-5">
            ({variant.option})
          </span>
        ) : null}
      </span>
    );
  }

  return null;
};
