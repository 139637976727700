'use client';

import { useInView } from 'react-intersection-observer';
import { useEffect } from 'react';

import { Personalization, ProductTileType } from 'services';

import { Loader } from './loader';
import { ProductTile as Default } from './default';
import { ErrorBoundary } from '../../utils/nullErrorBoundary';
import { PExperienceProductsType } from '../Personalization';

import './product-tile.scss';

export type MonetateProductTileType = ProductTileType &
  Personalization.MonetateProduct;

export type ProductTileProps = {
  currentPathDispensary?: any;
  checkoutId?: string;
  forCarousel?: boolean;
  hideButton?: boolean;
  isLoading: false;
  product: ProductTileType | PExperienceProductsType;
  handleAnalytics?: (productName: string) => void;
};

export type LoadingProps = {
  forCarousel?: boolean;
  isLoading: true;
};

export const ProductTile = ({
  props
}: {
  props: ProductTileProps | LoadingProps;
}): JSX.Element | null => {
  const [cardRef, inView] = useInView({
    triggerOnce: true,
    threshold: 0.5
  });

  useEffect(() => {
    if (inView && !props.isLoading && props.product) {
      const product = props.product;
      if ((product as MonetateProductTileType).recToken) {
        Personalization.sendRecEvent(
          (product as MonetateProductTileType).recToken,
          'monetate:record:RecImpressions'
        );
      }
    }
  }, [props.isLoading, inView]);

  return props.isLoading ? (
    <Loader forCarousel={props.forCarousel} />
  ) : (
    <ErrorBoundary component="product-tile">
      <span ref={cardRef}>
        <Default {...props} />
      </span>
    </ErrorBoundary>
  );
};
