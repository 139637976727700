import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { InputMask } from 'primereact/inputmask';
import { FloatLabel } from 'primereact/floatlabel';

import { DateInputProps } from './index';
import { getFormErrorMessage, isFormFieldValid } from '../../../../utils/forms';

export const DateInput = ({
  disabled,
  field,
  formik,
  required,
  title
}: DateInputProps) => {
  const [showMask, setShowMask] = useState(false);

  useEffect(() => {
    if (formik.values[field].length) setShowMask(true);
  }, [formik.values[field]]);

  return (
    <div
      className={classNames('text-input-curaleaf margin-bottom-15', {
        'form-error': isFormFieldValid(field, formik) && formik.submitCount > 0
      })}>
      <FloatLabel>
        <InputMask
          name={field}
          id={field}
          disabled={disabled}
          data-test="text-input"
          className="text-input"
          mask={showMask ? '99/99/9999' : ''}
          slotChar={showMask ? 'mm/dd/yyyy' : ''}
          value={formik.values[field]}
          onClick={() => setShowMask(true)}
          onFocus={() => setShowMask(true)}
          onChange={formik.handleChange(field) as () => void}
        />
        <label
          className="form-header-curaleaf d-flex align-items-center"
          data-test="text-input-label"
          htmlFor={field}>
          {title}
          {required ? <sup>*</sup> : null}
        </label>
      </FloatLabel>
      {formik.submitCount > 0 ? getFormErrorMessage(field, formik) : null}
    </div>
  );
};
