import {
  Blog,
  LocalBusiness,
  Organization,
  Product,
  WebPage,
  WebSite,
  WithContext
} from 'schema-dts';
import {
  CS_Article_Full,
  Consolidate_Product,
  dutchieFriendlyName,
  OverviewPageDispensary
} from 'services';
import { BreadcrumbProps } from '../components/Breadcrumbs';

export const homeJsonLD = {
  '@context': 'https://schema.org',
  '@graph': [
    {
      '@type': 'WebPage',
      '@id': `${process.env.WEBSITE_URL}/#webpage`,
      url: process.env.WEBSITE_URL,
      name: 'Curaleaf - Cannabis with Confidence',
      isPartOf: { '@id': `${process.env.WEBSITE_URL}/#website` },
      about: { '@id': `${process.env.WEBSITE_URL}/#organization` },
      primaryImageOfPage: {
        '@id': `${process.env.WEBSITE_URL}/#primaryimage`
      },
      image: { '@id': `${process.env.WEBSITE_URL}/#primaryimage` },
      thumbnailUrl:
        'https://images.contentstack.io/v3/assets/blt1244f6272732d4ea/blt354b4092087c8b5c/default-category-image.png',
      // datePublished: '2015-08-30T17:19:16+00:00',
      // dateModified: '2023-04-03T18:11:47+00:00',
      description:
        'We are a leading vertically integrated medical and wellness cannabis operator in the United States. We craft high-quality cannabis products that are uncomplicated and approachable.',
      inLanguage: 'en-US',
      potentialAction: [
        {
          '@type': 'ReadAction',
          target: [process.env.WEBSITE_URL]
        }
      ]
    },
    {
      '@type': 'ImageObject',
      '@id': `${process.env.WEBSITE_URL}/#primaryimage`,
      inLanguage: 'en-US',
      url: 'https://images.contentstack.io/v3/assets/blt1244f6272732d4ea/blt354b4092087c8b5c/default-category-image.png',
      width: 500,
      height: 457,
      caption: 'Curaleaf Logo'
    },
    {
      '@type': 'WebSite',
      '@id': `${process.env.WEBSITE_URL}/#website`,
      url: process.env.WEBSITE_URL,
      name: 'Curaleaf - Cannabis with Confidence',
      description:
        'We are a leading vertically integrated medical and wellness cannabis operator in the United States. We craft high-quality cannabis products that are uncomplicated and approachable.',
      publisher: {
        '@id': `${process.env.WEBSITE_URL}/#organization`
      },
      // potentialAction: [
      //   {
      //     '@type': 'SearchAction',
      //     target:
      //       'https://essencevegas.com/?s={search_term_string}',
      //     'query-input': 'required name=search_term_string'
      //   }
      // ], move to shop home
      inLanguage: 'en-US'
    },
    {
      '@type': 'Organization',
      '@id': `${process.env.WEBSITE_URL}#organization`,
      name: 'Curaleaf - Cannabis with Confidence',
      url: process.env.WEBSITE_URL,
      logo: {
        '@type': 'ImageObject',
        inLanguage: 'en-US',
        '@id': `${process.env.WEBSITE_URL}/#/schema/logo/image/`,
        url: 'https://images.contentstack.io/v3/assets/blt1244f6272732d4ea/blt354b4092087c8b5c/default-category-image.png',
        contentUrl:
          'https://images.contentstack.io/v3/assets/blt1244f6272732d4ea/blt354b4092087c8b5c/default-category-image.png',
        width: 500,
        height: 457,
        caption: 'Curaleaf Logo'
      },
      image: {
        '@id': `${process.env.WEBSITE_URL}/#/schema/logo/image/`
      }
    }
  ]
};

const WEEKDAY_KEY: {
  [key in keyof OverviewPageDispensary['openTimes']]: string;
} = {
  monday: 'Mo',
  tuesday: 'Tu',
  wednesday: 'We',
  thursday: 'Th',
  friday: 'Fr',
  saturday: 'Sa',
  sunday: 'Su'
};

const getOpeningHours = (
  openTimes: OverviewPageDispensary['openTimes']
): string[] => {
  const openingHoursObj: { [key: string]: string[] } = {};
  (
    Object.keys(openTimes) as Array<keyof OverviewPageDispensary['openTimes']>
  ).forEach((key) => {
    if (
      openTimes[key][0]?.militaryStartTime &&
      openTimes[key][0].militaryEndTime
    ) {
      const timeKey = `${openTimes[key][0].militaryStartTime}-${openTimes[key][0].militaryEndTime}`;
      if (openingHoursObj[timeKey] && openingHoursObj[timeKey]?.length) {
        openingHoursObj[timeKey]?.push(WEEKDAY_KEY[key]);
      } else {
        openingHoursObj[timeKey] = [WEEKDAY_KEY[key]];
      }
    }
  });

  const openingHours: string[] = [];
  Object.keys(openingHoursObj).forEach((key) => {
    openingHours.push(`${openingHoursObj[key]?.join(', ')} ${key}`);
  });
  return openingHours;
};

export const overviewJsonLD = (
  dispensary: OverviewPageDispensary,
  breadcrumb: BreadcrumbProps['breadcrumb'],
  pathname: string
): WithContext<LocalBusiness> => {
  return {
    '@context': 'https://schema.org',
    '@type': 'LocalBusiness',
    name: `Curaleaf - ${dispensary.friendlyName}`,
    image: dispensary.overviewPhotos.length
      ? dispensary.overviewPhotos[0]?.url
      : '',
    // '@id': `${process.env.WEBSITE_URL}/shop/${dispensary.location.state.slug}/${dispensary.slug}`, // TODO
    geo: {
      '@type': 'GeoCoordinates',
      latitude: dispensary.location.coordinates.latitude,
      longitude: dispensary.location.coordinates.longitude
    },
    // url: `${process.env.WEBSITE_URL}/dispensary/${dispensary.location.state.slug}/${dispensary.slug}/`, // TODO
    telephone: dispensary.phone,
    priceRange: '$',
    hasMap: dispensary.googleMapsUri,
    address: {
      '@type': 'PostalAddress',
      // sameAs: [`${process.env.WEBSITE_URL}/${dispensary.shopLink}`],
      addressLocality: dispensary.location.city,
      addressRegion: dispensary.location.state.abbreviation,
      addressCountry: 'US',
      postalCode: dispensary.location.zipcode,
      streetAddress: dispensary.location.address
    },
    description: dispensary.overview,
    openingHours: getOpeningHours(dispensary.openTimes), // openingHoursSpecification
    // aggregateRating: {
    //   '@type': 'AggregateRating',
    //   ratingValue: '4.5',
    //   reviewCount: '1577'
    // },
    paymentAccepted: dispensary.storeOffers.reduce((arr: string[], offer) => {
      if (offer.text === 'Cash') {
        arr.push('Cash');
      }
      if (offer.text === 'CanPay') {
        arr.push('CanPay');
      }
      return arr;
    }, []),
    currenciesAccepted: 'USD',
    mainEntityOfPage: breadcrumbsJsonLD(
      dispensary.friendlyName,
      breadcrumb,
      pathname
    )
    // specialOpeningHoursSpecification: [
    //   {
    //     '@type': 'OpeningHoursSpecification',
    //     opens: '09:00',
    //     closes: '17:00',
    //     validFrom: '2021-12-24',
    //     validThrough: '2021-12-24'
    //   },
    //   {
    //     '@type': 'OpeningHoursSpecification',
    //     validFrom: '2021-12-25',
    //     validThrough: '2021-12-25'
    //   },
    //   {
    //     '@type': 'OpeningHoursSpecification',
    //     opens: '10:00',
    //     closes: '19:00',
    //     validFrom: '2021-12-31',
    //     validThrough: '2021-12-31'
    //   },
    //   {
    //     '@type': 'OpeningHoursSpecification',
    //     validFrom: '2022-01-01',
    //     validThrough: '2022-01-01'
    //   }
    // ]
  };
};

export const pdpJsonLD = (
  product: Consolidate_Product
): WithContext<Product> => {
  return {
    '@context': 'https://schema.org',
    '@type': 'Product',
    name: product.name,
    description: product.description,
    brand: product.brand
      ? {
          '@type': 'Brand',
          name: product.brand.name,
          logo: product.brand.imageUrl || ''
        }
      : '',
    category: dutchieFriendlyName(product.category),
    image: product.images[0]?.url || '',
    productID: product.id,
    // aggregateRating: {
    //   '@type': 'AggregateRating',
    //   ratingValue: 4,
    //   reviewCount: 5
    // },
    // review: [
    //   {
    //     '@type': 'Review',
    //     reviewBody:
    //       'Top tier strain. Flavorful scent to the bud, flavorful smoke with a sweet aroma. Delicious. Over 1% Caryophyllene and high terps across the board. Awesome high. ',
    //     datePublished: '2024-01-22T22:16:58.727Z',
    //     author: { '@type': 'Person', name: 'c****2' },
    //     reviewRating: { '@type': 'Rating', ratingValue: 5 }
    //   }
    // ],
    // offers: [
    //   {
    //     '@type': 'Offer',
    //     price: '25.0',
    //     availability: 'InStock',
    //     priceCurrency: 'USD'
    //   }
    // ],
    additionalProperty: [
      {
        '@type': 'PropertyValue',
        name: 'Lineage',
        value: product.strainType || ''
      }
    ]
  };
};

export const breadcrumbsJsonLD = (
  title: string,
  breadcrumbs: BreadcrumbProps['breadcrumb'],
  pathname: string
): WithContext<WebPage> => {
  return {
    '@context': 'https://schema.org',
    '@type': 'WebPage',
    name: `${title} - Curaleaf`,
    url: `${process.env.WEBSITE_URL}${pathname}`,
    breadcrumb: {
      '@type': 'BreadcrumbList',
      itemListElement: breadcrumbs.map((crumb, i) => ({
        '@type': 'ListItem',
        position: i + 1,
        item: {
          '@type': 'Thing',
          name: crumb.breadcrumbName,
          '@id': `${process.env.WEBSITE_URL}/${crumb.urlFragment}`
        }
      }))
    }
  };
};

export const shopHomeJsonLD = (pathname: string): WithContext<WebSite> => {
  return {
    '@context': 'https://schema.org',
    '@type': 'WebSite',
    url: `${process.env.WEBSITE_URL}${pathname}`,
    potentialAction: {
      '@type': 'SearchAction',
      target: `${process.env.WEBSITE_URL}?s={query}`,
      query: 'required'
    }
  };
};

export const articleJsonLD = (
  article: CS_Article_Full,
  pathname: string
): WithContext<Blog> => {
  return {
    '@context': 'https://schema.org',
    '@type': 'Blog',
    url: `${process.env.WEBSITE_URL}${pathname}`,
    headline: article.title,
    thumbnailUrl: article.article_card.thumbnailConnection.edges[0]
      ? article.article_card.thumbnailConnection.edges[0].node.url
      : '',
    datePublished: article.published_date,
    description: article.seo?.description ? article.seo.description : ''
  };
};

export const statePageJsonLD = (
  pathname: string
): WithContext<Organization> => {
  return {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: 'Curaleaf',
    url: `${process.env.WEBSITE_URL}${pathname}`,
    logo: 'https://images.contentstack.io/v3/assets/blt1244f6272732d4ea/blt354b4092087c8b5c/default-category-image.png',
    sameAs: [
      'https://www.facebook.com/CuraleafInc/',
      'https://twitter.com/Curaleaf_Inc',
      'https://www.youtube.com/channel/UCjD4LUBOWFUxXmysQ06NPsQ',
      'https://www.linkedin.com/company/curaleaf'
    ]
  };
};
