import { useEffect } from 'react';
import { FormikProps } from 'formik';

import { AuthStatus, DatalayerAnalytics } from 'services';

import { DateField } from '../../../FormBuilder/components/Date';
import { EcomCTAButton } from '../../../FormBuilder/Account/CTAButton';
import { FormStatus } from '../../../FormBuilder/Account/status';
import { TextInput } from '../../../FormBuilder/components/TextInput';
import { PackageComponentWrapper } from '../../../PackageComponentWrapper';
import { AccountPreferencesFormFields } from '../../../../templates/Account/settings';

type EditPersonalInformationProps = {
  error: AuthStatus;
  formik: FormikProps<AccountPreferencesFormFields>;
  loading: boolean;
};

const FORM_GA_TYPE = 'account_preferences';

export const EditPersonalInformationForm = ({
  error,
  formik,
  loading
}: EditPersonalInformationProps) => {
  useEffect(() => {
    DatalayerAnalytics.pushBasicEvent('form_view', {
      form_type: FORM_GA_TYPE
    });
  }, []);

  return (
    <PackageComponentWrapper>
      <form
        className="account-form"
        onSubmit={formik.handleSubmit}
        autoComplete="on">
        <div className="sidebar-header">
          <h2 className="margin-bottom-30 text-primary mh5">Edit</h2>
          {error?.text ? <FormStatus {...error} type="error" /> : null}
        </div>
        <div className="sidebar-form-padded">
          <TextInput
            title="First Name"
            type="name"
            field="firstName"
            formik={formik}
            required={true}
            customId="first-name"
            autoComplete="given-name"
          />
          <div className="last-name-input">
            <TextInput
              title="Last Name"
              type="name"
              field="lastName"
              formik={formik}
              required={true}
              customId="last-name"
              autoComplete="family-name"
              disclaimer="Enter your full name as it appears on your ID"
            />
          </div>
          <TextInput
            title="Phone Number"
            type="tel"
            field="phone"
            formik={formik}
            required={true}
            autoComplete="tel"
          />
          <TextInput
            title="Email"
            type="email"
            field="email"
            formik={formik}
            required={true}
            disabled={true}
            autoComplete="email"
          />
          <DateField
            title="Birthday"
            field="birthday"
            formik={formik}
            required={true}
          />
          <EcomCTAButton
            disabled={!formik.dirty}
            loading={loading}
            text="Save Changes"
            variant="secondary"
          />
        </div>
      </form>
    </PackageComponentWrapper>
  );
};
