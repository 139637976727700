import { Dispatch, SetStateAction } from 'react';
import { Sidebar } from 'primereact/sidebar';
import { useQuery } from '@tanstack/react-query';

import { ErrorBoundary } from '../../../utils/nullErrorBoundary';
import CloseIcon from '../../../assets/svgs/close/close-teal.svg';

import {
  ProductListTile,
  ProductListTileOrderProps
} from '../../ProductListTile';
import { Loader } from '../../Account/Loader/loader';
import { useSiteWideContext } from '../../../hooks/siteWideContext';
import {
  FontOption,
  SummaryBlock,
  SummaryBlockProps,
  ValueType
} from '../../EcomSummaryBlock';
import {
  SummaryCardHeader,
  SummaryCardHeaderProps
} from '../SummaryCardHeader';
import { getTransaction } from '../../../utils/orderHistory';

import './order-history-drawer.scss';
import { DatalayerAnalytics } from 'services';

export type OrderDetail = {
  finalTotal?: string;
  items?: ItemDetail[];
  location?: string;
  loyaltyPoints?: string;
  loyaltyPts?: string;
  loyaltyUsed?: string;
  orderSource?: string;
  totalDiscount?: string;
  totalSales?: string;
  totalTax?: string;
  transactionItemDateTime?: string;
  discountSummary?: { name: string; value: number }[] | null;
};

export type ItemDetail = {
  brand?: string;
  discount?: string;
  grams?: string;
  imageUrl?: string;
  itemTotalPrice?: string;
  itemUnitPrice?: string;
  lineage?: string;
  name?: string;
  onlineTitle?: string;
  productId?: string;
  purchasedPrice?: string;
  quantity?: string;
};

const itemsMap = (item: any) => {
  return {
    option: `${item.grams}g`,
    price: item.itemUnitPrice,
    product: {
      brand: { name: item.brand },
      images: [{ url: item.imageUrl }],
      name: item.onlineTitle,
      strainType: item.lineage
    },
    productId: item.productId,
    quantity: Math.floor(item.quantity),
    subtotal: 0
  } as unknown as ProductListTileOrderProps['item'];
};

type Props = {
  transactionId: string;
  setVisibility: Dispatch<SetStateAction<boolean>>;
  visible: boolean;
};

export const OrderHistoryDrawer = ({
  transactionId,
  setVisibility,
  visible
}: Props): JSX.Element | null => {
  const { isMobile, user } = useSiteWideContext();

  const {
    data: order,
    isFetching,
    isLoading
  } = useQuery({
    queryKey: ['order', transactionId],
    queryFn: async (): Promise<OrderDetail | null> => {
      if (user) {
        try {
          return getTransaction(transactionId).then((data) => {
            return data;
          });
        } catch (error) {
          console.error('get transaction info: ', error);
          DatalayerAnalytics.pushErrorEvent({
            category: 'api',
            location: 'packages/ui/components/OrderHistory/Drawer/index.tsx',
            description: error as string
          });
          return null;
        }
      }
      return null;
    },
    enabled: !!transactionId,
    refetchOnWindowFocus: false
  });

  const closeDrawer = () => setVisibility(false);

  const mapTransactionSummary = (
    order: OrderDetail
  ): SummaryBlockProps['info'] => {
    const summaryInfo: SummaryBlockProps['info'] = [
      {
        keyName: 'Loyalty Points Earned',
        value: `${order.loyaltyPts} pts`,
        keyOptions: {
          tooltip: 'This is a test'
        },
        valueOptions: {
          type: ValueType.Chip
        }
      },
      {
        keyName: 'Subtotal',
        value: order.totalSales,
        valueOptions: {
          type: ValueType.Price
        },
        lineOptions: {
          separatorAbove: true
        }
      },
      {
        keyName: 'Discounts',
        value: order.totalDiscount,
        valueOptions: {
          type: ValueType.Discount
        },
        lineOptions: {
          skip: order.totalDiscount ? !order.totalDiscount : true
        }
      },
      {
        keyName: 'Loyalty Points Applied',
        value: order.loyaltyUsed,
        valueOptions: {
          type: ValueType.Discount
        },
        lineOptions: {
          skip: order.loyaltyUsed ? !order.loyaltyUsed : true,
          indent: true
        }
      },
      {
        keyName: 'Taxes',
        value: order.totalTax,
        valueOptions: {
          type: ValueType.Price
        }
      },
      {
        keyName: 'Total',
        value: order.finalTotal,
        valueOptions: {
          type: ValueType.Price
        },
        lineOptions: {
          separatorAbove: true,
          font: FontOption.Bold
        }
      }
    ];
    if (order.discountSummary?.length) {
      const mappedDiscounts = order.discountSummary.map((discount) => {
        return {
          keyName: discount.name,
          value: discount.value.toFixed(2),
          valueOptions: {
            type: ValueType.Discount
          },
          lineOptions: {
            indent: true
          }
        };
      });
      summaryInfo.splice(3, 0, ...mappedDiscounts);
    }
    return summaryInfo;
  };

  return (
    <Sidebar
      visible={visible}
      position={!isMobile ? 'right' : 'bottom'}
      className="curaleaf-ecommerce order-history-sidebar"
      onHide={closeDrawer}>
      <ErrorBoundary component="order-details-drawer">
        <div className="close-container d-flex justify-content-end">
          <button
            aria-label="Close order details drawer"
            aria-hidden="true"
            onClick={closeDrawer}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                closeDrawer();
              }
            }}
            className="close-icon pointer">
            <CloseIcon />
          </button>
        </div>
        <div className="sidebar-header">
          <h2 className="margin-bottom-30 text-primary mh5">Order Details</h2>
        </div>
        {isFetching && isLoading ? (
          <Loader />
        ) : order &&
          order.transactionItemDateTime &&
          order.items &&
          order.finalTotal ? (
          <>
            <div className="information-summary-container d-flex flex-column">
              <SummaryCardHeader
                transactionDateTime={order.transactionItemDateTime}
                items={order.items as SummaryCardHeaderProps['items']}
                totalPrice={order.finalTotal}
                loyalty={order.loyaltyPts}
              />
              <div className="order-status-information margin-inline-30">
                <div className="dispensary-order-information">
                  <p className="tbody14 margin-0">{order.location}</p>
                </div>
              </div>
            </div>
            <div className="tile-container padding-inline-30 d-flex flex-column">
              {order.items.map((item: any, i: number) => {
                const specialPrice =
                  parseFloat(item.itemUnitPrice) -
                  parseFloat(item.discount) / parseFloat(item.quantity);
                return (
                  <ProductListTile
                    key={i}
                    props={{
                      item: itemsMap(item),
                      isLoading: false,
                      variant: {
                        id: item.productId,
                        option: `${item.grams}g`,
                        price: parseFloat(item.itemUnitPrice),
                        specialPrice,
                        isSpecial:
                          specialPrice !== parseFloat(item.itemUnitPrice)
                      },
                      type: 'order',
                      isOrderHistory: true
                    }}
                  />
                );
              })}
            </div>
            <div className="order-total-wrapper d-flex padding-inline-30">
              <div className="order-total margin-bottom-15 text-color-dark-grey">
                <SummaryBlock
                  props={{
                    isLoading: false,
                    info: mapTransactionSummary(order)
                  }}
                />
              </div>
            </div>
          </>
        ) : null}
      </ErrorBoundary>
    </Sidebar>
  );
};
