'use client';

import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/navigation';
import {
  CS_AccountAndLoyaltyPref,
  DatalayerAnalytics,
  Monetate
} from 'services';

import { UnauthorizedPage } from './unauthorized';
import { AccountNavigation } from '../../components/Account/Navigation';
import { BreadCrumbs } from '../../components/Breadcrumbs';
import { InstructionSteps } from '../../components/InstructionsBlock/InstructionSteps';
import { PackageComponentWrapper } from '../../components/PackageComponentWrapper';
import { Loader } from '../../components/Account/Loader/loader';

import { useLoyaltyReferrals } from '../../hooks/graphs';
import { snackbarMessage } from '../../utils/snackbar';
import { isEmailValid } from '../../utils/validators';
import { useSiteWideContext } from '../../hooks/siteWideContext';

import './account-preferences.scss';

interface LoyaltyReferral {
  email: string;
  created_at: string;
  status: string;
}

interface Props {
  referral_config: CS_AccountAndLoyaltyPref['refer_a_friend'];
}

function statusClasses(status: string) {
  switch (status) {
    case 'Registered':
      return 'bg-success-light';
    default:
      return 'bg-warning-light';
  }
}

const Table = ({ referrals }: { referrals: LoyaltyReferral[] }) => {
  const subset = [...referrals.filter((_, i) => i < 3)];
  const [full, setFull] = useState(false);

  return (
    <div className="table">
      {subset.length ? (
        <>
          {(full ? referrals : subset).map((row, i) => (
            <div
              key={i}
              className={`${
                i % 2 ? 'bg-light-grey' : ''
              } d-flex justify-content-between table-item`}>
              <div>
                <p>
                  <small className="text-grey">Sent on {row.created_at}</small>
                </p>
                <p className="subtitle text-dark-grey">{row.email}</p>
              </div>
              <span
                className={`tag ${statusClasses(
                  row.status
                )} text-dark-grey subtitle`}>
                {row.status}
              </span>
            </div>
          ))}
          {referrals.length > 3 ? (
            <p
              className="d-flex flex-column  justify-content-end show-more-container subtitle margin-bottom-0"
              onClick={() => setFull(!full)}>
              <span className="underline text-primary text-center show-more">
                Show {full ? 'Less' : 'More'}
              </span>
            </p>
          ) : null}
        </>
      ) : (
        <p className="bg-light-grey subtitle text-dark-grey padding-15">
          There are no pending referrals.
        </p>
      )}
    </div>
  );
};

export const InputWithButton = ({
  label,
  onButtonClick,
  buttonText,
  staticValue,
  readOnly = false,
  loading = false
}: {
  buttonText: string;
  onButtonClick: (
    event: ChangeEvent<HTMLInputElement>,
    value: string
  ) => void | Promise<boolean>;
  label: string;
  staticValue?: string;
  readOnly?: boolean;
  loading?: boolean;
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [hasInput, setHasInput] = useState(!!staticValue);
  const [inputValue, setInputValue] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = (e: ChangeEvent<HTMLInputElement>) => {
    setIsFocused(false);
    setHasInput(e.target.value !== '');
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  return (
    <div className="d-flex align-items-center margin-top-40 sm-border-radius text-input-container">
      <div
        className={`input-wrapper ${isFocused || hasInput ? 'focused' : ''}`}>
        <label className="input-label subtitle text-grey">{label}</label>
        <input
          className="input-field subtitle"
          type="text"
          ref={inputRef}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={handleInputChange}
          value={readOnly ? staticValue : inputValue}
          readOnly={readOnly}
        />
      </div>
      <button
        className="refer-input-btn subtitle bg-primary text-white sm-border-radius pointer"
        disabled={(!inputValue && !hasInput) || loading}
        onClick={async (e) => {
          if (inputRef.current?.value) {
            const event = e as unknown as ChangeEvent<HTMLInputElement>;
            const submit = await onButtonClick(event, inputRef.current.value);
            if (submit) {
              setHasInput(false);
              setInputValue('');
            }
          }
        }}>
        {loading ? <i className="pi pi-spin pi-spinner" /> : buttonText}
      </button>
    </div>
  );
};

export const ReferralPage = ({ referral_config }: Props) => {
  const {
    isLoggedIn,
    loading: isAuthLoading,
    selectedDispensary,
    user,
    width
  } = useSiteWideContext() || {}; // todo
  const [initialLoad, setInitialLoad] = useState(true);
  const {
    isAuthenticated,
    loyaltyReferrals: { referrals, refetchReferrals, loading },
    loyaltyUrl: { token, urlLoading },
    submit: { submitLoyaltyReferrals, submittingReferrals }
  } = useLoyaltyReferrals();
  const { push } = useRouter();

  useEffect(() => {
    DatalayerAnalytics.pushPageView({
      page_type: 'account/best-buds'
    });
    Monetate.setPageType('account/best-buds');
  }, []);

  useEffect(() => {
    if (loading !== initialLoad) {
      setInitialLoad(loading);
    }
  }, [loading]);

  const baseUrl =
    typeof window !== 'undefined'
      ? window.location.origin + window.location.pathname
      : '';
  const isDesktop = width && width >= 768;

  let container = 'flex-row justify-content-between padding-20';
  let header = 'padding-top-10';
  let pending = 'margin-top-40 margin-bottom-40';

  if (!isDesktop) {
    container = 'flex-column';
    header = 'padding-top-10 padding-bottom-10';
    pending = 'margin-top-15 margin-bottom-15';
  }

  if ((!initialLoad && !isAuthenticated) || (!isAuthLoading && !isLoggedIn)) {
    return <UnauthorizedPage />;
  }

  return (
    <>
      <BreadCrumbs
        props={{
          breadcrumb: [
            {
              breadcrumbName: 'Home',
              urlFragment: '/',
              id: 0
            },
            {
              breadcrumbName: 'Account',
              urlFragment: '/account',
              id: 1
            }
          ],
          currentPage: 'Best Buds',
          isLoading: false
        }}
      />
      <PackageComponentWrapper additionalClass="account-preferences-wrapper bg-ecom">
        <AccountNavigation currentPage="best-buds" />
        <div className="loyalty">
          <h1 className="text-center margin-bottom-15 text-primary mh4">
            Best Buds
          </h1>
          {initialLoad ? (
            <div className="order-history-wrapper">
              <Loader />
            </div>
          ) : (
            <div className="loyalty-status-wrapper bg-white padding-0">
              <div className={`d-flex column-container ${container}`}>
                {/* Left Column */}
                <div className="column-6">
                  <span className="subtitle uppercase text-dark-grey">
                    {referral_config.tagline}
                  </span>
                  <h2 className={`${header} text-primary mh5`}>
                    {referral_config.heading}
                  </h2>
                  {!isDesktop ? (
                    <div>
                      <h5 className="text-dark-grey margin-bottom-15 tbody18-bold">
                        {referral_config.instructions.title}
                      </h5>
                      <InstructionSteps
                        steps={referral_config.instructions.steps}
                      />
                    </div>
                  ) : null}
                  <InputWithButton
                    label="Enter your Friends Email"
                    buttonText="Share"
                    loading={submittingReferrals}
                    onButtonClick={async (event, value) => {
                      event?.preventDefault();
                      let submit = false;
                      if (isEmailValid(value)) {
                        if (user?.email === value) {
                          snackbarMessage(
                            'Please enter an email other than your own.'
                          );
                        } else if (
                          referrals &&
                          referrals.some(
                            (ref: { email: string }) => ref.email === value
                          )
                        ) {
                          snackbarMessage(
                            `An email has already been sent to ${value}`
                          );
                        } else {
                          await submitLoyaltyReferrals
                            .mutateAsync({
                              emailToRefer: value,
                              path: baseUrl
                            })
                            .then(({ data }) => {
                              const loyaltyReferral =
                                data?.data.loyaltyReferral;
                              if (loyaltyReferral) {
                                if (
                                  !loyaltyReferral.success &&
                                  loyaltyReferral.message
                                ) {
                                  snackbarMessage(loyaltyReferral.message);
                                } else {
                                  refetchReferrals();
                                  submit = true;
                                  snackbarMessage(
                                    `An email has been sent to ${value}.`,
                                    'success'
                                  );
                                }
                              }
                            });
                        }
                      } else {
                        snackbarMessage(
                          'Invalid email address (e.g. example@email.com).'
                        );
                      }
                      return submit;
                    }}
                    readOnly={false}
                  />
                  <div className="d-flex align-items-center justify-content-center divider-parent">
                    <span className="d-flex align-items-center divider-text uppercase subtitle">
                      or
                    </span>
                  </div>
                  {urlLoading ? (
                    <InputWithButton
                      label="Share your custom URL with your friend"
                      buttonText="Copy"
                      onButtonClick={() => {
                        snackbarMessage('Custom URL is loading', 'error');
                      }}
                      readOnly={true}
                      staticValue={'Loading...'}
                    />
                  ) : (
                    <InputWithButton
                      label="Share your custom URL with your friend"
                      buttonText="Copy"
                      onButtonClick={(event) => {
                        event?.preventDefault();
                        navigator.clipboard
                          .writeText(`${baseUrl}?lr=${token}`)
                          .then(() => {
                            snackbarMessage(
                              'Text successfully copied to clipboard.',
                              'success'
                            );
                          })
                          .catch(() => {
                            snackbarMessage(
                              'Unable to copy text to clipboard.'
                            );
                          });
                      }}
                      readOnly={true}
                      staticValue={token ? `${baseUrl}?lr=${token}` : '...'}
                    />
                  )}
                  {!isDesktop ? (
                    <>
                      <button
                        className="shop-now-button hover-text-white hover-bg-tertiary bg-primary
            sm-border-radius pointer subtitle margin-bottom-30 margin-top-30 text-white"
                        onClick={() => {
                          push(selectedDispensary?.shopLink || '/locations');
                        }}>
                        Shop Now
                      </button>
                      <p className="text-grey subtitle padding-bottom-15">
                        {referral_config.instructions.disclaimer}
                      </p>
                    </>
                  ) : null}
                  {isLoggedIn && referrals ? (
                    <>
                      <p className={`text-dark-grey ${pending}`}>
                        <strong>Pending Referrals</strong>
                      </p>
                      <Table referrals={referrals} />
                    </>
                  ) : null}
                </div>
                {/* Right Column */}
                {isDesktop ? (
                  <div className="column-4">
                    <h5 className="text-dark-grey margin-bottom-15 tbody18-bold">
                      {referral_config.instructions.title}
                    </h5>
                    <InstructionSteps
                      steps={referral_config.instructions.steps}
                    />
                    <button
                      className="shop-now-button hover-text-white hover-bg-tertiary bg-primary
          sm-border-radius pointer subtitle margin-bottom-30 margin-top-30 text-white"
                      onClick={() => {
                        push(selectedDispensary?.shopLink || '/locations');
                      }}>
                      Shop Now
                    </button>
                    <p className="text-grey subtitle">
                      {referral_config.instructions.disclaimer}
                    </p>
                  </div>
                ) : null}
              </div>
            </div>
          )}
        </div>
      </PackageComponentWrapper>
    </>
  );
};
