'use client';

import Link from 'next/link';
import classNames from 'classnames';
import { useState } from 'react';
import { useRouter } from 'next/navigation';

import { useSiteWideContext } from '../../hooks/siteWideContext';
import { ProductPopup } from '../ProductPopup';
import { Searchbar } from './Search';
import { PackageComponentWrapper } from '../PackageComponentWrapper';
import { ecommerceSanitizeQuery } from '../../utils/url';

import CuraleafLogo from '../../assets/svgs/header/full-logo-teal.svg';
import SmCartIcon from '../../assets/svgs/header/cart-sm.svg';
import SearchIcon from '../../assets/svgs/header/search-teal.svg';

const Cart = (): JSX.Element | null => {
  const { selectedDispensary, quantity } = useSiteWideContext();

  return (
    <div className={classNames('user-cart d-flex align-items-center')}>
      {selectedDispensary?.shopLink ? (
        <Link
          tabIndex={0}
          className="icon-link"
          href={ecommerceSanitizeQuery(`${selectedDispensary.shopLink}/cart`)}>
          <SmCartIcon />
          {quantity > 0 ? (
            <span className="quantity d-flex align-items-center justify-content-center tbody12 text-white bg-primary">
              {quantity}
            </span>
          ) : null}
        </Link>
      ) : null}
    </div>
  );
};

export const KioskHeaderNavigation = (): JSX.Element | null => {
  const { categories, popup, selectedDispensary } = useSiteWideContext();
  const { push } = useRouter();
  const [openSearch, setOpenSearch] = useState(false);

  return (
    <PackageComponentWrapper additionalClass="bg-white header-navigation">
      <header className="kiosk-header bg-white">
        {openSearch ? (
          <Searchbar setOpenSearch={setOpenSearch} />
        ) : (
          <div className="nav-header d-flex justify-content-between align-items-center">
            {selectedDispensary ? (
              <>
                <div
                  tabIndex={0}
                  className="margin-right-30 padding-bottom-15 curaleaf-logo-kiosk pointer"
                  onClick={() => push(selectedDispensary.shopLink)}>
                  <CuraleafLogo />
                </div>
                <nav id="navigation" className="navigation">
                  <ul className="kiosk-header-links d-flex justify-content-between padding-0 margin-0">
                    <li className="header-item">
                      <Link
                        href={`${selectedDispensary.shopLink}/specials`}
                        className="pointer tbody16-bold text-color-primary">
                        Specials
                      </Link>
                    </li>
                    {categories.data.length
                      ? categories.data.slice(0, 3).map((cat) => (
                          <li className="header-item" key={cat.key}>
                            <Link
                              href={cat.link}
                              className="pointer tbody16-bold text-color-primary">
                              {cat.title}
                            </Link>
                          </li>
                        ))
                      : null}
                  </ul>
                </nav>
                <div
                  data-has-hamburger={false}
                  className="margin-left-30 user-cart d-flex justify-content-end align-items-center">
                  <div className="pointer margin-right-10">
                    <div
                      tabIndex={0}
                      onClick={() => setOpenSearch(!openSearch)}
                      onKeyDown={(event) =>
                        event.key === 'Enter' && setOpenSearch(!openSearch)
                      }>
                      <SearchIcon />
                    </div>
                  </div>
                  <Cart />
                </div>
              </>
            ) : (
              <div className="margin-bottom-10 curaleaf-logo">
                <CuraleafLogo />
              </div>
            )}
          </div>
        )}
      </header>
      {popup.show && popup.product ? (
        <div className="product-popup-container">
          <ProductPopup
            props={{
              item: { product: popup.product.product },
              variant: popup.product.variant
            }}
          />
        </div>
      ) : null}
    </PackageComponentWrapper>
  );
};
