import { PriceRangeSectionProps, fixedPrice } from './index';

export const PriceRangeSection = ({ variants }: PriceRangeSectionProps) => {
  const prices = variants
    .map((v) => (v.isSpecial ? v.specialPrice : v.price))
    .sort((a, b) => (a && b ? a - b : 0));

  const maxPrice = prices[prices.length - 1];
  if (prices[0] && maxPrice) {
    return (
      <span className="price">
        <span className="current-price tbody16-bold text-color-primary">
          ${fixedPrice(prices[0])} - ${fixedPrice(maxPrice)}
        </span>
      </span>
    );
  }
  return null;
};
