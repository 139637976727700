import { useState } from 'react';
import { Dialog } from 'primereact/dialog';

import { KioskFooterProps } from '../../index';
import { PackageComponentWrapper } from '../../../PackageComponentWrapper';
import { CTAButton } from '../../../CTAButton';
import { Markdown } from '../../../Markdown';
import { EmployeeLogin } from '../EmployeeLogin';

import './footer-lower.scss';

export const KioskFooterLower = ({
  copyrightInfo,
  legalLinks
}: {
  copyrightInfo: KioskFooterProps['copyrightInfo'];
  legalLinks: KioskFooterProps['legalLinks'];
}): JSX.Element | null => {
  const [show, setShow] = useState(false);
  const [legalContent, setLegalContent] = useState('');

  return (
    <>
      {show ? (
        <Dialog
          className="legal-modal disable-scroll"
          draggable={false}
          style={{ width: '80vw' }}
          footer={
            <CTAButton
              text="Close"
              variant="secondary"
              callBackFunc={() => setShow(false)}
            />
          }
          onHide={() => setShow(false)}
          resizable={false}
          visible={show}>
          <PackageComponentWrapper>
            <div className="legal-content">
              <Markdown content={legalContent} />
            </div>
          </PackageComponentWrapper>
        </Dialog>
      ) : null}
      <PackageComponentWrapper additionalClass="bg-white">
        <div className="footer-lower-kiosk text-center d-flex flex-column">
          <div className="sp-links" data-test="sp-links-url">
            {legalLinks && legalLinks.length
              ? legalLinks
                  .filter((llinks, i) => i < 3)
                  .map((link) => {
                    if (link.content) {
                      return (
                        <button
                          className="text-primary legal-links"
                          key={link.id}
                          onClick={() => {
                            // typescript forcing double content check
                            if (link.content) {
                              setLegalContent(link.content);
                              setShow(true);
                            }
                          }}
                          data-test="legal-link">
                          <small>{link.linkText}</small>
                        </button>
                      );
                    }
                  })
              : null}
          </div>
          {copyrightInfo ? (
            <EmployeeLogin copyrightInfo={copyrightInfo} />
          ) : null}
        </div>
      </PackageComponentWrapper>
    </>
  );
};
