'use client';

import { useState } from 'react';
import classNames from 'classnames';
import { Accordion, AccordionTab } from 'primereact/accordion';

import {
  CurrentPathDispensary,
  DatalayerAnalytics,
  InformationBannerType
} from 'services';
import { StorefrontCarousel } from '../StorefrontCarousel';
import { SpecialTileProps } from '../SpecialTile';
import { Markdown } from '../Markdown';

import DownArrow from '../../assets/svgs/arrows/chevron-down.svg';

import './specials-tray.scss';

type SpecialsTrayProps = {
  offerList?: SpecialTileProps[];
  todaysOffers?: InformationBannerType | null;
  selectedDispensary: CurrentPathDispensary;
};

export const SpecialsTray = ({
  offerList,
  todaysOffers,
  selectedDispensary
}: SpecialsTrayProps): JSX.Element | null => {
  const [visible, setVisible] = useState<boolean>(false);

  const toggleTray = () => {
    setVisible(!visible);
    if (!visible) {
      DatalayerAnalytics.pushBasicEvent(
        'open_specials_drawer',
        {},
        selectedDispensary
      );
    }
  };

  if (todaysOffers?.title.length || offerList?.length) {
    return (
      <div
        id="specials-tray"
        className={classNames(
          'specials-tray d-flex align-items-center flex-column justify-content-end',
          {
            ['opened slide-up']: visible,
            'slide-down': !visible
          }
        )}>
        {visible ? (
          <div
            className="backdrop"
            onClick={() => {
              setVisible(!visible);
            }}></div>
        ) : null}
        <div
          className="offers-button d-flex align-items-center  justify-content-between"
          role="button"
          tabIndex={0}
          onClick={toggleTray}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              toggleTray();
            }
          }}>
          <div className="d-flex align-items-center">
            <span className="tbody16-bold">Specials</span>
            {offerList?.length ? (
              <span className="tbody14 margin-left-5">
                - {offerList?.length} Available
              </span>
            ) : null}
          </div>
          <div
            className={classNames({
              'arrow-up': !visible
            })}>
            <DownArrow />
          </div>
        </div>
        <div
          className={classNames('tray-container', {
            ['slide-up disable-scroll']: visible
          })}>
          {todaysOffers ? (
            <Accordion
              className="todays-offers-accordion margin-bottom-15"
              activeIndex={0}>
              <AccordionTab
                header={
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="header">
                      <div className="tbody12-bold">Todays Offers</div>
                      <Markdown
                        additionalClass="todays-offers-markdown"
                        content={todaysOffers.description}
                      />
                    </div>
                  </div>
                }>
                {todaysOffers.dialog ? (
                  <Markdown
                    additionalClass="todays-offers-markdown"
                    content={todaysOffers.dialog}
                  />
                ) : null}
              </AccordionTab>
            </Accordion>
          ) : null}
          {offerList ? (
            <StorefrontCarousel
              banners={offerList.map((offer) => {
                return {
                  ...offer,
                  specialLocation: 'drawer',
                  onClick: () => setVisible(false)
                };
              })}
              isLoading={false}
              type="specials"
            />
          ) : null}
        </div>
      </div>
    );
  }
  return null;
};
