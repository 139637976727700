import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';

import { clickNextSet, clickPrevSet, selectItem } from './helpers';
import { ArrowProps, DotsProps, PromoDotsProps } from './types';
import { LARGE_PROMO_BANNER_THEMES } from '../../utils/constants';
import { useSiteWideContext } from '../../hooks/siteWideContext';

import { CTAButton, CTAButtonProps } from '../CTAButton';
import { Markdown } from '../Markdown';

import ArrowLeft from '../../assets/svgs/arrows/shop-carousel-left-arrow.svg';
import ArrowRight from '../../assets/svgs/arrows/shop-carousel-right-arrow.svg';

import './components.scss';

export const CarouselDots = ({
  classname,
  data,
  dotName,
  states
}: DotsProps): JSX.Element | null => {
  const { selectedItem, setActiveImageIndex } = states;
  if (!data || data.length < 1) return null;

  return (
    <section className={classname}>
      {data &&
        selectedItem &&
        data.map((item, index) => {
          return (
            <button
              className={
                selectedItem.id === item.id ? 'sm-dot active' : 'sm-dot pointer'
              }
              type="button"
              key={item.id}
              aria-label={dotName}
              onClick={() => setActiveImageIndex(index)}
            />
          );
        })}
    </section>
  );
};

export const CarouselArrowLeft = (props: ArrowProps): JSX.Element | null => {
  const { carouselLimiter, data, states } = props;
  const {
    setClassName,
    activeImageIndex,
    setActiveImageIndex,
    autoSlide,
    setAutoSlide,
    autoSlideSeconds
  } = states;

  const functionStates = {
    setActiveImageIndex,
    activeImageIndex,
    setClassName
  };

  const slideOptions = {
    autoSlide,
    setAutoSlide,
    autoSlideSeconds
  };

  if (!data || data.length < 1) return null;

  return (
    <section
      className="arrows-parent align-items-center"
      data-test="dots-container">
      <button
        className="prev-btn"
        data-test="prev-btn"
        type="button"
        onClick={() =>
          clickPrevSet(carouselLimiter, slideOptions, data, functionStates)
        }
        aria-label="previous">
        <ArrowLeft />
      </button>
    </section>
  );
};

export const CarouselArrowRight = (props: ArrowProps): JSX.Element | null => {
  const { carouselLimiter, data, states } = props;
  const {
    setClassName,
    activeImageIndex,
    setActiveImageIndex,
    autoSlide,
    setAutoSlide,
    autoSlideSeconds
  } = states;

  const functionStates = {
    setActiveImageIndex,
    activeImageIndex,
    setClassName
  };

  const slideOptions = {
    autoSlide,
    setAutoSlide,
    autoSlideSeconds
  };

  if (!data || data.length < 1) return null;

  return (
    <section
      className="arrows-parent align-items-center"
      data-test="dots-container">
      <button
        className="next-btn"
        data-test="next-btn"
        type="button"
        onClick={() =>
          clickNextSet(carouselLimiter, slideOptions, data, functionStates)
        }
        aria-label="next">
        <ArrowRight />
      </button>
    </section>
  );
};

export type CarouselBannerTextBlockDataType = {
  heading: string;
  text: string;
  ctabutton?: CTAButtonProps | null;
  theme?: string;
  background_hex_color?: string;
};

type CarouselBannerTextBlockProps = {
  data: CarouselBannerTextBlockDataType;
  states?: {
    index?: number;
    activeImageIndex: number;
  };
  size: string;
};

export const CarouselTextBlock = ({
  data,
  size,
  states
}: CarouselBannerTextBlockProps): JSX.Element | null => {
  const { DARK } = LARGE_PROMO_BANNER_THEMES;
  return (
    <section className={`col-12 ${size}`}>
      <div
        className={`text-container ${data.theme === DARK ? 'dark' : 'light'}`}
        data-test="text-block"
        style={
          data.background_hex_color && data.background_hex_color.length
            ? { backgroundColor: data.background_hex_color }
            : undefined
        }>
        <div>
          <Markdown
            data-test={
              states && states.index === states.activeImageIndex
                ? 'heading-active'
                : 'heading'
            }
            content={data.heading}
            // Removes the id from header that's added by showdown by default
          />
          <Markdown
            // className="text-body"
            content={data.text}
          />
        </div>
        {data.ctabutton && (
          <CTAButton {...data.ctabutton} data-test="cta-button" />
        )}
      </div>
    </section>
  );
};

export const PromoCarouselDots = (
  props: PromoDotsProps & { carouselLimiter: number }
): JSX.Element | null => {
  const { data, states, dotName, arrows, fade, carouselLimiter } = props;
  const {
    selectedItem,
    setClassName,
    activeImageIndex,
    setActiveImageIndex,
    autoSlide,
    setAutoSlide,
    autoSlideSeconds
  } = states;

  const functionStates = {
    setActiveImageIndex,
    activeImageIndex,
    setClassName
  };

  const slideOptions = {
    autoSlide,
    setAutoSlide,
    autoSlideSeconds
  };

  const { width } = useSiteWideContext();
  if (!data || data.length < 1) return null;

  return (
    <section
      className="dots-parent d-flex row align-items-center"
      data-test="dots-container">
      {arrows && width && width >= 768 && (
        <button
          className="prev-btn"
          data-test="prev-btn"
          type="button"
          onClick={() => {
            clickPrevSet(carouselLimiter, slideOptions, data, functionStates);
          }}
          aria-label="previous">
          <BsChevronLeft size={30} />
        </button>
      )}
      {data &&
        selectedItem &&
        data.map((item, index) => (
          <button
            data-test={dotName}
            className={
              selectedItem.id === item.id ? `${dotName} active` : dotName
            }
            type="button"
            key={item.id}
            aria-label={dotName}
            onClick={() =>
              fade ? selectItem(index, states) : setActiveImageIndex(index)
            }
          />
        ))}
      {arrows && width && width >= 768 && (
        <button
          className="next-btn"
          data-test="next-btn"
          type="button"
          onClick={() => {
            clickNextSet(carouselLimiter, slideOptions, data, functionStates);
          }}
          aria-label="next">
          <BsChevronRight size={30} />
        </button>
      )}
    </section>
  );
};
