import { useEffect, useState } from 'react';
import { usePathname } from 'next/navigation';

import { DatalayerAnalytics, InformationBannerType, Monetate } from 'services';
import { AccountBanner } from '../components/Account/Banner';
import { InformationBanners } from '../components/InformationBanners';
import { PageState } from '../components/PageState';
import { PersonalizationCarousel } from '../components/Personalization';
import { useDispensaryPathContext } from '../hooks/dispensaryPathContextProvider';
import { useSiteWideContext } from '../hooks/siteWideContext';
import { StorefrontPageProps } from './storefrontPage';
import { StorefrontCarousel } from '../components/StorefrontCarousel';
import { StorefrontBanners } from '../components/StorefrontBanners';
import { WideBanner } from '../components/WideBanner';
import { productsAnalyticsMap } from '../utils/storefrontCarouselsMap';
import { shopHomeJsonLD } from '../utils/jsonLD';
import { useMonetateExperience } from '../hooks/useMonetateExperience';
import {
  PersonalizedCarousels,
  fetchProductsForExperiences
} from '../utils/personalized';
import { moodiDayScript } from '../utils/moodiDayScript';

type HomePageProps = Omit<StorefrontPageProps, 'formConfiguration'>;

export const DutchiePlusHomePage = ({
  productLists,
  storefrontBanners
}: HomePageProps) => {
  const {
    currentPathDispensary,
    defaultMenuType,
    infoBanners,
    offerList,
    todaysOffers
  } = useDispensaryPathContext();
  const { categories, sort, websiteUrl } = useSiteWideContext();
  const asPath = usePathname();
  const [showWideBanner, setShowWideBanner] = useState(true);
  const [informationBanners, setInfoBanners] =
    useState<InformationBannerType[]>();
  const [personalized, setPersonalized] = useState<PersonalizedCarousels>({
    top: [],
    bottom: []
  });

  const { experiences } = useMonetateExperience({
    pageTemplate: 'shop/home'
  });

  useEffect(() => {
    Monetate.setPageType('shop/home');
  }, [asPath]);

  useEffect(() => {
    if (currentPathDispensary?.location?.state) {
      DatalayerAnalytics.pushPageView({
        page_type: 'shop/home',
        consolidateDispensary: currentPathDispensary
      });
    }
    Monetate.setPageType('shop/home');
  }, []);

  useEffect(() => {
    if (
      experiences.length &&
      !personalized.top.length &&
      !personalized.bottom.length &&
      sort.value
    ) {
      fetchProductsForExperiences(
        websiteUrl,
        experiences,
        currentPathDispensary.retailerId,
        defaultMenuType,
        setPersonalized,
        'HomePage',
        sort.value
      );
    }
  }, [experiences, sort]);

  useEffect(() => {
    const banners = [];
    if (infoBanners?.banners) banners.push(...infoBanners.banners);
    if (todaysOffers) banners.unshift(todaysOffers);
    if (banners.length) setInfoBanners(banners);
  }, [todaysOffers, infoBanners]);

  useEffect(() => {
    // MOODIDAY after products load in
    moodiDayScript();
  }, []);

  if (!productLists[defaultMenuType].length) {
    DatalayerAnalytics.pushErrorEvent({
      category: 'expected',
      location: 'shopHomePage',
      description: 'No products found',
      consolidateDispensary: currentPathDispensary
    });
    return (
      <PageState
        header="Products not available."
        link={{
          url: '/locations',
          text: 'Check out our other locations!'
        }}
      />
    );
  }

  return (
    <>
      {process.env.IS_KIOSK !== 'true' ? (
        <section>
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
              __html: JSON.stringify(
                shopHomeJsonLD(currentPathDispensary.shopLink)
              )
            }}
          />
        </section>
      ) : null}
      {showWideBanner && currentPathDispensary.storefrontMap ? (
        <WideBanner
          widebanner={currentPathDispensary.storefrontMap.ctaBanners}
          onClose={() => {
            setShowWideBanner(false);
          }}
        />
      ) : null}
      <div id="information-banners">
        <InformationBanners
          props={{
            banners: informationBanners ?? [],
            isLoading: false,
            setBanners: infoBanners ? infoBanners.set : () => undefined,
            sticky: infoBanners?.sticky ?? false
          }}
        />
      </div>
      <StorefrontBanners
        props={{
          isLoading: false,
          banners: storefrontBanners
        }}
      />
      {process.env.IS_KIOSK !== 'true' ? <AccountBanner /> : null}
      <div id="categories">
        <StorefrontCarousel
          banners={categories.data}
          isLoading={categories.loading}
          title="Categories"
          type="categories"
        />
      </div>
      <div id="specials-carousel">
        <StorefrontCarousel
          banners={offerList}
          isLoading={!offerList}
          title="Specials"
          type="specials"
          link={`${currentPathDispensary.shopLink}/specials`}
        />
      </div>
      <PersonalizationCarousel
        props={{
          isLoading: !personalized.bottom,
          recommendations: personalized.top
        }}
      />
      {(
        productLists[defaultMenuType] || [{ title: '', link: '', products: [] }]
      ).map((p) => {
        return (
          <StorefrontCarousel
            key={p.title}
            banners={productsAnalyticsMap(p.products)}
            isLoading={false}
            link={p.link}
            title={p.title}
            type="products"
          />
        );
      })}
      <PersonalizationCarousel
        props={{
          isLoading: !personalized.bottom,
          recommendations: personalized.bottom
        }}
      />
    </>
  );
};
