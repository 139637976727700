import { ErrorBoundary } from '../../../utils/nullErrorBoundary';

import './cart-button.scss';

type CartButtonProps = {
  callBackFunc: () => void;
  text: string;
  loading?: boolean;
  variant: 'primary' | 'secondary' | 'tertiary';
  disabled?: boolean;
};

const CartButton = ({
  callBackFunc,
  text,
  loading = false,
  disabled,
  variant = 'primary'
}: CartButtonProps): JSX.Element | null => {
  if (!text || !callBackFunc) return null;

  return (
    <ErrorBoundary component="cart-button">
      <button
        data-button-disabled={disabled}
        onClick={() => {
          callBackFunc();
        }}
        disabled={loading || disabled}
        className={`cart-button tbody16-bold btn ${variant} pointer`}
        data-test="cart-button"
        title={text}>
        {loading ? (
          <div className="spinner-icon">
            <div className="loading-spinner" />
          </div>
        ) : (
          text
        )}
      </button>
    </ErrorBoundary>
  );
};

export default CartButton;
