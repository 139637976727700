'use client';

import { CS_State } from 'services';

import { MobileBottomLinkType, SocialMediaLinksData } from '../../models/types';
import { KioskFooterNavigation } from './Kiosk';
import { WebFooterNavigation } from './Web';

export type FooterSectionLinks = {
  linkName: string;
  url?: string;
  id: number;
  callBackFunc?: () => void;
};

export type FooterNavLinks = {
  heading: string;
  links: FooterSectionLinks[];
  url?: string;
  id: number;
};

export type WebFooterProps = {
  copyrightInfo: string;
  disclaimer?: string;
  forKiosk: false;
  footerLinks: FooterNavLinks[];
  legalLinks: MobileBottomLinkType[];
  socialMediaLinks: SocialMediaLinksData[];
  states?: Pick<
    CS_State,
    'slug' | 'show_transparency_link' | 'lab_results_url'
  >[];
};

export type KioskFooterProps = {
  copyrightInfo: string;
  disclaimer?: string;
  forKiosk: true;
  legalLinks: {
    content?: string;
    id: number;
    linkText: string;
    page?: {
      slug: string;
    };
  }[];
};

export const FooterNavigation = (
  props: WebFooterProps | KioskFooterProps
): JSX.Element | null => {
  if (props.forKiosk) {
    return <KioskFooterNavigation {...props} />;
  }
  return <WebFooterNavigation {...props} />;
};
