import { SortBy } from '../models/types';
import {
  Consolidate_Product,
  Consolidate_ProductVariant,
  Curaql_Cart,
  DutchiePlus_OrderType,
  DutchiePlus_PricingType,
  ProductTileType
} from 'services';
import { SiteWideWrapperProps } from '../hooks/siteWideContext';
import { ProductListTilePopupProps } from '../components/ProductListTile';
import {
  favoredBrandsSort,
  sortByPopular,
  sortByRecommendedWithPopularity
} from './brandFavoring';

export function formatPrice(price: number): string {
  return (price / 100).toFixed(2);
}

const convertPotencyValue = (
  product: Pick<Consolidate_Product, 'potencyThc'>
): number => {
  const range = product?.potencyThc?.range;
  return range?.[1] ?? range?.[0] ?? 0;
};

export const sortProducts = (
  products: ProductTileType[],
  sortBy: SortBy,
  montateSort: SiteWideWrapperProps['sort']['value']
) => {
  const sortedProducts = [...products];
  if (sortBy === 'potency-percent-asc-rank') {
    sortedProducts.sort(
      (a, b) => convertPotencyValue(b) - convertPotencyValue(a)
    );
  } else if (sortBy === 'potency-percent-desc-rank') {
    sortedProducts.sort(
      (a, b) => convertPotencyValue(a) - convertPotencyValue(b)
    );
  } else if (sortBy === 'alphabetized') {
    sortedProducts.sort((a: ProductTileType, b: ProductTileType) => {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    });
  } else if (sortBy === 'price-asc-rank') {
    sortedProducts.sort((a: ProductTileType, b: ProductTileType) => {
      const aPrice = a.variants[0]?.specialPrice || a.variants[0]?.price;
      const bPrice = b.variants[0]?.specialPrice || b.variants[0]?.price;
      if (aPrice && bPrice) {
        if (aPrice > bPrice) return -1;
        if (aPrice < bPrice) return 1;
      }
      return 0;
    });
  } else if (sortBy === 'price-desc-rank') {
    sortedProducts.sort((a: ProductTileType, b: ProductTileType) => {
      const aPrice = a.variants[0]?.specialPrice || a.variants[0]?.price;
      const bPrice = b.variants[0]?.specialPrice || b.variants[0]?.price;
      if (aPrice && bPrice) {
        if (aPrice < bPrice) return -1;
        if (aPrice > bPrice) return 1;
      }
      return 0;
    });
  } else if (sortBy === 'recommended') {
    if (montateSort === 'recommended-by-popularity') {
      sortedProducts.sort(sortByRecommendedWithPopularity);
    } else {
      sortedProducts.sort(favoredBrandsSort);
    }
  } else if (sortBy === 'popular' && montateSort !== 'popular') {
    sortedProducts.sort(sortByPopular);
  }
  return sortedProducts;
};

export const addCartCompletion = (
  cart: Curaql_Cart | undefined,
  product: ProductListTilePopupProps['item']['product'],
  selectedVariant: Consolidate_ProductVariant,
  quantity: number,
  setPopupProduct?: SiteWideWrapperProps['popup']['setProduct'],
  setShowPopup?: SiteWideWrapperProps['popup']['setShow']
) => {
  if (cart) {
    if (setPopupProduct) {
      setPopupProduct({
        product,
        variant: selectedVariant,
        quantity
      });
    }
    if (setShowPopup) {
      setShowPopup(true);
    }
  }
};

export const deliveryTypeCheck = (
  orderTypes: DutchiePlus_OrderType[],
  pricingType: DutchiePlus_PricingType
) =>
  orderTypes.some((dot) => {
    return (
      (dot === 'AU_DELIVERY' && pricingType === 'RECREATIONAL') ||
      (dot === 'MED_DELIVERY' && pricingType === 'MEDICAL') ||
      dot === 'DELIVERY'
    );
  });

export const getDealSignal = (
  offers?: Consolidate_Product['offers']
): string => {
  let dealSignal = '';
  if (offers?.length) {
    let stringifiedOffers = JSON.stringify(offers).toLowerCase();

    // remove all spend x, get % off offers
    if (
      stringifiedOffers.includes('spend') &&
      stringifiedOffers.includes('% off')
    ) {
      const updatedOffers = offers.reduce(
        (arr: Consolidate_Product['offers'], offer) => {
          const lowerCasedTitle = offer.title.toLowerCase();
          if (
            !lowerCasedTitle.includes('spend') &&
            !lowerCasedTitle.includes('% off')
          ) {
            arr.push(offer);
          }
          return arr;
        },
        []
      );
      if (updatedOffers.length !== offers.length) {
        stringifiedOffers = JSON.stringify(updatedOffers).toLowerCase();
      }
    }
    if (stringifiedOffers.includes('bogo')) {
      dealSignal = 'BOGO';
    } else if (stringifiedOffers.includes('deal')) {
      dealSignal = 'Deal';
    } else if (stringifiedOffers.includes('% off')) {
      // currently only works for cart items
      const matchingPercentOffOffer = offers.find((o) =>
        o.title.toLowerCase().includes('% off')
      );
      if (matchingPercentOffOffer) {
        const splitOfferString = matchingPercentOffOffer.title.split(' ');
        const findPercent = splitOfferString.find((str) => str.includes('%'));
        if (findPercent) {
          dealSignal = `${findPercent} Off`;
        }
      }
    }
  }
  return dealSignal;
};
