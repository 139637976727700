import { LoadingSkeleton } from '../Loader/Skeleton';

export const Loader = ({ forCarousel = false }) => {
  return (
    <div
      className="product-tile-container margin-bottom-10 pointer bg-white"
      data-test="product-tile">
      <a>
        <div className="tile">
          <div className="image">
            <LoadingSkeleton variant="tile-img" />
          </div>
          <div className="product-details">
            <div className="d-flex justify-content-between align-items-center">
              <div className="price" data-test="price-section">
                <span className="curr-price" data-test="price-med">
                  <LoadingSkeleton width="32px" />
                </span>
              </div>
            </div>
            <h2 className="product-name text-color-primary margin-0 tbody16-bold">
              <LoadingSkeleton width="100%" />
              <span className="margin-top-5">
                <LoadingSkeleton width="60%" />
              </span>
            </h2>
            {!forCarousel ? (
              <>
                <div data-test="brand">
                  <span className="text-color-grey tbody12">
                    <LoadingSkeleton width="100px" />
                  </span>
                </div>
                <div>
                  <span data-test="thc" className="text-color-grey tbody12">
                    <LoadingSkeleton width="40%" />
                  </span>
                </div>
              </>
            ) : null}
          </div>
        </div>
      </a>
    </div>
  );
};
