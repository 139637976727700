import { useEffect, useState } from 'react';
import Link from 'next/link';

import { FooterNavLinks, FooterSectionLinks, WebFooterProps } from '../index';
import { ErrorBoundary } from '../../../utils/nullErrorBoundary';
import { useSiteWideContext } from '../../../hooks/siteWideContext';
import { PackageComponentWrapper } from '../../PackageComponentWrapper';

export const FooterUpper = ({
  footerLinks,
  states
}: {
  footerLinks: WebFooterProps['footerLinks'];
  states: WebFooterProps['states'];
}): JSX.Element | null => {
  const { selectedDispensary } = useSiteWideContext();
  const [showTransparencyLink, setShow] = useState(false);
  const [transparencyUrl, setTranspensaryUrl] = useState<null | string>(null);

  useEffect(() => {
    if (selectedDispensary && states?.length) {
      const matchingState = states.find(
        (state) => state.slug === selectedDispensary.location.state.slug
      );
      if (
        matchingState?.show_transparency_link &&
        matchingState.lab_results_url
      ) {
        setTranspensaryUrl(matchingState.lab_results_url);
        setShow(true);
      }
    }
  }, [selectedDispensary, states]);

  return (
    <PackageComponentWrapper additionalClass="bg-primary" maxWidth={true}>
      <div className="footer-upper">
        {footerLinks.map((section: FooterNavLinks) => (
          <div
            key={section.id}
            className="d-flex flex-column footer-links-container"
            data-test="footer-links-container">
            <div className="footer-header-border">
              {section.url && section.url.length ? (
                <Link href={section.url}>{section.heading}</Link>
              ) : (
                <h5
                  data-test="footer-header"
                  className="mh7 text-white footer-link-header margin-bottom-15">
                  {section.heading}
                </h5>
              )}
            </div>
            {section.links && section.links.length ? (
              <ul key={section.id} className="margin-0 padding-0">
                {section.links.map((link: FooterSectionLinks, i) => (
                  <span key={link.id}>
                    <li
                      data-test="footer-link"
                      className="padding-top-5 padding-bottom-5">
                      {link.url ? (
                        <a
                          className="tbody14 text-light-green text-decoration-none hover-color-white"
                          data-test="footer-link-url"
                          href={link.url}
                          target={link.url.startsWith('/') ? '' : '_blank'}>
                          {link.linkName}
                        </a>
                      ) : null}
                      {link.callBackFunc ? (
                        <a
                          onClick={link.callBackFunc}
                          className="tbody14 text-light-green text-decoration-none hover-color-white">
                          {link.linkName}
                        </a>
                      ) : null}
                    </li>
                    <ErrorBoundary component="transparency-link">
                      {section.heading === 'Services' &&
                      i === section.links.length - 1 &&
                      showTransparencyLink &&
                      transparencyUrl ? (
                        <li>
                          <Link
                            className="tbody14 text-light-green text-decoration-none hover-color-white"
                            href={transparencyUrl}
                            data-test="footer-link-url">
                            Transparency
                          </Link>
                        </li>
                      ) : null}
                    </ErrorBoundary>
                  </span>
                ))}
              </ul>
            ) : null}
          </div>
        ))}
      </div>
    </PackageComponentWrapper>
  );
};
