import { LoadingSkeleton } from '../Loader/Skeleton';

const info = [
  {
    valueOptions: {
      type: 'chip',
      append: 'pts'
    }
  },
  {
    lineOptions: {
      separatorAbove: true
    },
    valueOptions: {
      type: 'price'
    }
  },
  {
    valueOptions: {
      type: 'discount'
    }
  },
  {
    valueOptions: {
      type: 'discount'
    },
    lineOptions: {
      indent: true
    }
  },
  {
    valueOptions: {
      type: 'price'
    },
    lineOptions: {
      separatorAbove: true
    }
  }
];

export const Loader = (): JSX.Element | null => {
  return (
    <>
      {info.map((lineItem, i) => {
        const indent = lineItem.lineOptions?.indent ? 'indent-line' : '';
        // const val = lineItem.value;

        return (
          <div key={i} className="margin-bottom-10">
            <div
              className={lineItem.lineOptions?.separatorAbove ? 'hairline' : ''}
            />
            <div
              className={`d-flex justify-content-between text-color-dark-grey margin-bottom-10 ${indent}`}>
              <span>
                <LoadingSkeleton height="22px" width="75px" />
              </span>
              <span>
                <LoadingSkeleton height="22px" width="25px" />
              </span>
            </div>
          </div>
        );
      })}
    </>
  );
};
