import { FormikProps } from 'formik';
import { classNames } from 'primereact/utils';
import { InputTextarea } from 'primereact/inputtextarea';
import { FloatLabel } from 'primereact/floatlabel';

import {
  DefaultFormik,
  getFormErrorMessage,
  isFormFieldValid
} from '../../../../utils/forms';

import '../form.scss';

export type TextAreaProps = {
  title: string;
  field: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formik?: FormikProps<any>;
  required?: boolean;
};

export const TextArea = ({ field, formik, title, required }: TextAreaProps) => {
  if (!formik) {
    formik = DefaultFormik();
  }
  return (
    <div
      data-test="text-area-container"
      className={classNames('text-area-curaleaf margin-bottom-15', {
        'form-error': isFormFieldValid(field, formik) && formik.submitCount > 0
      })}>
      <FloatLabel>
        <InputTextarea
          data-test="text-area"
          name={field}
          id={field}
          value={formik.values[field]}
          onChange={formik.handleChange}
          autoResize
        />
        <label
          data-test="text-area-title"
          className="form-header-curaleaf d-flex align-items-center"
          htmlFor={field}>
          {title}
          {required ? <sup data-test="text-area-required">*</sup> : null}
        </label>
      </FloatLabel>
      {formik.submitCount > 0 ? getFormErrorMessage(field, formik) : null}
    </div>
  );
};
