import { useRouter } from 'next/navigation';

import { ProductListTile } from '../ProductListTile';
import { ProductFilter } from './ProductFilter';
import { ProductSortFilter } from './ProductSortFilter';
import { PageState } from '../PageState';

import { EcomProductGridProps } from '.';
import { PackageComponentWrapper } from '../PackageComponentWrapper';
import { TextBlock } from '../TextBlock';
import { ProductTile } from '../ProductTile/default';
import { useDispensaryPathContext } from '../../hooks/dispensaryPathContextProvider';
import { DatalayerAnalytics } from 'services';

export const EcomProductGrid = ({
  filters,
  isLoading,
  offersLength,
  onSelectFilter,
  pageTitle,
  pageType,
  products,
  searchParams,
  setProductView,
  shopLink,
  sortBy,
  totalProductCount,
  view
}: EcomProductGridProps): JSX.Element | null => {
  const { currentPathDispensary } = useDispensaryPathContext();
  const { push, refresh } = useRouter();
  if (pageType === 'AllSpecialsPage' && offersLength < 1) {
    DatalayerAnalytics.pushErrorEvent({
      category: 'unknown',
      location: 'ecomProductGrid',
      description: 'There are currently no specials available.',
      consolidateDispensary: currentPathDispensary
    });
    return (
      <PageState
        header="There are currently no specials available."
        link={{
          url: shopLink,
          text: 'Check out our other products!'
        }}
      />
    );
  }

  const hasSelectedFilters = () => {
    const queries = new URLSearchParams(window.location.search);
    queries.delete('page');
    queries.delete('sort');
    queries.delete('k');
    return queries.toString().length > 0;
  };

  return (
    <PackageComponentWrapper additionalClass="bg-ecom">
      <div className="ecom-product-grid-wrapper">
        {filters && Object.keys(filters).length ? (
          <ProductSortFilter
            props={{
              filters,
              isLoading,
              onSelectFilter,
              pageTitle,
              pageType,
              productView: view,
              productLength: products.length,
              searchParams,
              setProductView,
              shopLink,
              sortBy
            }}
          />
        ) : null}
        <div className="d-flex margin-left-30 margin-top-20 filter-subtitle justify-content-between">
          {totalProductCount > 0 &&
          pageType !== 'AllBrandsPage' &&
          pageType !== 'AllSpecialsPage' ? (
            <div
              className={
                'tbody12 text-color-grey'
              }>{`${totalProductCount} Results`}</div>
          ) : null}
          {hasSelectedFilters() ? (
            <a
              role="button"
              tabIndex={0}
              className="clear-filter tbody12 text-color-grey hover-color-tertiary underline pointer"
              onClick={() => {
                push(window.location.pathname);
                refresh();
              }}>
              Clear Filters
            </a>
          ) : null}
        </div>
        <div className="d-flex overflow-hidden">
          {filters && Object.keys(filters).length ? (
            <div
              className={`dt-product-filter-wrapper margin-left-30 ${totalProductCount <= 0 && !hasSelectedFilters() ? 'margin-top-30' : 'margin-top-5'}`}>
              <ProductFilter
                props={{
                  filters,
                  isLoading,
                  onSelect: onSelectFilter,
                  pageType,
                  searchParams,
                  shopLink,
                  sortBy
                }}
              />
            </div>
          ) : null}
          {!isLoading &&
          products.length <= 0 &&
          !['AllBrandsPage', 'AllSpecialsPage'].includes(pageType) ? (
            <div className="ecom-product-text-block-wrapper container-lr">
              <TextBlock
                align="center"
                content={[
                  `<h4 className="text-primary th2-bold">We couldn't find what you're looking for.</h4>
                  \n${pageType === 'SubSpecialPage' ? `See our other <u>[specials](${shopLink}/specials)</u>` : `Try a different ${pageType === 'SearchPage' ? 'search term' : 'filter'}`} or go back to our <u>[home page](${shopLink})</u>!`
                ]}
              />
            </div>
          ) : pageType === 'AllBrandsPage' ? (
            <div className="ecom-product-text-block-wrapper container-lr">
              <TextBlock
                align="center"
                content={[
                  '<h2 class="mh5 text-primary">Select a brand to get started!</h2>'
                ]}
              />
            </div>
          ) : pageType === 'AllSpecialsPage' ? (
            <div className="ecom-product-text-block-wrapper container-lr">
              <TextBlock
                align="center"
                content={[
                  '<h2 class="mh5 text-primary">Select a special to get started!</h2>'
                ]}
              />
            </div>
          ) : (
            <div className="ecom-grid-wrapper">
              <div
                className={`ecom-product-${view} container-lr ${totalProductCount <= 0 && !hasSelectedFilters() ? 'margin-top-30' : 'margin-top-5'}`}
                data-test="product-grid">
                {/* Ensure that all product tiles have same styles */}
                {view === 'grid'
                  ? products.map((product, i) => (
                      <ProductTile
                        key={`grid-${product.id}-${i}`}
                        isLoading={false}
                        product={product}
                      />
                    ))
                  : products.map((product, i) => (
                      <ProductListTile
                        key={`list-${product.id}-${i}`}
                        props={{
                          isLoading: false,
                          item: { product },
                          shopLink,
                          type: 'product'
                        }}
                      />
                    ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </PackageComponentWrapper>
  );
};
