import { useEffect, useState } from 'react';
import { InView } from 'react-intersection-observer';

import { DatalayerAnalytics, InformationBannerType } from 'services';
import { useCarousel } from '../../hooks/useCarousel';
import { InformationBannersProps } from './index';
import { InformationBanner } from './Banner';
import Carousel from '../Carousel';
import { PackageComponentWrapper } from '../PackageComponentWrapper';
import { CarouselDots } from '../Carousel/components';
import { TodaysOffers } from './TodaysOffers';

export const InformationBanners = ({
  banners,
  setBanners
}: InformationBannersProps): JSX.Element | null => {
  const [showBulletinModal, setBulletinModal] = useState(false);
  const [updatedBanners, setUpdatedBanners] = useState<InformationBannerType[]>(
    []
  );
  const [scrolling, setScrolling] = useState(false);

  const {
    setSelectedItem,
    setClassName,
    selectedItem,
    activeImageIndex,
    setActiveImageIndex,
    autoSlide = banners.length > 1,
    setAutoSlide,
    autoSlideSeconds
  } = useCarousel(banners, true, 7);

  useEffect(() => {
    if (banners) setUpdatedBanners([...banners]);
  }, [banners]);

  useEffect(() => {
    const handleScroll = () => {
      if (window && typeof window !== 'undefined') {
        const currentScrollY = window.scrollY;
        if (currentScrollY === 0) {
          // Banner is at the top of the page
          setUpdatedBanners(banners);
          setActiveImageIndex(0);
          setSelectedItem(banners[0]);
          setScrolling(false);
        } else if (currentScrollY) {
          // User is scrolling
          const errorBanners = [...updatedBanners].filter(
            (b) => b.severity === 'ERROR'
          );
          if (errorBanners.length) {
            if (currentScrollY >= 10) {
              if (errorBanners.length) {
                setUpdatedBanners(errorBanners);
                setActiveImageIndex(0);
                // eslint-disable-next-line  @typescript-eslint/no-explicit-any
                setSelectedItem(errorBanners as any);
                setAutoSlide(false);
              }
            }
            setScrolling(currentScrollY >= 240);
          }
        } else {
          setScrolling(false);
        }
      }
    };

    window.addEventListener('wheel', handleScroll);
    return () => {
      window.removeEventListener('wheel', handleScroll);
    };
  }, [updatedBanners]);

  const states = {
    setSelectedItem,
    setClassName,
    selectedItem,
    activeImageIndex,
    setActiveImageIndex,
    autoSlide,
    setAutoSlide,
    autoSlideSeconds
  };

  if (!updatedBanners.length) return null;

  const handleAnalytics = (banner: InformationBannerType) => {
    DatalayerAnalytics.pushBasicEvent('in_view', {
      component: 'information_banners',
      title: banner.title
        .replace(`'`, '')
        .replace('-', '_')
        .replace(' ', '_')
        .toLowerCase(),
      link_url: banner.cta?.url ? banner.cta.url : undefined
    });
  };

  const removeBanner = (id: number) => {
    const indexToRemove = updatedBanners.findIndex((ban) => ban.id === id);
    if (indexToRemove !== -1) {
      const dupe = [...updatedBanners];
      dupe.splice(indexToRemove, 1);
      if (dupe.length && typeof dupe[0]?.id === 'number') {
        setActiveImageIndex(dupe[0].id);
        setSelectedItem(dupe[0]);
        if (dupe.length === 1) {
          setAutoSlide(false);
        }
      }
      setUpdatedBanners(dupe);
      setBanners(dupe);
    }
  };

  const bannerWithDialog = banners.find((b) => b.dialog);

  return (
    <PackageComponentWrapper
      additionalClass={scrolling ? 'bg-transparent' : 'bg-ecom'}>
      <div className="information-banner-carousel">
        <Carousel data={updatedBanners} states={states}>
          {updatedBanners.map((banner) => (
            <InView
              key={banner.id}
              threshold={1}
              triggerOnce={true}
              onChange={(inView) => inView && handleAnalytics(banner)}>
              {({ ref }) => (
                <div
                  ref={ref}
                  key={banner.id}
                  className="carousel-el"
                  style={{
                    transform: `translate3d(${-activeImageIndex * 100}%, 0, 0)`
                  }}>
                  <InformationBanner
                    banner={banner}
                    removeBanner={removeBanner}
                    setBulletinModal={setBulletinModal}
                  />
                </div>
              )}
            </InView>
          ))}
        </Carousel>
        {updatedBanners.length > 1 && (
          <div className="storefront-banner-dots thumbs">
            <CarouselDots
              data={updatedBanners}
              states={states}
              dotName="sm-dot"
            />
          </div>
        )}
      </div>
      {bannerWithDialog ? (
        <TodaysOffers
          content={bannerWithDialog}
          setBulletinModal={setBulletinModal}
          show={showBulletinModal}
        />
      ) : null}
    </PackageComponentWrapper>
  );
};
