import { PackageComponentWrapper } from '../../PackageComponentWrapper';
import { StoreCard, StoreCardProps } from '../../StoreCard';

import './overview-card.scss';

type OverviewCardProps = {
  dispensary?: StoreCardProps['dispensary'];
};

/* Temporary fix adding dispensary info to the bottom of pages */
export const OverviewCard = ({
  dispensary
}: OverviewCardProps): JSX.Element | null => {
  if (!dispensary) return null;
  return (
    <PackageComponentWrapper additionalClass="overview-card bg-ecom">
      <div className="overview-card-container margin-left-30">
        <h6 className="mh6 text-primary">Currently shopping at:</h6>
        <StoreCard dispensary={dispensary} type="overview" />
      </div>
    </PackageComponentWrapper>
  );
};
