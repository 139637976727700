import { useState } from 'react';
import { useParams, usePathname } from 'next/navigation';

import { ErrorBoundary } from '../../utils/nullErrorBoundary';
import { PriceSection } from '../PriceSection';
import { useDispensaryPathContext } from '../../hooks/dispensaryPathContextProvider';
import { ProductListTileProductProps } from './index';
import {
  Consolidate_ProductVariant,
  DatalayerAnalytics,
  optionsMap
} from 'services';
import { useSiteWideContext } from '../../hooks/siteWideContext';
import { trackMonetateImpressions } from '../../utils/trackMonetateImpressions';

import PlusIcon from '../../assets/svgs/faq/faq-open-mobile.svg';

type ProductListPricesType = {
  product: ProductListTileProductProps['item']['product'];
  baseCartUrl: string;
};

export const ProductListPrices = ({
  baseCartUrl,
  product
}: ProductListPricesType) => {
  const { currentPathDispensary } = useDispensaryPathContext();
  const [selectedVariant, setVariant] = useState<Consolidate_ProductVariant>();
  const { addItemToCart } = useSiteWideContext();
  const pathname = usePathname();
  const params = useParams();

  return (
    <div
      data-multi-line={product.variants.length > 6}
      className={`variants-container d-flex justify-content-end ${
        product.variants.length <= 2 ? 'inline-btn' : ''
      }`}>
      <ErrorBoundary component="product-list-price">
        {product.variants.map((v) => (
          <button
            key={v.id}
            disabled={!!currentPathDispensary?.pauseEcommerce}
            data-has-variant={v.option && v.option !== 'N/A'}
            data-test="variant-button"
            className={`variant-btn d-flex flex-column align-items-center justify-content-center ${
              product.variants.length === 1 ? 'one' : ''
            } pointer text-color-dark-grey bg-white`}
            onClick={async (e) => {
              e.preventDefault();
              setVariant(v);
              if (addItemToCart) {
                await addItemToCart(
                  {
                    product,
                    quantity: 1,
                    variant: v,
                    analyticsContext:
                      DatalayerAnalytics.generateAnalyticsContext(
                        pathname,
                        currentPathDispensary.shopLink,
                        '',
                        undefined,
                        { key: `${params.categoryKey}` }
                      ),
                    baseCartUrl
                  },
                  () => {
                    trackMonetateImpressions(
                      product.category,
                      'userAddToCartPreferences'
                    );
                    setVariant(undefined);
                  }
                );
              }
            }}>
            {v.option !== 'N/A' ? (
              <>
                <span>{optionsMap(v.option)}</span>
                <PriceSection
                  props={{
                    variant: v,
                    showRange: false
                  }}
                />
              </>
            ) : (
              <PriceSection
                props={{
                  variant: v,
                  showRange: false
                }}
              />
            )}
            {selectedVariant === v ? (
              <div className="spinner-icon">
                <div className="loading-spinner" />
              </div>
            ) : currentPathDispensary?.pauseEcommerce ? null : (
              <span className="plus-icon">
                <PlusIcon />
              </span>
            )}
          </button>
        ))}
      </ErrorBoundary>
    </div>
  );
};
