import { FormikProps } from 'formik';
import { classNames } from 'primereact/utils';
import { Password } from 'primereact/password';
import { FloatLabel } from 'primereact/floatlabel';

import { PasswordChecklist } from './checklist';
import {
  DefaultFormik,
  getFormErrorMessage,
  isFormFieldValid
} from '../../../../utils/forms';

import '../form.scss';

export type PasswordProps = {
  errorMessage?: string;
  field: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formik?: FormikProps<any>;
  required?: boolean;
  showFeedback?: boolean;
  title?: string;
};

export const PasswordField = ({
  errorMessage,
  field,
  formik,
  required,
  showFeedback = false,
  title
}: PasswordProps) => {
  if (!formik) {
    formik = DefaultFormik();
  }

  return (
    <div
      className={classNames('password-input-curaleaf margin-bottom-10', {
        'form-error': isFormFieldValid(field, formik) && formik.submitCount > 0
      })}>
      <FloatLabel>
        <Password
          data-test="password-input"
          name={field}
          inputId={field}
          className={classNames(`password-input`)}
          value={formik.values[field]}
          onChange={formik.handleChange(field)}
          toggleMask
          strongRegex="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})(?=.*[-+_!@#$%^&*., ?])"
          header={<PasswordChecklist value={formik.values[field]} />}
          feedback={showFeedback}
        />
        {title ? (
          <label htmlFor={field}>
            {title}
            {required ? <sup>*</sup> : null}
          </label>
        ) : null}
      </FloatLabel>
      {errorMessage || formik.submitCount > 0
        ? getFormErrorMessage(field, formik, errorMessage)
        : null}
    </div>
  );
};
